import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as httpUtils from '../../../utils/httpUtils';
import Pagination from '../../../components/Pagination';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import * as appConfig from '../../config';
import TableHeader from '../../../components/TableHeader';
import DateToRender from '../../../components/DateToRender';
import ReactDOM from 'react-dom';
import moment from 'moment';
//import {Router, Route, IndexRoute, browserHistory} from 'react-router';
import {
  Icon,
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerHeaderContent,
  Navigation,
  LinearProgress,
  Textfield,
  Fab,
  Menu,
  MenuAnchor,
  MenuItem,
} from 'react-mdc-web';
import { getFixTableMeasurements } from '../../../utils/tableMetrics';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import * as appConstants from '../../config';
import { salesMaterializedViewConfig, SalesMaterializedViewTableBody } from './SalesMaterializedViewConfig';
import orderBy from 'lodash/orderBy';
import * as filterUtil from '../../../utils/filterUtil';
import _ from 'lodash';
import { connect } from 'react-redux';
import '../../../styles/printStyle.css';
import cloneDeep from 'lodash/cloneDeep';

//const businessUnitID = 1;
const downloadFormat = [
  { id: 1, value: 'Download Materialized View PDF' },
  { id: 2, value: 'Download Materialized View CSV' },
  { id: 3, value: 'Download Materialized View XLS' },
];
let dataListLength = 0;

class SalesMaterializedView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      loading: false,
      showSearchBox: false,
      showMenu: false,
      totalData: '',
      searchValue: '',
      businessUnitId: '',
      dataSet: [],
      printDataSet: [],
      dataList: [],
      sorting: {
        label: '',
        order: 2,
      },
      queryParameters: {
        pagination: {
          page: 1,
          limit: 50,
        },
        filter: {},
        date: {
          start: filterUtil.getStartOfCurrentMonth(),
          end: filterUtil.getCurrentDay(),
        },
      },
      printDetails: {
        transaction: '',
        invoiceNumber: '',
        printedBy: '',
      },
      startPrint: false,
      printInfo: [
        { title: 'Print On', value: '' },
        { title: 'Print Time', value: '' },
        { title: 'Print Count', value: '' },
        { title: 'Printed By', value: '' },
      ],
    };
  }

  /** adjust table width and height according to screen **/
  componentDidMount() {
    this.getTableDataForGrid();
    const tableSize = getFixTableMeasurements(this.refs.tableReference, this.refs.fixedTableFooter);
    let table = ReactDOM.findDOMNode(this.refs.fixedTableBody);
    table.style.maxHeight = tableSize.tableHeight;
    table.style.maxWidth = tableSize.tableWidth;
    table.style.overflow = 'auto';
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  /** get data for grid, adjust loading flag */
  getTableDataForGrid = () => {
    const self = this;
    this.setState({ loading: true });
    const { queryParameters, businessUnitId } = this.state;
    const filterDetailsWithAssignedAttributes = filterUtil.getObjectWithAssignedAttributes(queryParameters.filter);
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    queryParameters.filter = filterDetailsWithAssignedAttributes;
    httpUtils
      .get(
        appConstants.baseUrl +
          `report/sales-invoice-mv?start_date=${queryParameters.date.start_date.toString()}&end_date=${queryParameters.date.end_date.toString()}&page=${
            this.state.queryParameters.pagination.page
          }&limit=${queryParameters.pagination.limit}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data.list, totalData: response.data.total, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  getDataForPrint = () => {
    const self = this;
    this.setState({ loading: true });
    httpUtils
      .get(appConstants.baseUrl + 'report/sales-invoice-mv')
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ printDataSet: response.data.list, loading: false }, () => {
            this.groupPrintDataset();
          });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  resetFilter = () => {
    const { queryParameters } = this.state;
    Object.keys(queryParameters.filter).map(attribute => {
      queryParameters.filter[attribute] = [];
    });
    queryParameters.pagination.page = 1;
    queryParameters.date.start = filterUtil.getStartOfCurrentMonth();
    queryParameters.date.end = filterUtil.getCurrentDay();
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  onPageSelect = (pageNumber, pageLimit) => {
    const { queryParameters } = this.state;
    queryParameters.pagination.page = pageNumber;
    queryParameters.pagination.limit = pageLimit;
    this.setState({ queryParameters: queryParameters }, () => this.getTableDataForGrid());
  };

  handleDateRangeChange = (start, end) => {
    const queryParameters = Object.assign({}, this.state.queryParameters);
    queryParameters.date.start = start.format('DD MMM YYYY');
    queryParameters.date.end = end.format('DD MMM YYYY');
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters: queryParameters }, () => {
      this.getTableDataForGrid();
    });
  };

  handleDownloadClick = id => {
    let type = '';
    const { user } = this.props;
    const self = this;
    const schema = 'https://';
    const { queryParameters } = this.state;
    queryParameters.date['start_date'] = moment(queryParameters.date.start).format('YYYY-MM-DD');
    queryParameters.date['end_date'] = moment(queryParameters.date.end).format('YYYY-MM-DD');
    if (id === 1) {
      type = 'PDF';
    } else if (id === 2) {
      type = 'CSV';
    } else {
      type = 'XLS';
    }

    httpUtils
      .get(
        appConstants.baseUrl +
          `download/sales-mv-report?user_id=${user.idUsers}&start_date=${queryParameters.date.start_date}&end_date=${queryParameters.date.end_date}&type=${type}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ loading: false });
          const url = response.data.url;
          const newWin = window.open(schema + window.location.hostname + url);
          if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
            alert('Please enable pop for this site');
          }
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  handlePrintClick = () => {
    this.setState({ startPrint: true }, () => {
      window.print();
    });
    window.onafterprint = () => {
      window.location.reload();
    };
  };

  handleFilterChange = (fieldValue, selectedValue) => {
    const queryParameter = Object.assign({}, this.state.queryParameters);
    queryParameter.filter[fieldValue] = selectedValue;
    queryParameter.pagination.page = 1;
    this.setState({ queryParameters: queryParameter }, () => {
      this.getTableDataForGrid();
    });
  };

  /** handle upon the header click */
  handleTableSorting = (labelName, sortingOrder) => {
    let sorting = Object.assign({}, this.state.sorting);
    sorting.label = labelName;
    sorting.order = sortingOrder;
    this.setState({ sorting: sorting });
  };

  /** get the sorted data set */
  getSortedDataSet = (labelName, sortingOrder) => {
    if (sortingOrder == 2) {
      return this.state.dataSet;
    }
    const sortingName = sortingOrder == 0 ? 'asc' : 'desc';
    const data = orderBy(this.state.dataSet, labelName, sortingName);
    return orderBy(this.state.dataSet, labelName, sortingName);
  };

  groupPrintDataset() {
    const { printDataSet } = this.state;
    let chunk = 10;
    let dataList = [];
    let arrayList = [];
    let count = 0;
    arrayList = cloneDeep(printDataSet);
    arrayList.forEach((item, index) => {
      item['index'] = index;
      arrayList[index] = item;
    });
    for (let index = 0, j = arrayList.length; index < j; index += chunk) {
      count++;
      let chunkArray = [];
      chunkArray = arrayList.slice(index, index + chunk);
      chunkArray['page'] = count;
      dataList.push(chunkArray);
    }
    dataListLength = dataList.length;
    this.setState({ dataList: dataList }, () => {
      this.handlePrintClick();
    });
  }

  handleKeyDown = e => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.getDataForPrint();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  onSearchInputChange = value => {
    const self = this;
    const searchText = value;
    httpUtils
      .get(
        appConstants.baseUrl +
          `sales-invoice?&bu=${this.state.businessUnitId}&page=${this.state.queryParameters.pagination.page}&limit=${this.state.queryParameters.pagination.limit}&search_text=${searchText}`,
      )
      .then(response => {
        if (response.status == 200 && response.success) {
          self.setState({ dataSet: response.data, totalData: response.totalCount, loading: false });
        } else {
          self.setState({ loading: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  inputSearch = value => {
    const searchValue = value;
    this.setState({ searchValue: searchValue }, () => {
      this.changeSearch(this.state.searchValue);
    });
  };

  clearInputText = () => {
    const value = '';
    const { showSearchBox } = this.state;
    this.setState({ searchValue: '', showSearchBox: !showSearchBox }, () => {
      this.onSearchInputChange(value);
    });
  };
  changeSearch = _.debounce(term => {
    this.onSearchInputChange(term);
  }, 700);

  render() {
    const { loading, queryParameters, totalData, showSearchBox, sorting, searchValue } = this.state;
    const sortedDataSet = this.getSortedDataSet(sorting.label, sorting.order);
    return (
      <div>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        {!this.state.startPrint && (
          <div className={loading ? 'clickable-false' : ''}>
            <div className="card-header-bar" ref="tableReference">
              <h2>Sales Materialized View</h2>
              <div className="header-menu">
                {/*<div className="header-menu-left ">
                                <span className="search-icon-wrapper" onClick={() => this.setState({
                                  showSearchBox: !showSearchBox
                                })}>
                                    <Icon name="search" className="search-icon material-icons"/>
                                 </span>
                        <span className={`top-search-input-wrapper ${showSearchBox ? 'input-active' : 'input-inactive' }`}>
                                  {showSearchBox &&
                                  <Textfield
                                    value={this.state.searchValue}
                                    onChange={(e) => {
                                      this.inputSearch(e.target.value)
                                    }}>
                                  </Textfield>}
                          {
                            searchValue &&
                            <Icon name="close" className="cross-icon material-icons" onClick={this.clearInputText}/>
                          }
                                </span>


                      </div>*/}
                <div className="header-menu-right">
                  <span className="date-rendered">
                    <Icon name="date_range" className="material-icons" />
                    <DateToRender date={queryParameters.date} />
                  </span>
                  <span className="download-menu" onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
                    <Icon name="get_app" />
                  </span>
                  <MenuAnchor>
                    <Menu
                      open={this.state.showMenu}
                      onClose={() => {
                        this.setState({ showMenu: false });
                      }}
                      className="transaction-download"
                    >
                      <MenuItem
                        onClick={() => {
                          this.handleDownloadClick(downloadFormat[0].id);
                        }}
                      >
                        {downloadFormat[0].value}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          this.handleDownloadClick(downloadFormat[1].id);
                        }}
                      >
                        {downloadFormat[1].value}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          this.handleDownloadClick(downloadFormat[2].id);
                        }}
                      >
                        {downloadFormat[2].value}
                      </MenuItem>
                    </Menu>
                  </MenuAnchor>
                  <span className="download-menu" onClick={e => this.getDataForPrint()}>
                    <Icon name="print" />
                  </span>
                  <span onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                    <Icon name="filter_list" />
                  </span>
                </div>
              </div>
            </div>

            <div ref="fixedTableBody" className="fixed-table-wrapper">
              <table>
                <TableHeader
                  headerDetails={salesMaterializedViewConfig.headerDetails}
                  handleSorting={this.handleTableSorting}
                />
                {sortedDataSet.map((data, key) => (
                  <tbody>
                    <SalesMaterializedViewTableBody data={data} onRowClick={this.onSalesInvoiceItemClick} />
                  </tbody>
                ))}
              </table>
            </div>

            {
              <div ref="fixedTableFooter">
                <Pagination
                  pageSize={queryParameters.pagination.limit}
                  currentPage={queryParameters.pagination.page}
                  orientation="top"
                  totalItems={totalData}
                  onPageSelect={this.onPageSelect}
                />
              </div>
            }
          </div>
        )}
        {this.state.startPrint &&
          this.state.dataList.map((data, key) => {
            return (
              <div className="print-body zoom-reduce">
                <h2>Sales Materialized View</h2>
                <div ref="fixedTableBody" className="fixed-table-wrapper pdf-table-wrapper">
                  <table className="pdf-table">
                    <TableHeader
                      headerDetails={salesMaterializedViewConfig.headerDetails}
                      handleSorting={this.handleTableSorting}
                    />
                    {data.map((data, key) => (
                      <tbody>
                        <SalesMaterializedViewTableBody data={data} />
                      </tbody>
                    ))}
                  </table>
                </div>
                <div className="footer-block">
                  <PageNumber value={data.page} totalPage={dataListLength} />
                </div>
              </div>
            );
          })}
        <div dir="rtl">
          <Drawer
            open={this.state.showFilter}
            className="right-drawer"
            onClose={() => {
              this.setState({ showFilter: false });
            }}
          >
            <div className="filter-wrapper">
              <h2 className="default-horizontal-padding-24">
                <span>Filter</span>
                <span className="float-right">
                  <Icon name="replay" onClick={() => this.resetFilter()} />
                  <Icon name="clear" onClick={() => this.setState({ showFilter: false })} />
                </span>
              </h2>
              <div className="divider"></div>
              <DateRangePicker
                startDate={moment(queryParameters.date.start)}
                endDate={moment(queryParameters.date.end)}
                onChange={this.handleDateRangeChange}
              />
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

SalesMaterializedView.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    user: state.billing.user || null,
    company: state.billing.company || null,
    bu_id: state.billing.bu_id || null,
  };
};
const salesMaterializedView = connect(mapStateToProps)(SalesMaterializedView);

export default salesMaterializedView;
