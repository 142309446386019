import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { VALIDATION_TYPES } from '../../common/validation';
import { Icon } from '../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { TRANSACTION_TYPE } from '../../../data/enums/enums';
import { formatToNumericDate } from '../../../utils/filterUtil';

const initializeCreateJv = () => ({
  ledger_name: '',
  ledger_id: '',
  type_id: '',
  dr_amount: 0,
  cr_amount: 0,
  ref_number: '',
  ref_date: new Date(),
  narration: '',
  entered_by: '',
  party_id: '',
  party_name: '',
  party_pan_no: '',
  party_taxable_amount: 0,
  tagid: '',
});

const apiTransformedJvList = (jvList, user, businessId) => jvList.map((jvItem) => {
  delete jvItem.includePartyInfo;
  delete jvItem.ledger_name;
  delete jvItem.party_pan_no;
  // delete jvItem.party_name;
  jvItem.business_id = businessId;
  jvItem.entered_by = user.idUsers;
  jvItem.ref_date = formatToNumericDate(jvItem.ref_date);
  return jvItem;
});

const formValidateFieldArray = [
  { title: 'ledger_id' },
  { title: 'dr_amount', type: VALIDATION_TYPES.ZERO_VALID },
  { title: 'cr_amount', type: VALIDATION_TYPES.ZERO_VALID },
];
const pageValidateFieldArray = [{ title: 'narration' }];

const apiTransformedJvSummary = (jvSummary, user, businessId) => ({
  business_id: businessId,
  entered_by: user.idUsers,
  transaction_type: TRANSACTION_TYPE.JV,
  ...jvSummary,
});

const CreateJournalVoucherTableBody = ({
  dataList,
  partyIncluded,
  handleEditIconClick,
  handleDeleteIconClick,
  tagList,
}) => (
  <tbody>
    {dataList.map((data, index) => (
      <tr>
        <td>{index + 1}</td>
        <td>{data.ledger_name}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.dr_amount)}</td>
        <td className='right-align'>{fixedFloatAndCommas(data.cr_amount)}</td>
        <td className='left-align'>{data.ref_number}</td>
        <td className='left-align'>{moment(data.ref_date).format('ll')}</td>
        <td className='left-align'>{data.narration}</td>
        <td className='left-align'>{data.tagid ? tagList.find((v) => v.idTags === data.tagid).title || '' : ''}</td>
        {partyIncluded && <td className='left-align'>{data.party_name}</td>}
        {partyIncluded && <td className='left-align'>{data.party_pan_no}</td>}
        {partyIncluded && <td className='right-align'>{data.party_taxable_amount}</td>}
        <td className='right-align'>
          <Icon name='edit' onClick={() => handleEditIconClick(data, index)} />
          <Icon name='delete' onClick={() => handleDeleteIconClick(index)} />
        </td>
      </tr>
    ))}
  </tbody>
);

CreateJournalVoucherTableBody.defaultProps = { enableUpdate: true };

CreateJournalVoucherTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDeleteIconClick: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  enableUpdate: PropTypes.bool,
};

const createJournalVoucherConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'index',
      title: 'SN',
      className: null,
      sortable: false,
      display: true,
    },
    {
      id: 2,
      label: 'ledger_name',
      title: 'Ledger',
      className: null,
      sortable: false,
      display: true,
    },
    {
      id: 3,
      label: 'dr_amount',
      title: 'Debit',
      className: 'right-align',
      sortable: false,
      display: true,
    },
    {
      id: 4,
      label: 'cr_amount',
      title: 'Credit',
      className: 'right-align',
      sortable: false,
      display: true,
    },
    {
      id: 5,
      label: 'ref_number',
      title: 'RefNo',
      className: 'left-align',
      sortable: false,
      display: true,
    },
    {
      id: 6,
      label: 'ref_date',
      title: 'RefDate',
      className: 'left-align',
      sortable: false,
      display: true,
    },
    {
      id: 7,
      label: 'narration',
      title: 'Narration',
      className: 'left-align',
      sortable: false,
      display: true,
    },
    {
      id: 8,
      label: 'tag_id',
      title: 'Tag',
      className: 'left-align',
      sortable: false,
      display: true,
    },
    {
      id: 9,
      label: 'party_name',
      title: 'Party Name',
      className: 'left-align',
      sortable: false,
      display: false,
    },
    {
      id: 10,
      label: 'party_pan_no',
      title: 'Party PAN No.',
      className: 'left-align',
      sortable: false,
      display: false,
    },
    {
      id: 11,
      label: 'party_taxable_amount',
      title: 'Taxable Amount',
      className: 'right-align',
      sortable: false,
      display: false,
    },
    {
      id: 12,
      label: 'action',
      title: '',
      className: 'right-align',
      sortable: false,
      display: true,
    },
  ],
};

const message = {
  success: 'Journal Voucher created.',
  error: '!Error while creating Journal Voucher',
  balanceError: '!Error Debit and Credit amount must be equal',
};

const partyHeaderList = ['party_name', 'party_pan_no', 'party_taxable_amount'];

export {
  initializeCreateJv,
  apiTransformedJvList,
  apiTransformedJvSummary,
  formValidateFieldArray,
  pageValidateFieldArray,
  partyHeaderList,
  message,
  createJournalVoucherConfig,
  CreateJournalVoucherTableBody,
};
