import styled from 'styled-components';

const GRNStyled = styled.div`
  /*======= header right css is for Create GRN PRN SRN JV & Receipt also ======= */
  .header-right.document_date {
    .date-picker-wrapper {
      display: flex;
      position: relative;
      .date-range-picker-wrapper {
        .rdrCalendarWrapper{
        position: absolute;
        right: 0;
        top: 43px;
      }
      }
      & > label {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.58);
      }
      .default-vertical-padding-24 {
        padding: 0;
      }
      .mdc-layout-grid__inner {
        display: unset;
        & > div {
          padding-left: 8px;
          .calendar-status,
          .calendar-icon {
            display: none;
          }
          span {
            border-bottom: none;
          }
        }
      }
    }
  }
  .input_date {
    float: unset;
    .date-range-picker-wrapper {
      margin-top: 8px;
      .date-picker-holder,
      .date-picker-holder-active,
      & > div {
        padding: 0;
        .mdc-layout-grid__inner {
          display: flex;
          & > div {
            flex: 1;
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
          }
        }
      }
      .calendar-status,
      .calendar-icon {
        display: none;
        border-bottom: 0;
      }
      #date-range-picker-id {
        border-bottom: 0;
      }
    }
    .calendar-arrow-icon {
      padding-bottom: 10px;
      border-bottom: 0;
      position: absolute;
      right: 0;
    }
  }
  /*======= header right css is for GRN PRN SRN & Payment also ======= */

  /* For GRN Only styling */
  .grn-create
    .three-input-wrapper
    .mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--box):not(.mdc-textfield--textarea) {
    height: 49px;
    min-width: unset;
  }
  .total-section-wrapper .sub-total-wrap .mdc-textfield {
    height: 25px;
  }
  .header-menu-right .drawer_filter{
      span{
        top: 18px;
      }
    }
  /* For GRN Only styling  End*/
`;
export default GRNStyled;
