import styled from 'styled-components';
const LedgerReportStyled = styled.div`
  @media print{
    .ledger-report-details{
      &.portrait-type{
        .print-body{
          height: 100% !important;
          page-break-after: always;
          padding:0 !important;
          margin:0.28in 0.3in !important;
          .fixed-table-wrapper{
            height:1420px !important;
            table{
              height:100%;
              .blank-tr{
                height:24px !important;
                border:0;
              }
              thead{
                th{
                  font-size: 14px !important;
                  width:70px !important;
                  min-width:70px !important;
                  max-width:70px !important;
                }
              }
              tbody{
                .pagination-wrapper{
                  height:32px !important;
                  line-height:0;
                  td{
                  height:0 !important;
                  vertical-align:middle;
                  }
                }
                td{
                  font-size: 14px !important;
                  width:70px !important;
                  min-width:70px !important;
                  max-width:70px !important;
                  &.created-date,.document-date{
                    width:60px !important;
                    min-width:60px !important;
                    max-width:60px !important;
                  }
                  &.miti{
                    width:75px !important;
                    min-width:75px !important;
                    max-width:75px !important;
                  }
                  &.voucher-num{
                    width:120px !important;
                  min-width:120px !important;
                  max-width:120px !important;
                  }
                  &.narration{
                    width:140px !important;
                    min-width:140px !important;
                    max-width:140px !important;
                    white-space:normal !important;
                  }
                  &:last-child{
                    font-weight:500;
                  }
                }
              }
            }
            .pagination-wrapper td{
              line-height:24px !important;
            }
          }
          &:last-child .fixed-table-wrapper .pagination-wrapper td{
                border-top:1px solid #c5c5c5  !important;
                border-bottom: 1px solid #c5c5c5  !important;
                font-weight: 600;
            }
            .footer-block{
            position:unset;
          }
        }
      }
    }
  }
  @media print and (max-width:5.83in) {
    .ledger-report-details.portrait-type .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
          height:1405px !important;
        }
    }
  }
`;
export default LedgerReportStyled;
