import React from 'react';
import { connect } from 'react-redux';
import { Grid, Cell } from '../../../components/BillingMDC';
import DatePicker from '../../../components/DatePicker/DatePicker';
import * as filterUtil from '../../../utils/filterUtil';

const DetailHeader = ({ headerConfig,handleDateChange,editInput ,documentDate ,exciseNumber ,exciseStatus ,fiscalYearRange}) => {
  const minDate = fiscalYearRange?.previous_fiscal_year_closed ? fiscalYearRange?.start_date : fiscalYearRange?.previous_fiscal_year_start;
  return (
    <div className='header-bar'>
      <Grid>
        <Cell col={4} tablet={8}>
          <div className='header-title'>{headerConfig.company.title}</div>
          <span className='header-title-content'>
            <span className='active-opacity-text'>Address: </span>
            <span>{headerConfig.company.address}</span>
          </span>
          <span className='header-title-content'>
            <span className='active-opacity-text'>Phone: </span>
            <span>{headerConfig.company.phone}</span>
          </span>
          <span className='header-title-content'>
            <span className='active-opacity-text'>PAN No: </span>
            <span>{headerConfig.company.panNumber}</span>
          </span>
          {!!exciseNumber && exciseStatus&&  (
            <span className='no-margin-bottom header-title-content'>
              <span className='active-opacity-text'>Excise No: </span>
              <span>{exciseNumber}</span>
            </span>
          )}
        </Cell>
        <Cell col={4} tablet={8}>
          <div className='header-middle-title center-align'>
            <h2>{headerConfig.title}</h2>
          </div>
        </Cell>
        <Cell col={4} tablet={8}>
          <div className='right-align'>
            <span className='header-right-content'>
              {editInput && <span className='dialog-date-picker-wrapper'>
                <DatePicker date={filterUtil.getMomentDate(documentDate)} onChange={(e) => handleDateChange('document_date', e)}
                  maxDate={filterUtil.getCurrentDate()}
                  minDate={filterUtil.getMomentDate(minDate)}
                />
              </span>
              }
              <span className=''>Date: </span>
              <span>{headerConfig.date}</span>
            </span>
            {headerConfig.miti ? (
              <span>
                <span className=''>Miti: </span>
                <span>{headerConfig.miti}</span>
              </span>
            ) : null}
            {headerConfig.paymentDetail ? (
              <span>
                <span className=''>Payment Mode: </span>
                <span>{headerConfig.paymentDetail.mode}</span>
              </span>
            ) : null}
          </div>
        </Cell>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fiscalYearRange: state.billing.fiscalYearRange || null,
  };
};

const DetailHeaderView = connect(mapStateToProps)(DetailHeader);

export default DetailHeaderView;
