import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BillingSelect from '../components/Select/BillingSelect';
import {
  ledgerApi,
} from '../views/common/base.api';
import { debouncer } from '../../src/utils/handlers';
import { LEDGERS } from '../data/enums/enums';
import { getCustomerList,getTypes ,customerfilterOption} from '../../src/views/fundamentals/common/helpers';

class CustomerSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option:[],
      optionListAll:[],
    };
  }
  componentDidMount() {
  }

  onInputChange = async(searchTxt ='') =>{
    const {value,type} =this.props;
    if(searchTxt==='' && (value ===null  || value===''))  {
      this.setState({optionListAll:[]})
      return;
    }
     const types =getTypes(type);
     const extraQuery =`&search_text=${encodeURIComponent(searchTxt)}`;
     const res=await ledgerApi.getList({},`${types}${extraQuery}`);
    this.setState({ optionListAll:res?.data?.list});
   }

  customerfilterOption = (option, inputValue) => {
    const testCondition=    option?.data?.panNo?.includes(inputValue) || option?.data?.title?.toLowerCase()?.includes(inputValue.toLowerCase());
    return  !!testCondition ? true :false;
  }

  render() {
    const {value,param,valueKey,clearable,onHandleChange,className,required,pageValidation,ledgerListPartial,ledgerList} = this.props;
    const {
      optionListAll
    } = this.state;
    return (
      <BillingSelect
      name="form-field-name"
      param={param}
      isSearchable
      clearable={clearable}
      className={className}
      value={value}
      valueKey={valueKey}
      required={required}
      emptyField={pageValidation}
      getOptionLabel={({ title, address, panNo }) => (
        <>
          <div>{title}</div>
          { (panNo || address) && <span>{`PAN: ${panNo || 'N/A'} | Address: ${address || 'N/A'}`}</span>}
        </>
      )}
      getOptionValue={({ title }) => title}
      onInputChange={debouncer(this.onInputChange, 800)} 
      filterOption={customerfilterOption}
      options={optionListAll.length >0 ?  optionListAll : value >0 ? ledgerList :ledgerListPartial }
      handleChange={onHandleChange}
      multipleParam
    />
    );
  }
}
CustomerSelect.defaultProps = {
  clearable:false,
  required:false,
  ledgerExcludeVat:false,
  pageValidation:false,
  onHandleChange: () => null,
};

CustomerSelect.propTypes = {
  clearable:PropTypes.bool,
  required:PropTypes.bool,
  ledgerExcludeVat:PropTypes.bool,
  pageValidation:PropTypes.bool,
  onHandleChange: PropTypes.func,
};

export default CustomerSelect;



