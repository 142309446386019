import { compose } from 'recompose';

import DebitNoteDetail from './DebitNoteDetail';

import apiInterceptor from '../../../api/interceptor';

import { debitNoteAPi,jvApi } from '../../common/base.api';

const DebitNoteVoucherDetail = compose(apiInterceptor({ ...debitNoteAPi, updateApi: jvApi.update, }))(DebitNoteDetail);

export default DebitNoteVoucherDetail;
