import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import tableConfig from './config';
import * as filterUtil from '../../../utils/filterUtil';
import withBaseState from '../../common/withBaseState';
import PageView from '../../common/pagination/PageView';
import * as queryService from '../../common/query.service';
import { LEDGER_REPORT_CONFIG } from '../../common/domain.config';
import history from '../../../utils/history';
import { debouncer } from '../../../utils/handlers';
import { CUSTOMER_LEDGER_DETAILS } from '../../../data/enums/Route';
import { customerDetails } from '../../../actions/customerDetail';
import {storeInLocalStorage ,fetchFromLocalStorage} from "../../common/common";

const propTypes = {
  onLedgerRowClick: PropTypes.func.isRequired,
};
const defaultProps = {};

class LedgerReport extends Component {
  constructor(props) {
    super(props);
    const { pagination, ...queryParameter } = queryService.queryParameters;
    this.state = {
      display: {
        searchBox: false,
        drawer: false,
      },
      queryParameters: {
        ...queryService.queryParameters,
        date: {
          start: filterUtil.formatToDateMonthYear(props.fiscalYearRange.start_date),
          end: filterUtil.getCurrentDay(),
        }, 
        filter: {
          account_groups: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
    };
    const { reportDownloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      reportDownloadList,
    );
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { queryParameters,display} = this.state;
    storeInLocalStorage(
      'DISPLAY',
      display,
      'LEDGER_REPORT'
    );
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "LEDGER_REPORT"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters,display} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "LEDGER_REPORT");
    const localDisplay = fetchFromLocalStorage('DISPLAY', 'LEDGER_REPORT');
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,
        display:{...localDisplay,filter:false} || display},
      () => {
        this.loadTableData();
      },
    );
  };
  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };
loadTableApiCall =() =>{
  const { getReport } = this.props;
  const { queryParameters ,data} = this.state;
  getReport(queryParameters).then(response => {
    data.list = response.data;
    data.total = response.total ? response.total : response.data.length;
    this.setState({ data });
  });
}
  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { queryParameters } = this.state;
    this.setFilterStateInStorage();
    if(queryParameters?.search?.length>0) {
        debouncer(this.loadTableApiCall,900)();
      return;
    } 
    this.loadTableApiCall();
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleTableBodyClick = data => {
    const { queryParameters } = this.state;
    const { onLedgerRowClick } = this.props;

    onLedgerRowClick(customerDetails({ detail: { ...data }, date: queryParameters.date }));

    history.push(`/${CUSTOMER_LEDGER_DETAILS}/${data.ledgerId}`);
  };
  resetFilter = () => {
    this.setState(
      {
        queryParameters: {
          ...queryService.queryParameters,
          date: {
            start: filterUtil.formatToDateMonthYear(this.props.fiscalYearRange.start_date),
            end: filterUtil.getCurrentDay(),
          },
          filter: {
            account_groups: [],
          },
        },
      },
      () => {
        this.loadTableData();
      },
    )
  };
  handleClearSearch =() =>{
    const {display}=this.state;
    this.setState({display:{...display,searchBox:false,search:false}});
    this.basePaginationService.clearSearchText();
  }
  handleSearchInputChange = (value) =>{
    const {queryParameters}=this.state;
    queryParameters.search = value;
    queryParameters.pagination.page = 1;
    this.setState({queryParameters},()=>this.loadTableData())
  }

  render() {
    const { queryParameters, data, display } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <div className="ledger-report">
        <PageView
          domainConfig={LEDGER_REPORT_CONFIG}
          headerConfig={{
            search: true,
            date: true,
            download: true,
            filter: true,
            create: false,
          }}
          display={display}
          controlDisplay={this.controlDisplay}
          serverResponseWaiting={serverResponseWaiting} 
          handleSearchChange={this.basePaginationService.handleSearchInputChange} 
          clearSearchText={this.handleClearSearch}
          resetFilter={this.resetFilter}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          handleDownloadClick={this.basePaginationService.handleDownloadClick}
          data={data}
          queryParameters={queryParameters}
          tableConfig={tableConfig}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          onTableBodyClick={this.handleTableBodyClick}
          onPageSelect={this.basePaginationService.onPageSelect}
          handleFilterChange={this.basePaginationService.handleFilterChange}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLedgerRowClick: action => dispatch(action),
  };
}

LedgerReport.protoTypes = propTypes;
LedgerReport.defaultProps = defaultProps;

const LedgerReportWithState = withBaseState(LedgerReport);

export default connect(null, mapDispatchToProps)(LedgerReportWithState);
