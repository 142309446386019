import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import * as actionTypes from '../data/enums/actionTypes';
import { defaultFiscalYear } from '../data/enums/enums';
import { clone } from '../utils/arrayProcessor';

// Reducer for the counter
const billing = (
  state = {
    user: {},
    company: {},
    bu_id: 1,
    permission: [],
    fiscalYearRange: defaultFiscalYear,
  },
  action,
) => {
  switch (action.type) {
  case actionTypes.SET_DETAILS:
    return {
      user: action.details.user,
      company: action.details.company,
      permission: action.details.permission || state.permission,
      bu_id: 1,
      fiscalYearRange: action.details.fiscalYearRange || defaultFiscalYear,
    };
  default:
    return state;
  }
};

// Reducer for the customer details
const customerLedgerDetail = (state = { detail: {}, date: { start: '', end: '' } }, action) => {
  switch (action.type) {
  case actionTypes.CUSTOMER_DETAILS:
    return { detail: action.payload.detail, date: action.payload.date };
  default:
    return state;
  }
};

// Reducer for ledger group details
const ledgerGroupDetails = (state = { ledgerGroupDetails: {}, date: { start: '', end: '' } }, action) => {
  switch (action.type) {
  case actionTypes.LEDGER_GROUP_DETAILS:
    return {
      detail: action.details.ledgerGroupDetails,
      date: action.details.date,
    };
  default:
    return state;
  }
};

// Reducer for journal voucher details

const journalVoucherDetail = (state = { journalVoucherDetails: {} }, action) => {
  switch (action.type) {
  case actionTypes.JOURNAL_VOUCHER_DETAILS:
    return action.payload;
  default:
    return state;
  }
};

const filterDetails = (state = { metaData: {} }, action) => {
  switch (action.type) {
  case actionTypes.SET_META_DATA:
    return { ...state, metaData: action.details };
  default:
    return state;
  }
};

const stockLedgerData = (state = { row: {}, date: { start: '', end: '' } }, action) => {
  switch (action.type) {
  case actionTypes.STOCK_LEDGER:
    return {
      ...state,
      row: clone(action.details.row),
      date: clone(action.details.date),
    };
  default:
    return state;
  }
};

const tagDetail = (state = { date: { start: '', end: '' } }, action) => {
  switch (action.type) {
    case actionTypes.TAG_DETAILS:
      return { date: action.payload.date };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  form: reduxFormReducer,
  billing,
  customerLedgerDetail,
  journalVoucherDetail,
  filterDetails,
  stockLedgerData,
  ledgerGroupDetails,
  tagDetail
});

export default rootReducer;
