import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, LinearProgress } from '../../../../components/BillingMDC';
import '../../../../styles/printStyle.css';
import history from '../../../../utils/history';
import { clone } from '../../../../utils/arrayProcessor';
import * as filterUtil from '../../../../utils/filterUtil';
import {
  getBillFooterSummary,
  getBillSummaryDetails,
  getBillTotalList,
  MESSAGE,
  PRINT_COPY,
  TITLE,
  config,
  getTotals,
} from './config';
import PrintBody from '../../../../components/PrintComponent/PrintBody';
import { SALES_INVOICE_BASE } from '../../../../data/enums/Route';
import { CLIENT, GET_BATCH_TH } from '../../../../data/enums/enums';
import { salesInvoiceApi } from '../../../common/base.api';
import { has } from '../../../../utils/hasOwnProperty';
import * as printService from '../../../common/print.service';
import * as snackService from '../../../common/snack.service';
import BillingSnackBar from '../../../../components/BillingMDC/BillingSnackbar';
import PageButtonWrapper from '../../../common/PageButtonWrapper';
import * as httpUtils from '../../../../utils/httpUtils';
import * as appConfig from '../../../../v1/config';
import DetailView from '../../../common/detail/DetailView';
import ReportView from '../../../../components/PrintComponent/ReportView';
import numberToWords from '../../../../utils/numberToTextConverter';
import { isObjectEmpty } from '../../../../utils/objectProcessor'
import {addIndexInPrintDataSet} from "../../../common/common";
import { JWT } from '../../../../environment';
import InvoiceDetailsStyled from '../../sales/detail/InvoiceDetailsStyled';
import {groupPrintDataSet} from "../../../common/print.service";

const propTypes = {
  postPrintHandler: PropTypes.func,
  batchEnabled: PropTypes.bool,
};

const defaultProps = {
  postPrintHandler: () => window.close(),
  batchEnabled: false,
};

class InvoiceDetails extends Component {
  mapPrintInfo = () => {
    const {user} =this.props;
    const { firstCopyTaxInvoice, dateTime,printData ,printTotalInfo} = this.state;
    if (!firstCopyTaxInvoice && this.client === CLIENT.DBS) {
      this.setState({ printInfo: printData.info });
    } else if(this.client === CLIENT.ROSIA){
      this.setState({printInfo:printData.info});        }
    else{
      const printInfoData = [
        { title: 'Printed On', value: dateTime.date },
        { title: 'Printed Time', value: dateTime.time },
        { title: 'Printed By', value: user.name },
        { title: 'Print Count', value:printTotalInfo && printTotalInfo.printCount  || ''}
      ];
      this.setState({ printInfo:printInfoData,});
    }  
}  ;

  handleCancelClick = () => {
    history.push(`/${SALES_INVOICE_BASE}`);
  };

  printInvoice = () => {
    const { printDetails } = this.state;
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true}, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    printService.postPrint(printDetails,);
    setTimeout(() => {
      document.getElementById("newId").click();
    }, 800);
    this.getNewDetail();
    // todo handle if error while notifying server
  };
  getNewDetail=async() =>{
  const { invoiceNumber, userDetail,firstCopyTaxInvoice} = this.state;
  this.setState({ loading: true });
  const newData=await salesInvoiceApi
    .getPrintDetails({
      invoice_number: invoiceNumber,
      user_id: userDetail.id,
    })
    const salesPrintInfo = printService.dataProcessor(newData?.data?.salesInvoice, userDetail.id);
    const printInfo = !firstCopyTaxInvoice ? salesPrintInfo.printInfo : this.state.printInfo;
    this.setState({ loading: false,printInfo,firstCopyTaxInvoice:false});
}
  // fetch the detail from the server.
  getDetail = () => {
    const { invoiceNumber, userDetail, printDetails, firstCopyTaxInvoice } = this.state;
    this.setState({ loading: true });
    salesInvoiceApi
      .getPrintDetails({
        invoice_number: invoiceNumber,
        user_id: userDetail.id,
      })
      .then(response => {
        this.setState({ loading: false });
        if ((response.status == '200') & response.success) {
          let { printInfo } = this.state;
          const { invoiceDetail, paymentDetail } = this.state;
          const { company } = this.props;
          const { salesInvoice = {} } = response.data;
          const totalQuantity = salesInvoice.salesDetail.reduce((acc, curr) => {
            return acc + curr.quantity;
          }, 0);
          const subTotal = response.data ? response.data.subTotal || 0 : 0;
          const billTotalList = getBillTotalList({
            subTotal,
            ...salesInvoice,
          });
          const dataMiscellaneousList = getBillSummaryDetails(salesInvoice);
          const billSummary = getBillFooterSummary(salesInvoice);
          const invoiceData = {
            ...salesInvoice,
            ...getTotals({ ...salesInvoice, subTotal }),
          };
          const data = {
            list: salesInvoice.salesDetail,
            summary: { ...invoiceData },
          };
          let companyInfo = { ...company };
          // summary.totalInWords = numberToWords(summary.netAmount || 0);
          if (salesInvoice.paymentMode === 'CASH') {
            paymentDetail.mode = 'CASH';
          }
          invoiceDetail.value = salesInvoice.userDetail.name;
          const printTotalInfoData =salesInvoice.printDetail;
          if (this.client === CLIENT.ROSIA) {
            companyInfo = response.data.company || company;
            if (has.call(response.data, 'actionUserDetail')) {
              userDetail.title = response.data.actionUserDetail.name;
              invoiceDetail.value = response.data.actionUserDetail.name;
            }
            const salesPrintInfo = printService.dataProcessor(salesInvoice, userDetail.id);
            printInfo = !firstCopyTaxInvoice ? salesPrintInfo.printInfo : this.state.printInfo;
            printDetails.invoiceNumber = salesInvoice.invoiceNumber;
            printDetails.printedBy = userDetail.id;
          } else {
            userDetail.title = this.props.user.name;
            userDetail.id = this.props.user.idUsers;
          }
          const callbackFunc= () =>{
            this.getprintDetails();
            if(firstCopyTaxInvoice || this.client === CLIENT.ROSIA)
            { 
              this.printInvoice()
            }
          }
          this.setState(
            {
              data,
              totalQuantity: totalQuantity,
              billSummary,
              skuList: salesInvoice.salesDetail,
              invoiceId: salesInvoice.invoiceNumber,
              date: moment(salesInvoice.date).format('DD MMM YYYY'),
              miti: salesInvoice.mitiTitle,
              entered_by: salesInvoice.userDetail.name,
              billTotalList,
              dataMiscellaneousList,
              invoiceDetail,
              paymentDetail,
              printInfo,
              userDetail,
              company: companyInfo,
              printTotalInfo:printTotalInfoData,
            },callbackFunc
          );
        } else {
          const snack = snackService.generateFailureMessage('Error while loading!');
          this.setState({ loading: false, snack });
        }
      })
      .catch(error => {
        const snack = snackService.generateFailureMessage('Error while loading!');
        this.setState({ loading: false, snack });
      });
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.printInvoice();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  renderPrintContent = () => {
    const {
      date,
      miti,
      print,
      dataList,
      printInfo,
      billSummary,
      invoiceDetail,
      paymentDetail,
      billTotalList,
      firstCopyTaxInvoice,
      dataMiscellaneousList,
      totalQuantity,
      printFooterNote,
      exciseNumber,
      exciseStatus,
      data,
    } = this.state;
    const { company } = this.state;
    const printInfoBill = company.printInfo;
    const printBody = [];
    const agentInfo = dataMiscellaneousList.slice(-1)[0];
    const countInfo = printInfo.find(a => a.title === 'Print Count');
    const printCountExist = !!(countInfo && countInfo.value);
    const printCountInfo = printCountExist ? countInfo : null;
    for (let count = 0; count < printInfoBill.count; count++) {
      const title = !printCountExist ? (count === 0 ? TITLE.ORIGINAL : TITLE.ORIGINAL2) : TITLE.COPY;
      printBody.push(
        <PrintBody
          date={date}
          miti={miti}
          print={print}
          title={title}
          company={company}
          dataList={dataList}
          printInfo={printInfo}
          billSummary={billSummary}
          message={MESSAGE.NEXT_PAGE}
          invoiceDetail={invoiceDetail}
          paymentDetail={paymentDetail}
          billTotalList={billTotalList}
          dataMiscellaneousList={dataMiscellaneousList}
          agentInfo={agentInfo}
          printInfoBill={printInfoBill}
          pageConfig={this.pageConfig}
          totalQuantity={totalQuantity}
          printCountInfo={printCountInfo}
          printFooterNote={printFooterNote}
          exciseNumber={exciseNumber}
          exciseStatus={exciseStatus}
          invoicedData={data}
        />,
      );
    }
    return printBody;
  };

  constructor(props) {
    super(props);
    const {match} =this.props;
    const id=match.params.id.replace(/%2F/g, '/');
    this.box = React.createRef();
    this.state = {
      id,
      date: '',
      miti: '',
      invoiceNumber: id,
      entered_by: '',
      firstCopyTaxInvoice: '',
      print: true,
      loading: false,
      printButtonClicked: false,
      paymentDetail: {
        status: true,
        mode: 'CREDIT',
      },
      data: {
        list: [],
        summary: {},
      },
      billSummary: getBillFooterSummary({}),
      dateTime: {
        date: filterUtil.getCurrentDay(),
        time: filterUtil.getCurrentTime(),
      },
      printTotalInfo:{},
      userDetail: {
        id: '',
        title: '',
      },
      company: isObjectEmpty(props.company) ? { printInfo: {} } : props.company,
      invoiceDetail: { title: 'Invoiced By', value: '' },
      skuList: [],
      dataList: [],
      printInfo: [],
      printDetails: {
        transaction: 'SALES',
        invoiceNumber: id,
        printedBy: '',
      },
      printData:{
        details:{},
        firstCopy:false,
        id:id,
        info:[],
        start:false,
      },
      exciseNumber:'',
      printFooterNote:'',
      exciseStatus:false,
      totalQuantity: 0,
      billTotalList: getBillTotalList({}),
      dataMiscellaneousList: getBillSummaryDetails({}),
      snack: { ...snackService.snackParameters },
    };
    this.client = CLIENT.DBS;
    this.dataListLength = 0;
    this.pageConfig = this.getPageConfig();
  }

  getPageConfig = () => {
    const {
      company: {
        printInfo: { batchEnabled },
      },
    } = this.state;
    //const { company: { printInfo: { batchEnabled }}} = this.props;
    const header = [...config.header];
    if (batchEnabled) {
      header.splice(2, 0, GET_BATCH_TH);
      return { ...config, header };
    }
    return config;
  };
  handleOutsideClick = event => {
    const { printButtonClicked } = this.state;
    if (this.box && !this.box.current.contains(event.target)) {
      printButtonClicked === true && this.handleAfterPrint();
    }
  };

  componentDidMount() {
    this.setupConfig();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
    this.getPrintExtraInfo();
    document.addEventListener('mousedown', this.handleOutsideClick);
  }
  getPrintExtraInfo() {
    const settings = localStorage.getItem(JWT.LOCAL_STORAGE.SETTINGS.NAME);
    if (settings) {
      let parsedSettings = JSON.parse(settings);
      if (parsedSettings) {
        this.setState({ exciseNumber:parsedSettings?.excise_number,printFooterNote:parsedSettings?.print_remarks,exciseStatus:parsedSettings?.addExcise });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
    document.removeEventListener('mousedown', this.handleClickOutside);

  }

  getPrintTotalInfo = (printTotalInfo, state) => {
    let printData;
    if (!!printTotalInfo) {
      printData = [
        {
          title: 'Printed On',
          value: filterUtil?.formatToNumericDate(printTotalInfo?.localPrintDate) ?? dateTime.date,
        },
        { title: 'Printed Time', value: filterUtil?.getTime(printTotalInfo?.printTime) ?? dateTime.time },
        { title: 'Printed By', value: printTotalInfo?.printedBy?.name ?? '' },
        { title: 'Print Count', value: printTotalInfo?.printCount ?? '' },
      ];
    } else {
      printData = state.info;
    }
    return printData;
  };
  getprintDetails (){
    const { printData,printDetails ,id,dateTime,printTotalInfo} = this.state;
    const { user,match ,location,company} = this.props;
    const {state} =location;
    if(state){
      printData.firstCopy =state.firstCopy;
      printData.id = state.id;
      printData.details =state.details;
      printData.info =   this.getPrintTotalInfo(printTotalInfo,state) || state.info;
      this.setState({printData},()=>{
        this.mapPrintInfo();
      });
    }
    else{
      const printInfo = [
        { title: 'Printed On', value: dateTime.date },
        { title: 'Printed Time', value: dateTime.time },
        { title: 'Printed By', value: user.name },
        { title: 'Print Count', value:printTotalInfo && printTotalInfo.printCount  || 0},
      ];
    printData.firstCopy = false;
    printData.id = id;
    printData.details =printDetails;
    printData.info =  printInfo;
    this.setState({printData},()=>{
      this.mapPrintInfo();
    });
    }
  }

  setupConfig() {
    const { match, user = {}, location = {}, company } = this.props;
    const { userDetail ,printData,id} = this.state;
    this.getprintDetails();
    const invoiceHash =match.params.invoiceHash || '';
    if (!location.state && !!invoiceHash.length ) {
      this.client = CLIENT.ROSIA;
      userDetail.id = match.params.userId;
     const firstCopy=JSON.parse(match.params.firstCopy);
      this.setState(
        {
          invoiceNumber: id,
          firstCopyTaxInvoice:firstCopy,
          userDetail,
          loading: true,
        },
        () => {
          this.getDetail();
        },
      );
    } else {
      const printDetails = {
        transaction: 'SALES',
        invoiceNumber: id,
        printedBy: user.idUsers,
      };
      userDetail.id=user.idUsers;
      userDetail.title =user.name;
      this.setState(
        {
          invoiceNumber: id,
          firstCopyTaxInvoice: printData.firstCopy,
          company,
          printDetails,
          userDetail,
        },
        () => {
          this.getDetail();
        },
      );
    }
  }

  groupPrintDataSetNew() {
    const { skuList, company  } = this.state;
    const printInfoBill = company.printInfo || {};
    const orders = clone(skuList);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  btnWrapper = () => (
    <div ref={this.box}>
      <Button
        accent
        className="margin-right-0 cancel-btn modal-btn"
        onClick={() => history.push(`/${SALES_INVOICE_BASE}`)}
      >
        Cancel
      </Button>
      <Button
        accent
        className="save-btn modal-btn"
        onClick={() => {
          this.printInvoice();
        }}
      >
        Print
      </Button>
    </div>
  );

  handleAfterPrint = () => {
    this.setState({ printButtonClicked: false }, () => {
      if (this.client === CLIENT.ROSIA) window.close();
    });
  };

  render() {
    const { loading, snack, printButtonClicked, data, company, paymentDetail,dataList ,exciseNumber,exciseStatus,firstCopyTaxInvoice} = this.state;
    const { printInfo: printInfoBill } = company;
    console.log(firstCopyTaxInvoice,'firstCopyTaxInvoice')
    return (
      <InvoiceDetailsStyled>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={`sales-invoice-detail pad-b-24${loading ? 'clickable-false' : ''}`}>
          <div>
            {/*  { !printButtonClicked && ( */}
            <div className={`sales-invoice ${loading ? 'clickable-false' : ''}`}>
              <div className={printButtonClicked ? 'no-print' : 'display-block'}>
                <DetailView
                  display={{
                    header: true,
                    footer: true,
                    summary: true,
                  }}
                  serverResponseWaiting={loading}
                  headerConfig={{
                    company,
                    title: config.title,
                    date: moment(data.summary.date || new Date()).format('DD MMM YYYY'),
                    paymentDetail,
                  }}
                  pageConfig={this.pageConfig}
                  data={data}
                  printInfoBill={printInfoBill}
                  exciseNumber={exciseNumber}
                  exciseStatus={exciseStatus}
                />
                <PageButtonWrapper renderBtn={this.btnWrapper} />
              </div>
              {/* {printButtonClicked && ( */}
                <div
                  className={printButtonClicked ? 'display-block portrait visibility-shown' : 'no-print'}
                >
                  {this.renderPrintContent()}
                </div>
              {/* )} */}
            </div>
            {/* )} */}
          </div>
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </div>

        <div
          style={{
            display: `${printButtonClicked || this.client === CLIENT.ROSIA ? 'flex' : 'none'}`,
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh)',
            width: '100%',
          }}
        id="id"
        className="print_display_none"
        >
          <a href="#id" id="newId"></a>
          {/* the translate part is width of drawer minus content-body left padding, height of nav minus content-body top padding */}
          <p
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: 'rgba(0, 0, 0, 0.54)',
              transform: 'translate(-44px,-54px)',
            }}
          >
            Click anywhere to close this tab
          </p>
        </div>


      </InvoiceDetailsStyled>
    );
  }
}

InvoiceDetails.contextTypes = {
  router: PropTypes.object,
};

InvoiceDetails.propTypes = propTypes;
InvoiceDetails.defaultProps = defaultProps;

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
});

const SalesInvoiceDetail = connect(mapStateToProps)(InvoiceDetails);

export default SalesInvoiceDetail;
