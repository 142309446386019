import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHeader from '../../../components/TableHeader';
import BillingTextField from '../../../components/TextField/BillingTextField';
import RadioButtonList from '../../../components/RadioButtonList/RadioButtonList';
import { Button, Cell, Fab, Grid, Icon, LinearProgress, Snackbar } from '../../../components/BillingMDC';
import history from '../../../utils/history';
import { clone } from '../../../utils/arrayProcessor';
import { JOURNAL_VOUCHER_BASE, JOURNAL_VOUCHER_DETAILS } from '../../../data/enums/Route';
import { buApi, checkIfApiCallSuccess, jvApi, ledgerApi, tagApi } from '../../common/base.api';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { validateForm } from '../../common/validation';
import JvDialogView from './DialogView';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import * as filterUtil from '../../../utils/filterUtil';
import { LEDGERS } from '../../../data/enums/enums';
import {
  apiTransformedJvList,
  apiTransformedJvSummary,
  createJournalVoucherConfig,
  CreateJournalVoucherTableBody,
  formValidateFieldArray,
  initializeCreateJv,
  message,
  pageValidateFieldArray,
  partyHeaderList,
} from './config';
import { voucherEncoder } from '../../../utils/miscellaneous';
import GRNStyled from '../../fundamentals/grn/GRNStyled';
import { JWT } from '../../../environment';
import JVStyled from '../JVStyled';
import { getBackDatedStatus, getCustomerList } from '../../../views/fundamentals/common/helpers';
import CustomerSelect from '../../../common/CustomerSelect';

const propTypes = {
  fiscalYearRange: PropTypes.shape({
    start_date: PropTypes.number,
    end_date: PropTypes.number,
    previous_fiscal_year_closed: PropTypes.bool,
  }),
};
const defaultProps = {
  fiscalYearRange: {
    start_date: filterUtil.getCurrentDate(),
    end_date: filterUtil.getCurrentDate(),
    previous_fiscal_year_closed: false,
  },
};
class CreateJournalVoucher extends Component {
  // get BU list
  getBUList = () => {
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  fetchLedgerOutStandingBalance = (field, customerId) => {
    const { to_ledger } = this.state;
    // display sign to represent credit or debit
    const displaySign = 1;
    ledgerApi.getOutstandingValue(customerId).then(response => {
      if (checkIfApiCallSuccess(response)) {
        if (field === 'ledger_id') {
          to_ledger.show = true;
          to_ledger.amount = response.data.outstandingAmount * displaySign;
          this.setState({ to_ledger });
        }
      }
    });
  };

  getTagList = () => {
    tagApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ tagList: response.data.list });
      }
    });
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id });
  };

  onInputFieldChange = (field, value) => {
    const { jvSummary } = this.state;
    jvSummary[field] = value;
    this.setState({ jvSummary });
  };

  // handle change of create jv events.
  onChange = (field, value, all = {}) => {
    const { createJV } = this.state;

    createJV[field] = value;

    if (field === 'dr_amount' || field === 'cr_amount') {
      this.disableInput(createJV.dr_amount, createJV.cr_amount);
    }
    // update the changes in the field value.
    if (field === 'ledger_id') {
      createJV.ledger_name = all?.title || 'title';
      createJV.type_id = all?.typeId;
      createJV.party_id = '';
      createJV.party_name = '';
      createJV.party_pan_no = '';
      createJV.party_taxable_amount = 0;
      this.fetchLedgerOutStandingBalance(field, value);
    }
    if (field === 'party_id') {
      createJV.party_name = all?.title;
      createJV.party_pan_no = all?.panNo;
    }

    this.setState({ createJV: clone(createJV) });
  };

  disableInput = (dr, cr) => {
    const { createJV, transactionDisabled } = this.state;
    if (Number(dr) !== 0) {
      createJV.cr_amount = 0;
      transactionDisabled.credit = true;
    } else if (Number(cr) !== 0) {
      createJV.dr_amount = 0;
      transactionDisabled.debit = true;
    } else if (Number(dr) === 0 || Number(cr) === 0) {
      transactionDisabled.credit = false;
      transactionDisabled.debit = false;
    }
    this.setState({ transactionDisabled, createJV });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (createJV, index) => {
    this.disableInput(createJV.dr_amount, createJV.cr_amount);
    this.setState({ createJV: clone(createJV), editModalOpen: true, indexInJVList: index });
    this.fetchLedgerOutStandingBalance('ledger_id', createJV.ledger_id);
  };

  // create the sales Return, update the discount value
  createJournalVoucher = () => {
    const { jvList, createJV, businessUnitId, partyHeaderIncluded } = this.state;
    createJV.business_id = businessUnitId;
    // createJV.ref_date = moment(createJV.ref_date).format('ll');
    if (createJV.party_id) {
      this.setState({ partyHeaderIncluded: true }, () => this.updateTableHeaderList(true, partyHeaderList));
    }
    jvList.push(createJV);
    this.setState({ jvList }, () => {
      this.handleModalClose();
      this.handleVoucherListUpdate();
    });
  };

  handleVoucherListUpdate = () => {
    const { jvList, jvSummary } = this.state;
    let net_dr_amount = 0;
    let net_cr_amount = 0;
    jvList.map(element => {
      net_dr_amount += Number(element.dr_amount);
      net_cr_amount += Number(element.cr_amount);
      // return obj;
    });
    jvSummary.net_dr_amount = Number(Number(net_dr_amount).toFixed(2));
    jvSummary.net_cr_amount = Number(Number(net_cr_amount).toFixed(2));
    this.setState({ jvSummary });
  };

  balanceDebitCredit = () => {
    const { jvSummary } = this.state;
    const debit = Number(jvSummary.net_dr_amount);
    const credit = Number(jvSummary.net_cr_amount);
    if (debit !== credit) {
      this.setState({ showMessage: true, success: false, message: message.balanceError });

      return false;
    }
    return true;
  };

  editJournalVoucher = (data, index) => {
    const { jvList, createJV, partyHeaderIncluded } = this.state;
    if (createJV.party_id && !partyHeaderIncluded) {
      this.setState({ partyHeaderIncluded: true }, () => this.updateTableHeaderList(true, partyHeaderList));
    }
    jvList[index] = createJV;
    this.setState({ jvList }, () => {
      this.handleVoucherListUpdate();
      this.handleModalClose();
    });
  };

  // delete the particular journal voucher from jvList.
  deleteJVItem = index => {
    const { jvList, indexInJVList } = this.state;
    if (jvList.length >= indexInJVList) {
      jvList.splice(indexInJVList, 1);
      this.setState({ jvList }, () => {
        this.handleVoucherListUpdate();
        this.handleModalClose();
      });
    }
  };

  // close the modal resetting  all the value
  handleModalClose = () => {
    const { to_ledger } = this.state;
    to_ledger.show = false;
    this.setState({ createModalOpen: false, editModalOpen: false, deleteModalOpen: false, to_ledger });
    this.resetJVDialog();
  };

  // reset JV Dialog
  resetJVDialog = () => {
    this.setState({
      createJV: initializeCreateJv(),
      formEmptyField: false,
      transactionDisabled: { credit: false, debit: false },
    });
  };

  handleDateChange = (field, value) => {
    const { jvSummary, createJV } = this.state;
    if (field === 'document_date') {
      jvSummary.document_date = filterUtil.formatToNumericDate(value);
      this.setState({ jvSummary });
    } else {
      createJV.ref_date = filterUtil.formatToDateMonthYear(value);
      this.setState({ createJV });
    }
  };

  // handle on modal ok click
  handleModalOk = () => {
    // check edit or create config //close the dialog
    const { createModalOpen, editModalOpen, createJV } = this.state;
    if (createModalOpen || editModalOpen) {
      const valid = validateForm(createJV, this.getFormValidationConfig(), valid =>
        this.setState({ formValidation: valid }),
      );
      if (valid) {
        if (createModalOpen) {
          this.createJournalVoucher();
        } else {
          this.editJournalVoucher(this.state.createJV, this.state.indexInJVList);
        }
      }
    } else {
      this.deleteJVItem();
    }
  };

  handleSaveClick = () => {
    // generate the object to send to the server
    const { jvList, jvSummary, businessUnitId, pageValidation } = this.state;
    const { user } = this.props;
    const valid = validateForm(jvSummary, pageValidation, validStatus =>
      this.setState({ pageValidation: validStatus }),
    );
    const jvListCheck = jvList.length > 0;
    if (!jvListCheck) {
      alert(' Journal Voucher List  is empty');
    }
    if (valid && jvListCheck) {
      const balanceCheck = this.balanceDebitCredit();
      if (balanceCheck) {
        this.setState({ loading: true });
        const filteredJvList = apiTransformedJvList(clone(jvList), user, businessUnitId);
        const object = {
          jv: {
            summary: apiTransformedJvSummary(jvSummary, user, businessUnitId),
            detail: filteredJvList,
          },
        };
        this.setState({ saveDisable: true });

        jvApi
          .create(object)
          .then(response => {
            this.setState({ loading: false });
            if (response.success) {
              const { voucher_number = '' } = response.data.journalVoucherData;
              this.setState({
                showMessage: true,
                success: true,
                message: message.success,
                voucherNumber: voucher_number,
                partyHeaderIncluded: false,
                saveDisable: true,
              });
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
              showMessage: true,
              success: false,
              message: err?.error?.message || message.error,
              saveDisable: false,
            });
          });
      }
    }
  };

  directToMainPage = () => {
    const { showMessage, success, voucherNumber } = this.state;
    if (!showMessage && success) {
      const modifiedVoucherNumber = voucherEncoder(voucherNumber) || '';
      history.push(`/${JOURNAL_VOUCHER_DETAILS}/${modifiedVoucherNumber}`);
    }
  };

  handleCancelClick = () => {
    history.push(`/${JOURNAL_VOUCHER_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({ deleteModalOpen: true, indexInJVList: index });
  };

  updateTableHeaderList = (flag, list = []) => {
    createJournalVoucherConfig.headerDetails = createJournalVoucherConfig.headerDetails.map(item => {
      item.display = list.includes(item.label) ? flag : item.display;

      return item;
    });
  };

  getFormValidationConfig = () => {
    const { formValidation, createJV, addTag } = this.state;
    let validationConfig =
      createJV.ledger_id === LEDGERS.VAT.ledger_id
        ? {
            flag: formValidation.flag,
            fieldList: [...formValidation.fieldList, { title: 'party_id' }, { title: 'party_taxable_amount' }],
          }
        : formValidation;

    validationConfig = addTag
      ? {
          flag: formValidation.flag,
          fieldList: [...validationConfig.fieldList, { title: 'tagid' }],
        }
      : formValidation;

    return validationConfig;
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      createModalOpen: false,
      editModalOpen: false,
      formEmptyField: false,
      pageEmptyField: false,
      deleteModalOpen: false,
      indexInJVList: 0,
      businessUnitId: null,
      voucherNumber: '',
      createJV: initializeCreateJv(),
      jvSummary: {
        narration: '',
        net_dr_amount: 0,
        net_cr_amount: 0,
        document_date: filterUtil.getCurrentDay(),
      },
      jvList: [],
      buList: [],
      tagList: [],
      transactionDisabled: {
        credit: false,
        debit: false,
      },
      invalidPAN: false,
      partyHeaderIncluded: false,
      formValidation: {
        flag: false,
        fieldList: formValidateFieldArray,
      },
      pageValidation: {
        flag: false,
        fieldList: pageValidateFieldArray,
      },
      showMessage: false,
      saveDisable: false,
      success: false,
      message: message.success,
      addTag: false,
      partyLedgerList: [],
      partyLedgerListPartial: [],
      ledgerList: [],
      ledgerListPartial: [],

      backDatedStatus: getBackDatedStatus(),
      to_ledger: {
        amount: 0,
        show: false,
      },
      fyMinDate: filterUtil.getCurrentDay(),
    };
  }

  componentDidMount() {
    this.getBUList();
    this.updateTableHeaderList(false, partyHeaderList);
    this.getLedgerList();
    this.setAddTagStatus();
    this.getTagList();
    this.getStartDate();
  }
  getStartDate = async () => {
    const { getStartDate } = this.props;
    await getStartDate({
      query: {
        date: {
          start: filterUtil.getCurrentDay(),
          end: filterUtil.getCurrentDay(),
        },
      },
    }).then(response => {
      const startDate = filterUtil.formatToDateMonthYear(response);
      this.setState({ fyMinDate: startDate });
    });
  };
  setAddTagStatus() {
    const settings = localStorage.getItem(JWT.LOCAL_STORAGE.SETTINGS.NAME);
    if (settings) {
      const parsedSettings = JSON.parse(settings);
      if (parsedSettings) {
        this.setState({ addTag: !!parsedSettings.addTag });
      }
    }
  }
  getLedgerList = async () => {
    const ledgerListAll = await getCustomerList('all');
    this.getOtherListList(ledgerListAll);
  };
  getOtherListList = ledgerListAll => {
    const partyLedgerListPartial = ledgerListAll.slice(0, 300);
    const ledgerListPartial = ledgerListAll.slice(0, 300);
    this.setState({
      partyLedgerListPartial,
      ledgerListPartial,
      partyLedgerList: ledgerListAll,
      ledgerList: ledgerListAll,
    });
  };

  getLedgerSelectOptions = () => {
    const {
      createJV,
      to_ledger,
      formValidation,
      partyLedgerListPartial,
      ledgerListPartial,
      ledgerList,
      partyLedgerList,
    } = this.state;
    return (
      <>
        <Cell col={4} tablet={4} className="input-select ">
          <label>Ledgers*</label>
          <CustomerSelect
            value={createJV.ledger_id}
            param="ledger_id"
            valueKey="customerId"
            clearable
            onHandleChange={this.onChange}
            required
            pageValidation={formValidation.flag}
            ledgerList={ledgerList}
            ledgerListPartial={ledgerListPartial}
          />
          <span>
            {' '}
            {to_ledger.show && `${to_ledger.amount > 0 ? 'Cr:' : 'Dr:'} ${fixedFloatAndCommas(to_ledger.amount)}`}
          </span>
        </Cell>
        {createJV.ledger_id === LEDGERS.VAT.ledger_id && (
          <>
            <Cell col={4} tablet={6}>
              <label className="select-custom-label">Customer Name</label>
              <CustomerSelect
                value={createJV.party_id}
                param="party_id"
                valueKey="customerId"
                clearable
                onHandleChange={this.onChange}
                required
                pageValidation={formValidation.flag}
                ledgerList={partyLedgerList}
                ledgerListPartial={partyLedgerListPartial}
              />
            </Cell>
            <Cell col={4} tablet={6}>
              <BillingTextField
                value={createJV.party_pan_no}
                param="party_pan_no"
                floatingLabel="PAN Number"
                required={false}
                rule="isInt"
                type="number"
                className="billing-required inp-disabled"
              />
            </Cell>
            <Cell col={4} tablet={6}>
              <BillingTextField
                value={createJV.party_taxable_amount}
                param="party_taxable_amount"
                floatingLabel="Taxable Amount"
                required
                type="number"
                className="billing-required"
                handleChange={this.onChange}
                disabled={!createJV.party_id}
                emptyField={formValidation.flag}
              />
            </Cell>
          </>
        )}
      </>
    );
  };

  render() {
    const {
      loading,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      buList,
      jvList,
      showMessage,
      saveDisable,
      createJV,
      partyHeaderIncluded,
      formValidation,
      transactionDisabled,
      pageValidation,
      jvSummary,
      success,
      message,
      tagList,
      addTag,
      backDatedStatus,
      fyMinDate,
    } = this.state;
    return (
      <JVStyled className="jv-create">
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        <div className={loading ? 'clickable-false' : ''}>
          <div className="message-snackbar">
            <Snackbar
              className={success ? 'success-message' : ''}
              timeout={success ? 100 : 4000}
              onTimeout={() => {
                this.setState({ showMessage: false }, () => {
                  this.directToMainPage();
                });
              }}
              open={showMessage}
            >
              {message}
            </Snackbar>
          </div>
          <div className="card-header-bar clearfix" ref="tableReference">
            <div className="header-left">
              <h2>New Journal Voucher</h2>
            </div>
            <GRNStyled>
              <div className={`${backDatedStatus || 'disabled-opacityFull'} header-right document_date`}>
                <span className="date-picker-wrapper">
                  <label>Document Date:</label>
                  <DatePicker
                    date={filterUtil.getMomentDate(jvSummary.document_date)}
                    onChange={e => this.handleDateChange('document_date', e)}
                    maxDate={filterUtil.getCurrentDate()}
                    minDate={filterUtil.getMomentDate(fyMinDate)}
                  />
                </span>
              </div>
            </GRNStyled>
          </div>
          <div className="card-body">
            <div className="radio-list-wrapper">
              {buList.length && (
                <RadioButtonList data={buList} handleBuClick={this.handleBUClick} radioDisableControl />
              )}
            </div>
            <div>
              <div className="three-input-wrapper">
                <Grid>
                  <Cell col={4} className="input-field">
                    <BillingTextField
                      value={jvSummary.narration}
                      param="narration"
                      floatingLabel="Narration"
                      required
                      className="billing-required"
                      emptyField={pageValidation.flag}
                      handleChange={this.onInputFieldChange}
                    />
                  </Cell>
                </Grid>
              </div>
              <div className="table-wrapper">
                <div ref="fixedTableBody" className="overflow-scrollable fixed-table-wrapper">
                  <table>
                    <TableHeader
                      headerDetails={createJournalVoucherConfig.headerDetails}
                      filterHeaderLabel={false}
                      handleSorting={this.handleTableSorting}
                    />
                    <CreateJournalVoucherTableBody
                      dataList={jvList}
                      handleEditIconClick={this.handleEditIconClick}
                      handleDeleteIconClick={this.handleDeleteIconClick}
                      partyIncluded={partyHeaderIncluded}
                      tagList={tagList}
                    />
                  </table>
                </div>

                {/* Fab Icon */}
                <div className="mini-fab-button">
                  <Fab mini onClick={() => this.setState({ createModalOpen: true })}>
                    <Icon name="add" />
                  </Fab>
                </div>
              </div>
              {/* Sales invoice table summary goes over here. */}
              <div className="total-section-wrapper">
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total Debit:{' '}
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(jvSummary.net_dr_amount)}
                  </Cell>
                </Grid>

                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Total Credit:
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(jvSummary.net_cr_amount)}
                  </Cell>
                </Grid>
                <Grid>
                  <Cell col={8} />
                  <Cell col={3} className="right-align active-opacity-text">
                    Difference:
                  </Cell>
                  <Cell col={1} className="right-align">
                    {fixedFloatAndCommas(jvSummary.net_dr_amount - jvSummary.net_cr_amount)}
                  </Cell>
                </Grid>
              </div>
            </div>
          </div>
          <div className="newinvoice-btn-wrapper ">
            <Grid>
              <Cell col={8} />
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  accent
                  className={saveDisable ? 'save-btn modal-btn btn-disabled' : 'save-btn modal-btn'}
                  disabled={saveDisable}
                  onClick={() => {
                    this.handleSaveClick();
                  }}
                >
                  Save
                </Button>
              </Cell>
            </Grid>
          </div>

          {/* Begin of the dialog Body */}
          <JvDialogView
            tagList={tagList}
            addTag={addTag}
            onModalClose={this.handleModalClose}
            renderLedgerSelectOptions={this.getLedgerSelectOptions}
            modalOpen={createModalOpen}
            editModalOpen={editModalOpen}
            deleteModalOpen={deleteModalOpen}
            createJV={createJV}
            handleInputChange={this.onChange}
            onModalSubmit={this.handleModalOk}
            formEmptyField={formValidation.flag}
            handleDateChange={this.handleDateChange}
            transactionDisabled={transactionDisabled}
          />
        </div>
      </JVStyled>
    );
  }
}
CreateJournalVoucher.contextTypes = {
  router: PropTypes.object,
};

CreateJournalVoucher.defaultProps = defaultProps;
CreateJournalVoucher.propTypes = propTypes;

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
  bu_id: state.billing.bu_id || null,
  fiscalYearRange: state.billing.fiscalYearRange || null,
});

const createJournalVoucher = connect(mapStateToProps)(CreateJournalVoucher);

export default createJournalVoucher;
