import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GRNDialog from './Dialog';
import TableHeader from '../../../../components/TableHeader';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import { Icon, LinearProgress, Fab, Button, Grid, Cell, Snackbar } from '../../../../components/BillingMDC';
import history from '../../../../utils/history';
import { debouncer } from '../../../../utils/handlers';
import { INVENTORY_BASE } from '../../../../data/enums/Route';
import {
  CreateGRNTableBody,
  createGRNConfig,
  validationList,
  createSKUMapper,
  vendorDetailsMapper,
  billSummaryMapper,
  isGRNConstraintError,
} from './config';
import { fixedFloat, fixedFloatAndCommas } from '../../../../utils/conversion';
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import RadioButtonList from '../../../../components/RadioButtonList/RadioButtonList';
import GRNStyled from '../GRNStyled';

import { catalogsApi, ledgerApi, checkIfApiCallSuccess, buApi, grnApi } from '../../../common/base.api';
import { getDiscountedValue, handleBatchSelect } from '../../../../utils/handlers';
import * as filterUtil from '../../../../utils/filterUtil';
import DiscountBarWithCheckBox from '../../../common/create/DiscountBarWithCheckBox';
import { discountOptions } from '../../sales/create/config';
import DiscountBarStyled from '../../../common/create/DiscountBarStyled';
import { updateDiscountValueOfInvoiceList } from '../../common/helpers';
import CustomerSelect from '../../../../common/CustomerSelect';
import { getBackDatedStatus ,getCustomerList} from '../../../../views/fundamentals/common/helpers';
import { checkBUId, getIntegratedBUList } from '../../../common/common';

const message = {
  success: 'GRN created successfully.',
  error: '!Error while creating GRN',
};

class CreateGoodsReceivedNote extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      loading: false,
      saveDisable: false,
      editModalOpen: false,
      formEmptyField: false,
      pageEmptyField: false,
      batchDialogValidation: true,
      createModalOpen: false,
      deleteModalOpen: false,
      batchFormOpen: false,
      billDiscountChecked: false,
      tradeDiscountChecked: false,
      indexInInvoiceList: 0,
      snack: {
        show: false,
        success: true,
        message: message.success,
      },
      businessUnitId: null,
      vendorDetails: { ...vendorDetailsMapper({}) },
      createSKU: { ...createSKUMapper({}) },
      billSummary: billSummaryMapper({ user }),
      batchDetails: {
        title: '',
        // rlp: 0,
        dlp: 0,
        mfgDate: filterUtil.getCurrentDay(),
        expiryDate: filterUtil.getCurrentDay(),
      },
      invoiceId: '',
      skuList: [],
      batchList: [],
      buList: [],
      brandList: [],
      invoiceList: [],
      brandSKUList: [],
      date: new Date(),
      grnId:null,
      vendorInvoiceDate: filterUtil.getCurrentDay(),
      // eslint-disable-next-line react/no-unused-state
      headerTitleToView: createGRNConfig.headerTitleToView,
      backDatedStatus:getBackDatedStatus(),
      ledgerList:[],
      ledgerListPartial:[],
    };
  }

  // after mounted get the customer list.
  componentDidMount() {
    this.getBUList();
    this.getLedgerList();
  }

  getLedgerList = async () => {
    const ledgerListAll =await getCustomerList('vendor');
    this.getOtherListList(ledgerListAll)
  }
  getOtherListList = (ledgerListAll) =>{
    const ledgerListPartial = ledgerListAll.slice(0,300) ;
    this.setState({ledgerList:ledgerListAll,ledgerListPartial}) 
  }

  // handle input change of customer Details. Fetch the list if invoice id is valid.
  onInputFieldChange = (field, value,stateType) => {
    const { billSummary,vendorDetails } = this.state;
    if(stateType==='vendorDetails'){
      vendorDetails[field] = value;
    }
  else{
    billSummary[field] = value;
    if (field === 'bill_discount_param') {
      billSummary.bill_discount = 0;
    }
    if (field === 'trade_discount_param') {
      billSummary.trade_discount = 0;
    }
  }
    this.setState({ billSummary ,vendorDetails}, () => {
      if (field !== 'remarks ') this.handleGRNItemUpdate();
    });
  };

  onInvoiceNumberChange = (field, invoiceId) => {
    this.setState({ invoiceId });
  };

  onVendorDropDownChange = (field, id, vendor = {}) => {
    this.setState({ vendorDetails: { ...vendorDetailsMapper(vendor) } });
  };

  onBatchFabClick = () => {
    this.resetBatchFormDetail();
    this.setState({
      batchFormOpen: true,
      batchDialogValidation: true,
    });
  };

  onBatchInputChange = (field, value, detail) => {
    const { batchDetails } = this.state;
    batchDetails[field] = value;

    this.setState({ batchDetails });
  };

  resetBatchFormDetail = () => {
    const batchDetails = {
      title: '',
      // rlp: 0,
      dlp: 0,
      mfgDate: filterUtil.getCurrentDay(),
      expiryDate: filterUtil.getCurrentDay(),
    };

    this.setState({
      batchDetails,
    });
  };

  onBatchFormCancel = () => {
    this.resetBatchFormDetail();
    this.setState({ batchFormOpen: false });
  };

  onBatchFormSubmit = () => {
    const { batchDetails, createSKU, batchList } = this.state;
    const valid =
      batchDetails.title !== '' &&
      // && batchDetails.rlp >= 0
      batchDetails.dlp >= 0;

    if (!valid) {
      this.setState({ batchDialogValidation: false });
    } else {
      const batchData = {
        business_id: createSKU.business_id,
        business_sku_id: createSKU.sku_id,
        title: batchDetails.title,
        // rlp: batchDetails.rlp,
        dlp: batchDetails.dlp,
        mfg_date: batchDetails.mfgDate,
        expiry_date: batchDetails.expiryDate,
      };

      catalogsApi
        .createSkuBatch(batchData)
        .then(response => {
          const batchResponse = response.data;

          const batch = {
            id: batchResponse.business_batch_id,
            title: batchResponse.title,
            // rlp: batchResponse.rlp,
            dlp: batchResponse.dlp,
            businessBatchId: batchResponse.business_batch_id,
          };
          batchList.push(batch);

          this.setState(
            {
              batchList,
              batchFormOpen: false,
            },
            () => {
              this.onChange('batch_id', batch.businessBatchId, batch);
            },
          );
        })
        .catch(err => {
          throw err;
        });
    }
  };

  getDiscountInput = createSku => {
    return Number(fixedFloat(createSku.quantity * createSku.dlp || 0));
  };
  // handle change of create sku events.
  onChange = (field, value, detail = {}) => {
    let { createSKU } = this.state;
    createSKU[field] = value;
    // discount can not be greater than qty*rate
    if (field === 'discount_label') {
      createSKU.discount_input = null;
    }
    createSKU.quantity = createSKU.normal + createSKU.shortage + createSKU.damage;
    createSKU.amount = Number(Number(createSKU.quantity * createSKU.rate).toFixed(2));
    createSKU.discount =
      createSKU.discount_label === 'rs'
        ? Number(createSKU.discount_input || 0)
        : Number(Number((createSKU.discount_input || 0) * createSKU.amount * 0.01).toFixed(2));
    if (createSKU.discount > createSKU.amount) {
      createSKU.discount = 0;
      createSKU.discount_input = null;
    }
    if (field === 'brand') {
      createSKU.normal =0;
      createSKU.damage=0;
      createSKU.shortage=0;
      createSKU.quantity=0;
      createSKU.batch_id = '';
      createSKU.rate = '';
      createSKU.promotion_discount = 0;
      createSKU.dlp = 0;
      createSKU.business_id = detail?.businessId;
      createSKU.skuTitle = '';
      createSKU.sku_id = null;
      createSKU.discount = 0;
      createSKU.discount_input = null;
      this.setState({ batchList: [] }, () => value !== null && this.getSKUListUnderBrand(detail?.businessCatalogDetailId,null, detail?.businessId));
      if (value === null) {
        createSKU.brand = '';
        this.setState({ brandSKUList: [] });
      }
    }
    if (field === 'sku_id') {
      createSKU.normal =0;
      createSKU.damage=0;
      createSKU.shortage=0;
      createSKU.quantity=0;
      createSKU.business_id = detail?.businessId;
      createSKU.skuTitle = detail.title || '';
      createSKU.excisePercent = JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise?detail.excisePercent:0;
      createSKU = {
        ...(handleBatchSelect(createSKU, (detail?.batchList?.length > 0 && detail?.batchList[0]) || {}, 'inventory') ||
          createSKU),
      };
      createSKU.discount_input = null;
      createSKU.discount = 0;
      createSKU.vat_percent = detail.vatPercent;
      createSKU.dlp = createSKU.rate;
      createSKU.quantity = 0;
      this.setState({ batchList: detail?.batchList || [] });
    }

    if (field === 'normal' || field === 'damage' || field === 'shortage') {
      createSKU.discount = 0;
      createSKU.discount_input=0;
      if (createSKU.freeSku) {
        createSKU.discount = this.getDiscountInput(createSKU);
      }
    }

    if (field === 'batch_id') {
      createSKU.normal =0;
      createSKU.damage=0;
      createSKU.shortage=0;
      createSKU.quantity=0;
      createSKU = {
        ...(handleBatchSelect(createSKU, detail, 'inventory') || createSKU),
      };
      createSKU.freeSku = false;
      createSKU.quantity = 0;
    }
    if (field === 'freeSku') {
      createSKU.discount = this.getDiscountInput(createSKU);
      if (!value) {
        createSKU.rate = createSKU.dlp;
        createSKU.discount = 0;
      }
    }
    const amountCalc = Number(createSKU.quantity * createSKU.rate);
    createSKU.net_amount = createSKU.freeSku ? 0 : Number(Number(amountCalc - createSKU.discount).toFixed(2)) || 0;
    if (Number(createSKU.net_amount) < 0) {
      createSKU.net_amount = '0.00';
      createSKU.discount = 0;
    }
    createSKU.vat = Number(Number((createSKU.vat_percent / 100) * createSKU.net_amount).toFixed(2));
    createSKU.excise =  Number(Number(createSKU.excisePercent / 100 ) * createSKU.net_amount).toFixed(2) || 0;
    this.setState({ createSKU });
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data = {}, index) => {
    const {brandList} =this.state;
    const businessCatalogDetailIdx =brandList.find(a=>a.indexArr===data.brand)?.businessCatalogDetailId;
    businessCatalogDetailIdx !==undefined ? this.getSKUListUnderBrand(businessCatalogDetailIdx, data.sku_id, data.business_id) :this.getSkuList(data?.skuTitle);
    this.setState({
      createSKU: { ...createSKUMapper(data) },
      editModalOpen: true,
      indexInInvoiceList: index,
    });
  };

  // create the sales Return, update the discount value
  createGRN = () => {
    const { invoiceList, createSKU } = this.state;
    // createSKU.amount = (Number(createSKU.quantity) * Number(createSKU.rate)).toFixed(2);
    invoiceList.push(createSKU);
    this.setState({ invoiceList }, () => {
      this.closeModal();
      // handle SRN item update
      this.handleGRNItemUpdate();
    });
  };

  // editGRN
  editGRN = (data, index) => {
    const { invoiceList, createSKU } = this.state;
    // update the discount value
    // createSKU.discount = createSKU.quantity * createSKU.rate - createSKU.price;
    createSKU.amount = (Number(createSKU.quantity) * Number(createSKU.rate)).toFixed(2);
    invoiceList[index] = createSKU;
    this.setState({ invoiceList }, () => {
      this.closeModal();
      this.handleGRNItemUpdate();
    });
  };

  // delete the sales Return for particular sku, and update the total value.
  deleteGRNItem = index => {
    const { invoiceList, indexInInvoiceList } = this.state;
    if (invoiceList.length >= indexInInvoiceList) {
      invoiceList.splice(indexInInvoiceList, 1);
      this.setState({ invoiceList }, () => {
        this.closeModal();
        this.handleGRNItemUpdate();
      });
    }
  };

  // when SRN item number changes, update the bill total value
  handleGRNItemUpdate = () => {
    // set the invoice total.
    const { invoiceList, billSummary } = this.state;
    let salesValue = 0;
    invoiceList.map(element => (salesValue += Number(element.net_amount)));
    const billDiscountValue = getDiscountedValue(
      salesValue,
      billSummary.bill_discount_param,
      billSummary.bill_discount,
    );
    billSummary.bill_discount_value = billDiscountValue || 0;

    const tradeDiscountValue = getDiscountedValue(
      salesValue - billDiscountValue,
      billSummary.trade_discount_param,
      billSummary.trade_discount,
    );
    billSummary.trade_discount_value = tradeDiscountValue || 0;
    billSummary.taxable_amount = salesValue - billSummary.bill_discount_value - billSummary.trade_discount_value;
    if (billSummary.taxable_amount <= 0) {
      billSummary.taxable_amount = salesValue;
      billSummary.bill_discount_value = 0;
      billSummary.bill_discount = 0;
      billSummary.trade_discount_value = 0;
      billSummary.trade_discount = 0;
    }
    const updatedInvoiceList = updateDiscountValueOfInvoiceList(invoiceList, billDiscountValue, tradeDiscountValue);
    billSummary.vat = updatedInvoiceList.reduce((acc, item) => acc + item.vat, 0);
    billSummary.taxExemptedAmount = updatedInvoiceList.reduce((acc, item) => acc + item.tax_exempted_amount, 0);
    billSummary.excise = updatedInvoiceList.reduce((acc, item) => acc + Number(Number(item.net_amount) - Number(item.bill_discount||0) - Number(item.promotion_discount||0) - Number(item.trade_discount||0))*(item.excisePercent||0)/100, 0) 
    billSummary.actualTaxableAmount = billSummary.taxable_amount - (billSummary.taxExemptedAmount || 0) + Number(Number(billSummary.excise).toFixed(2));;
    billSummary.net_amount = salesValue;
    billSummary.gross_amount = billSummary.taxable_amount + billSummary.vat + billSummary.excise;
    this.setState({ billSummary, invoiceList: updatedInvoiceList });
  };

  // close the modal resetting  all the value
  closeModal = () => {
    this.setState({
      createModalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      batchList: [],
      brandSKUList: [],
    });
    this.resetSKUDialog();
  };

  // reset SKU Dialog
  resetSKUDialog = () => {
    const skuList = [];
    this.setState({
      createSKU: { ...createSKUMapper({}) },
      formEmptyField: false,
      skuList,
    });
  };

  // handle on modal ok click
  handleModalOkClick = () => {
    // check edit or create config //close the dialog
    const { createModalOpen, editModalOpen, createSKU, indexInInvoiceList } = this.state;
    if (createModalOpen || editModalOpen) {
      const flag = createSKU.quantity !== 0;
      const validationForm = validationList.form.filter(i => i !== 'rate');
      const valid = this.validateForm(createSKU, validationForm, 'formEmptyField', flag);
      if (valid) {
        if (createModalOpen) {
          this.createGRN();
        } else {
          this.editGRN(createSKU, indexInInvoiceList);
        }
      }
    } else {
      this.deleteGRNItem();
    }
  };

  validateForm = (formObject, config, flag, zeroFlag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        const value = formObject[k];
        const conditionParameter = zeroFlag ? value === '' : !value;
        if (conditionParameter) {
          this.setState({ [flag]: true });
          return true;
        }
      }
    });
    return !data;
  };

  directToMainPage = () => {
    const { snack ,grnId} = this.state;
    if (!snack.show && snack.success) {
      history.push(`/${INVENTORY_BASE}/details/${grnId}`,{
        printEnable: true,
      });
    }
  };

  // get the brand list
  getBrandList = () => {
    const { businessUnitId, buList } = this.state;
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
    catalogsApi.getBrandList({ business_id: checkBUId(businessUnitId) }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const filteredBrandList = response.data.list.filter(list => !integratedBUIds.includes(list.businessId));
        const insertIndexArr=filteredBrandList.map((a,index)=>{return {...a, indexArr:index+1}});
        this.setState({ brandList: insertIndexArr });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = (brandId, skuId = null, businessId) => {
    const { batchList } = this.state;
    let batches = [...batchList];
    catalogsApi
      .getSkuUnderBrand({
        business_id: businessId,
        brand_id: brandId,
      })
      .then(response => {
        if (checkIfApiCallSuccess(response)) {
          const skus = response.data.list || [];

          if (skuId) {
            const sku = skus.find(sku => sku.businessSkuId === skuId) || {};
            batches = sku.batchList || [];
          }
          this.setState({ skuList: skus, batchList: batches, brandSKUList: skus });
        }
      });
  };

  // get sku list
  getSkuList = async inputValue => {
    const { businessUnitId, buList } = this.state;
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
    const nonIntegratedBUIds = buList.filter(list => !integratedBUIds.includes(list.businessId)).map(list=>list.businessId);
    const response = await catalogsApi.getSkuUnderOtherBUList({ business_id: nonIntegratedBUIds });
    const filteredSKUList = response.data.list.filter(list =>
      list.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
    this.setState({ skuList: filteredSKUList });
    return filteredSKUList;
  };

  getBrandSkuList = async inputValue => {
    const { brandSKUList } = this.state;
    const filteredSKUList = brandSKUList.filter(list => list.title.toLowerCase().includes(inputValue.toLowerCase()));
    this.setState({ skuList: filteredSKUList });
    return filteredSKUList;
  };

  getBUList = () => {
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getBrandList();
    });
  };

  handleSaveClick = () => {
    const { vendorDetails, billSummary, businessUnitId, invoiceId, invoiceList, vendorInvoiceDate, date,snack } = this.state;
    const vendorWithRemarksAndInvoiceId = {
      ...vendorDetails,
      invoiceId,
      remarks: billSummary.remarks,
    };

    // check the validation
    const valid = this.validateForm(vendorWithRemarksAndInvoiceId, validationList.page, 'pageEmptyField', false);

    let amountTotal = 0;
    let discountTotal = 0;

    invoiceList.forEach(invoice => {
      amountTotal += Number(invoice.amount);
      discountTotal += Number(invoice.discount);
    });

    if (invoiceList.length <= 0) {
      alert('Purchase List  is empty');
    }

    if (!valid) {
      return;
    }

    const grnDetails = {
      business_id: checkBUId(businessUnitId),
      entered_by: billSummary.entered_by,
      net_amount: billSummary.gross_amount,
      tax_exempted_amount: billSummary.taxExemptedAmount || 0,
      remarks: billSummary.remarks,
      vat: billSummary.vat,
      vendor_invoice_number: invoiceId,
      vendor_ref_id: vendorDetails.vendorId,
      vendor_invoice_date: vendorInvoiceDate,
      document_date: filterUtil.formatToReadableDate(date),
      amount: amountTotal,
      address:vendorDetails.address,
      promotion_discount: discountTotal,
      bill_discount: billSummary.bill_discount_value,
      trade_discount: billSummary.trade_discount_value,
      discount: billSummary.trade_discount_value + billSummary.bill_discount_value + discountTotal,
      excise: Number(billSummary.excise),
    };

    this.setState({ loading: true, saveDisable: true });
    // todo : backEnd filters the unnecessary object attributes.

    grnDetails.skuList = invoiceList.map(sku => {
      const { brand, brandTitle, skuTitle, batchTitle, discount_label, discount_input, ...skuWithoutTitle } = sku;

      skuWithoutTitle.vendor_ref_id = vendorDetails.vendorId;
      skuWithoutTitle.vendor_invoice_number = invoiceId;
      skuWithoutTitle.promotion_discount = sku.discount;
      skuWithoutTitle.excise = (Number (Number(sku.net_amount) - Number(sku.promotion_discount||0) -  Number(sku.trade_discount||0) - Number(sku.bill_discount||0)) * sku.excisePercent /100) || 0; 
      return skuWithoutTitle;
    });

    grnApi
      .create(grnDetails)
      .then(response => {
        const grndIdGet =response.data.detailList[0].purchase_id;
        if (checkIfApiCallSuccess(response)) {
          this.setState({
            snack: {
              show: true,
              success: true,
              message: message.success,
            },
            saveDisable: true,
            grnId:grndIdGet,
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          snack: {
            show: true,
            success: false,
            message:(err && err.error && err.error.isGRNConstraintError ? isGRNConstraintError : err.error && err.error.message )|| err.message,
          },
          saveDisable: false,
        });
      });
  };

  handleDateChange = (field, value) => {
    if (field === 'document_date') {
      const formattedDate = filterUtil.formatToReadableDate(value);
      this.setState({ date: formattedDate });
    } else {
      const vendorDateFormatted = filterUtil.formatToNumericDate(value);
      this.setState({ vendorInvoiceDate: vendorDateFormatted });
    }
  };

  handleCancelClick = () => {
    history.push(`/${INVENTORY_BASE}`);
  };

  handleDeleteIconClick = index => {
    this.setState({
      deleteModalOpen: true,
      indexInInvoiceList: index,
    });
  };

  handleCheckBoxClick = (fieldName, flag) => {
    this.setState({ [fieldName]: flag });
    const { billSummary } = this.state;
    // if the flag is set off reset value to 0 and disable
    if (fieldName === 'billDiscountChecked') {
      billSummary.bill_discount_value = 0;
      billSummary.bill_discount = 0;
      billSummary.bill_discount_param = discountOptions[0].value;
    } else if (fieldName === 'tradeDiscountChecked') {
      billSummary.trade_discount_value = 0;
      billSummary.trade_discount = 0;
      billSummary.trade_discount_param = discountOptions[0].value;
    }
    this.setState({ billSummary }, () => this.handleGRNItemUpdate());
  };

  render() {
    const {
      loading,
      saveDisable,
      invoiceList,
      createModalOpen,
      editModalOpen,
      deleteModalOpen,
      vendorDetails,
      buList,
      snack,
      pageEmptyField,
      invoiceId,
      date,
      vendorInvoiceDate,
      businessUnitId,
      batchDetails,
      batchDialogValidation,
      batchFormOpen,
      billSummary,
      billDiscountChecked,
      tradeDiscountChecked,
      brandSKUList,
      createSKU,
      backDatedStatus,
      ledgerList,
      ledgerListPartial,
    } = this.state;
    const { fiscalYearRange } = this.props;
    const minDate = fiscalYearRange.previous_fiscal_year_closed ? fiscalYearRange.start_date : fiscalYearRange.previous_fiscal_year_start;
    return (
      <GRNStyled>
        <div className="grn-create">
          {loading && (
            <div className="linear-progress-wrapper temp-progress-wrapper">
              <LinearProgress accent indeterminate />
            </div>
          )}
          <div className={`grn-create${loading ? 'clickable-false' : ''}`}>
            <div className="message-snackbar">
              {
                <Snackbar
                  className={snack.success ? 'success-message' : ''}
                  timeout={snack.success ? 1000 : 4000}
                  onTimeout={() => {
                    this.setState(
                      ({ snack }) => ({ snack: { ...snack, show: false } }),
                      () => {
                        this.directToMainPage();
                      },
                    );
                  }}
                  open={snack.show}
                >
                  {snack.message}
                </Snackbar>
              }
            </div>
            <div className="card-header-bar clearfix" ref="tableReference">
              <div className="header-left">
                <h2>New GRN</h2>
              </div>

              <div className={`${backDatedStatus || 'disabled-opacityFull'} header-right document_date`}>
                <span className="date-picker-wrapper">
                  <label>Document Date:</label>
                  <DatePicker
                    date={filterUtil.getMomentDate(date)}
                    onChange={e => this.handleDateChange('document_date', e)}
                    maxDate={filterUtil.getCurrentDate()}
                    minDate={filterUtil.getMomentDate(minDate)}
                  />
                </span>
              </div>
            </div>
            <div className="card-body">
              <div className="radio-list-wrapper">
                {buList.length && <RadioButtonList data={buList} disableRosiaBu handleBuClick={this.handleBUClick} otherBU />}
              </div>
              <div className={businessUnitId !== null ? '' : 'block-overlay'}>
                <div className="three-input-wrapper">
                  <Grid>
                    <Cell col={3} className="input-select">
                      <BillingTextField
                        value={invoiceId}
                        param="invoiceId"
                        floatingLabel="Vendor Invoice Number"
                        required
                        className="billing-required"
                        emptyField={pageEmptyField}
                        handleChange={this.onInvoiceNumberChange}
                      />
                    </Cell>
                    <Cell col={2}>
                      <span className="date-picker-wrapper input_date">
                        <label>Vendor Invoice Date</label>
                        <DatePicker
                          date={filterUtil.getMomentDate(vendorInvoiceDate)}
                          onChange={e => this.handleDateChange('vendorInvoiceDate', e)}
                          maxDate={filterUtil.getCurrentDate()}
                        />
                      </span>
                    </Cell>
                    <Cell col={3} className="input-select">
                      <label>Vendor Name</label>
                      <CustomerSelect
                      value ={vendorDetails.vendorId}
                      param='name'
                      valueKey='customerId'
                      onHandleChange={this.onVendorDropDownChange}
                      required
                      pageValidation={pageEmptyField}
                      type='vendor'
                      ledgerList={ledgerList}
                      ledgerListPartial={ledgerListPartial}
                      />
                    </Cell>
                    <Cell col={2} className="input-field">
                      <BillingTextField
                        value={vendorDetails.address}
                        param="address"
                        floatingLabel="Address"
                        required
                        className='billing-required'
                        emptyField={pageEmptyField}
                        handleChange={(value,event)=>{this.onInputFieldChange('address',event,'vendorDetails')}}
                      />
                    </Cell>
                    <Cell col={2} className="input-field">
                      <BillingTextField
                        value={vendorDetails.panNo}
                        floatingLabel="PAN Number"
                        required
                        type="number"
                        rule="isInt"
                        className="billing-required no-pointer-events"
                        emptyField={pageEmptyField}
                      />
                    </Cell>
                    <Cell col={3} className="input-field">
                      <BillingTextField
                        value={billSummary.remarks}
                        param="remarks"
                        floatingLabel="Remarks"
                        required
                        className="billing-required"
                        emptyField={pageEmptyField}
                        handleChange={this.onInputFieldChange}
                      />
                    </Cell>
                  </Grid>
                </div>
                <div className="table-wrapper">
                  <div ref="fixedTableBody" className="fixed-table-wrapper">
                    <table>
                      <TableHeader
                        headerDetails={createGRNConfig.headerDetails}
                        filterHeaderLabel={false}
                        handleSorting={this.handleTableSorting}
                      />
                      {invoiceList.map((data, key) => (
                        <tbody>
                          <CreateGRNTableBody
                            data={data}
                            index={key}
                            deleteSalesInvoice={this.deleteSalesInvoice}
                            handleEditIconClick={this.handleEditIconClick}
                            handleDeleteIconClick={this.handleDeleteIconClick}
                          />
                        </tbody>
                      ))}
                    </table>
                  </div>

                  {/* Fab Icon */}
                  <div className="mini-fab-button">
                    <Fab mini onClick={() => this.setState({ createModalOpen: true })}>
                      <Icon name="add" />
                    </Fab>
                  </div>
                </div>
                {/* Sales invoice table summary goes over here. */}
                <DiscountBarStyled>
                  <div className="total-section-wrapper">
                    <Grid>
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        Sub Total
                      </Cell>
                      <Cell col={1} className="right-align">
                        {fixedFloatAndCommas(billSummary.net_amount)}
                      </Cell>
                    </Grid>
                    <Grid>
                      <Cell col={3} />
                      <Cell col={8} className="sub-total-wrap right-align">
                        <DiscountBarWithCheckBox
                          onCheckBoxClick={this.handleCheckBoxClick}
                          showCheckBox={billDiscountChecked}
                          discountParam="bill_discount"
                          checkBoxParam="billDiscountChecked"
                          invoiceAmount={billSummary.net_amount}
                          discountOptionValue={billSummary.bill_discount_param}
                          discountOptionParam="bill_discount_param"
                          discountAmount={billSummary.bill_discount}
                          discountLabel="Bill Discount"
                          onInputChange={this.onInputFieldChange}
                        />
                      </Cell>
                      <Cell col={1} className="right-align default-top-padding-12">
                        {fixedFloatAndCommas(billSummary.bill_discount_value)}
                      </Cell>
                    </Grid>
                    <Grid>
                      <Cell col={3} />
                      <Cell col={8} className="sub-total-wrap right-align">
                        <DiscountBarWithCheckBox
                          onCheckBoxClick={this.handleCheckBoxClick}
                          showCheckBox={tradeDiscountChecked}
                          discountParam="trade_discount"
                          checkBoxParam="tradeDiscountChecked"
                          invoiceAmount={billSummary.net_amount}
                          discountOptionValue={billSummary.trade_discount_param}
                          discountOptionParam="trade_discount_param"
                          discountAmount={billSummary.trade_discount}
                          discountLabel="Trade Discount"
                          onInputChange={this.onInputFieldChange}
                        />
                      </Cell>
                      <Cell col={1} className="right-align default-top-padding-12">
                        {fixedFloatAndCommas(billSummary.trade_discount_value)}
                      </Cell>
                    </Grid>
                    {JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && <Grid className="padding-top-24">
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        {' '}
                        Excise Duty
                      </Cell>
                      <Cell col={1} className="right-align">
                        {' '}
                        {fixedFloatAndCommas(billSummary.excise)}
                      </Cell>
                    </Grid> }
                    <Grid className="padding-top-24">
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        {' '}
                        Taxable Amount
                      </Cell>
                      <Cell col={1} className="right-align">
                        {' '}
                        {fixedFloatAndCommas(billSummary.actualTaxableAmount)}
                      </Cell>
                    </Grid>
                    <Grid>
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        VAT
                      </Cell>
                      <Cell col={1} className="right-align">
                        {fixedFloatAndCommas(billSummary.vat)}
                      </Cell>
                    </Grid>
                    <div className="hr" />
                    <Grid>
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        Total
                      </Cell>
                      <Cell col={1} className="right-align font-bold">
                        {fixedFloatAndCommas(billSummary.gross_amount)}
                      </Cell>
                    </Grid>
                  </div>
                </DiscountBarStyled>
              </div>
            </div>
          </div>
          <div className="newinvoice-btn-wrapper">
            <Grid>
              <Cell col={8} />
              <Cell col={4} className="right-align">
                <Button
                  accent
                  className="cancel-btn modal-btn"
                  onClick={() => {
                    this.handleCancelClick();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  accent
                  className={saveDisable ? 'save-btn modal-btn btn-disabled' : 'save-btn modal-btn'}
                  disabled={saveDisable}
                  onClick={() => {
                    this.handleSaveClick();
                  }}
                >
                  Save
                </Button>
              </Cell>
            </Grid>
          </div>

          {/* Begin of the dialog Body */}
          <GRNDialog
            editModalOpen={editModalOpen}
            createModalOpen={createModalOpen}
            deleteModalOpen={deleteModalOpen}
            state={this.state}
            closeModal={this.closeModal}
            onChange={ debouncer(this.onChange, 800)}
            onBatchInputChange={this.onBatchInputChange}
            handleModalOkClick={this.handleModalOkClick}
            batchDetails={batchDetails}
            onBatchFormCancel={this.onBatchFormCancel}
            onBatchFormSubmit={this.onBatchFormSubmit}
            batchFormOpen={batchFormOpen}
            onBatchFabClick={this.onBatchFabClick}
            batchDialogValidation={batchDialogValidation}
            brandSKUList={brandSKUList}
            loadOptions={createSKU.brand === '' ? this.getSkuList : this.getBrandSkuList}
          />
        </div>
      </GRNStyled>
    );
  }
}

CreateGoodsReceivedNote.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
  bu_id: state.billing.bu_id || null,
  fiscalYearRange: state.billing.fiscalYearRange || null,
});
const CreateGRN = connect(mapStateToProps)(CreateGoodsReceivedNote);

export default CreateGRN;
