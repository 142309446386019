import React, { Component } from 'react';
import moment from 'moment/moment';
import config from './config';
import history from '../../../utils/history';
import * as filterUtil from '../../../utils/filterUtil';
import { clone } from '../../../utils/arrayProcessor';
import CreditNoteStyled from '../CreditNoteStyled';
import DetailView from '../../common/detail/DetailView';
import withBaseState from '../../common/withBaseState';
import { CREDIT_NOTE } from '../../../data/enums/Route';
import { voucherDecoder } from '../../../utils/miscellaneous';
import DebitNoteStyled from '../../debitNote/DebitNoteStyled';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { Button, Grid, Cell ,Icon} from '../../../components/BillingMDC';
import Header from '../../../components/PrintComponent/Header';
import TableHeader from '../../../components/TableHeader/index';
import numberToWords from '../../../utils/numberToTextConverter';
import PageButtonWrapper from '../../common/PageButtonWrapper';
import { checkIfApiCallSuccess, creditNoteAPi ,ledgerApi,jvApi} from '../../common/base.api';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import VoucherFooter from '../../../components/PrintComponent/voucherFooter';
import {flattenDataPrintAttributeWise, groupPrintDataSet} from "../../common/print.service";
import * as snackService from '../../common/snack.service';
import BillingSnackBar from '../../../components/BillingMDC/BillingSnackbar';
import { validateForm } from '../../common/validation';
import { LEDGERS,VAT_PER_DERIVE, VAT_ZERO_PERCENTAGE } from '../../../data/enums/enums';
import CNDialogView  from './DialogView';
import { getBackDatedStatus, getValidationErrorMsg,getCustomerList} from '../../../views/fundamentals/common/helpers';
import CustomerSelect from '../../../common/CustomerSelect';

const propTypes = {};
const defaultProps = {};

class CreditNoteDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        summary: {},
      },
      userDetail: { id: 'userId', title: '' },
      preparedBy: { title: 'Prepared By', value: '' },
      dataList: [],
      print: false,
      dateTime: {
        date: filterUtil.getCurrentDay(),
        time: filterUtil.getCurrentTime(),
      },
      printInfo: [],
      snack: { ...snackService.snackParameters },
      backUpDataList:[],
      createCN: config.formMapper({}),
      createCNBackUp:{},
      editModalOpen:false,
      readModalOpen: false,
      editModalOpenLedger:false,
      idJournalVoucherDetail:0,
      validation: {
        flag: false,
        fieldList: config.formValidationFieldList,
      },
      showUpdateBtn:false,
      summary:{
        totalDrAmount:0,
        totalCrAmount:0,
        documentDate: filterUtil.getCurrentDay(),
      },
      ledgerList: [],
      ledgerListPartial:[],
      partyLedgerList:[],
      partyLedgerListPartial:[],
      partyLedgerId:0,
      editInput:false,
      documentDate: filterUtil.getCurrentDay(),
      drList:[],
      updateBtnSummary:false,
      partyLedgerCloned:0,
      backDatedStatus:getBackDatedStatus(),
      vatStatus:true,
      vatStatusBack:true,
    };
    this.dataListLength = 0;
  }
  componentDidMount() {
    this.getData();
    this.mapPrintInfo();
    this.getLedgerList();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

 // get the customer list for the selection.
 getLedgerList = async () => {
  const ledgerListAll =await getCustomerList('all');
  this.getOtherListList(ledgerListAll)
}
getOtherListList = (ledgerListAll) =>{
  const ledgerList =ledgerListAll.filter(a=>!([LEDGERS.VAT.type].includes(a.typeId)))
  const ledgerListPartial = ledgerList.slice(0,300);
  this.setState({partyLedgerListPartial:ledgerListPartial,ledgerListPartial,partyLedgerList:ledgerList,ledgerList}) 
}

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  mapPrintInfo = () => {
    const { dateTime } = this.state;
    const { user } = this.props;
    const printInfo = [
      { title: 'Printed On', value: dateTime.date },
      { title: 'Printed Time', value: dateTime.time },
      { title: 'Printed By', value: user.name },
    ];
    this.setState({ printInfo });
  };

  getData = () => {
    const { match } = this.props;
    const { preparedBy ,summary} = this.state;
    const voucher_number = voucherDecoder(match.params.id);

    creditNoteAPi.getDetails({ voucher_number, user_id: match.params.userId || '' }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const { journalVoucherDetail, journalVoucher } = response.data;
        const {
          narration, drAmount, crAmount, date = journalVoucher.transactionDate, mitiTitle, voucherNumber, type,documentDate,idJournalVoucher,
        } = journalVoucher;
        const formattedList = flattenDataPrintAttributeWise(journalVoucherDetail, config.lengthyAttributes, config.getData);

        const customerIds = journalVoucherDetail.map(x => x.customer.customerId );
        const drCrAmountZero =journalVoucherDetail?.some(a =>a.drAmount === 0 && a.crAmount===0);
        const partyLedgerIdentical = customerIds.length !== _.uniq(customerIds).length;
        const idJvDetailMaxList =journalVoucherDetail.sort((a, b) => b.idJournalVoucherDetail - a.idJournalVoucherDetail)[0];
        const vatList = config.getFilteredList(config.VAT_LIST,journalVoucherDetail);
        let drList=[],crList=[];
         if(drCrAmountZero && partyLedgerIdentical){
           crList = [idJvDetailMaxList];
           drList= _.differenceWith(journalVoucherDetail, [idJvDetailMaxList,vatList[0]], _.isEqual);
        }
        else if(drCrAmountZero){
          const vatPartyId = config.getFilteredList(config.VAT_PARTY_ID,journalVoucherDetail);
           crList=config.getFilteredList(config.CR_LIST,journalVoucherDetail,vatPartyId);
           drList=config.getFilteredList(config.DR_LIST,journalVoucherDetail,vatPartyId)
          }
        else{
          drList = journalVoucherDetail.filter(
            (a) => a?.customer?.customerId !== LEDGERS?.VAT?.ledger_id && a?.crAmount === 0,
          );
          crList =journalVoucherDetail?.filter(a => a?.drAmount === 0 && a?.customer?.customerId !== LEDGERS?.VAT?.ledger_id);
        }
        const data = {
          list: formattedList,
          summary: {
            narration,
            crAmount,
            drAmount: drList.reduce((prev, cur) => prev + cur.drAmount, 0),
            date,
            mitiTitle,
            idJournalVoucher,
            voucherNumber,
            type,
            totalInWords: numberToWords(crAmount || 0),
            partyLedger: crList[0].customer.title,
            panNo: crList[0].customer.panNo,
            vatAmount: vatList[0].drAmount,
            refDate: crList[0].refDate,
            refNumber: crList[0].refNumber,
          },
        };
        const userDetail = config.userDetailMapper(response.data);
        const documentDateUpdated =filterUtil.formatToDateMonthYear(documentDate);
        const backUpDataListing= clone(drList);
        const partyLedgerIdMapped=crList[0].customer.customerId;
        summary.mainNarration = narration;
        summary.documentDate = documentDateUpdated;
        preparedBy.value = userDetail.title;
        const vatStatusCheck =vatList[0]?.drAmount > 0 ? true : false
        this.setState({ data, userDetail,backUpDataList:clone(backUpDataListing),preparedBy,summary,documentDate:clone(documentDateUpdated),drList:drList,partyLedgerCloned:clone(partyLedgerIdMapped),partyLedgerId:partyLedgerIdMapped,vatStatus:vatStatusCheck,vatStatusBack:clone(vatStatusCheck)});
      }
    });
  };

  printInvoice = () => {
    const self = this;
    const { match } = this.props;
    self.setState({ print: true }, () => {
      window.print();
    });

    window.onafterprint = function () {
      if (match.params.userId) {
        window.close();
      } else {
        history.push(`/${CREDIT_NOTE}`);
      }
    };
  };
  
  handlePrintClick = () => {
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ print: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ print: false });
    };
  };
  handleUpdate = () => {
    this.setState({readModalOpen:true});
    }


  btnWrapper = () => {
    const { showUpdateBtn,updateBtnSummary} = this.state;
     return(
    <>
      <Button accent className="cancel-btn modal-btn" onClick={() => history.push(`/${CREDIT_NOTE}`)}>
        Cancel
      </Button>
          { (updateBtnSummary || showUpdateBtn) || 
          <Button accent className="margin-right-0 save-btn modal-btn" onClick={() => this.handlePrintClick()}>
            Print
          </Button>
      }
      { (updateBtnSummary || showUpdateBtn) && 
      <Button
        accent
        className="margin-right-0 save-btn modal-btn"
        onClick={() => {
          this.handleUpdate();
        }}
      >
        Update
      </Button>
  }
    </>
  )};
  groupPrintDataSetNew() {
    const { drList} = this.state;
    const { company  } = this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(drList);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }


  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };
  handleEditIconClick = list => { 
    this.setState({
      createCN:config.formMapper(list),
      createCNBackUp:clone({list}),
      editModalOpen:true,
      idJournalVoucherDetail:list.idJournalVoucherDetail,
    }
     )
  };
  onChange = (field, value, all = {}) => {
    const { createCN} = this.state;
    if (field === 'document_date') {
      const  date = filterUtil.formatToNumericDate(value);
      this.setState({ summary:{documentDate:date} });
    }
    else if(field ==='partyLedgerId'){
      this.setState({partyLedgerId:value})
    }
    else if(field==='vatStatus'){
      this.setState({vatStatus:value},()=>{
        this.updateDataSummary();
      });
    }
    else if(field ==='customerId'){
      const createCNmerged={...createCN,customerId:value}
      this.setState({createCN:createCNmerged})
    }
    else{
      createCN[field] =value;
      this.setState({createCN});
    }
  };
  handleModalClose = () => {
    const {editModalOpenLedger,editModalOpen,data,backUpDataList,idJournalVoucherDetail,readModalOpen} =this.state;
    this.resetJVDialog();
    if(readModalOpen){
      this.getData();
      this.resetBtn();
      return;
    }
    if(editModalOpenLedger){
      this.onEditSummaryClose();
      this.resetJVDialog();
      return;
    }
    if(editModalOpen){
  const dataList= backUpDataList.find(a=>a.idJournalVoucherDetail ===idJournalVoucherDetail);
  let dataIndex = data?.list?.findIndex((obj => obj.idJournalVoucherDetail == idJournalVoucherDetail));
  data?.list.splice(dataIndex, 1, dataList);
   this.setState({data,showUpdateBtn:false},()=>{
    this.updateDataSummary();
    this.getChangedDataList();
  });
}
  };
  resetJVDialog = () => {
    this.setState({ editModalOpen: false,readModalOpen: false,formEmptyField: false,editModalOpenLedger:false});
  }
  resetSummary =() =>{
    const {documentDate,summary,partyLedgerCloned,data,partyLedgerList,partyLedgerId} =this.state;
    summary.documentDate=documentDate;
    data.summary.partyLedger =partyLedgerList.find(a=>a.customerId===partyLedgerCloned)?.title;

    const customerIds = data?.list.map(x => x.customer.customerId);
    const drCrAmountZero =data?.list?.some(a =>a.drAmount === 0 && a.crAmount===0);
    const partyLedgerIdentical = customerIds.length !== _.uniq(customerIds).length;
    const idJvDetailMaxList =data?.list.sort((a, b) => b.idJournalVoucherDetail - a.idJournalVoucherDetail)[0];
    const vatListPartyId = config.getFilteredList(config.VAT_PARTY_ID,data?.list);
    const vatListIndex = config.getFilteredListIndex(config.VAT_INDEX,data?.list,vatListPartyId);
    let crListIndex=[];
     if(drCrAmountZero && partyLedgerIdentical){
      const crListIndexId = idJvDetailMaxList?.idJournalVoucherDetail;
      crListIndex =data?.list?.findIndex(a =>a?.idJournalVoucherDetail === crListIndexId);
    }
    else if(drCrAmountZero){
     crListIndex = config.getFilteredListIndex(config.CR_INDEX,data?.list,vatListPartyId);
    }
    else{
      crListIndex =data?.list.findIndex(a => a?.drAmount === 0 &&  a?.customer?.customerId !== LEDGERS?.VAT.ledger_id);
    }
    data.list[crListIndex].customer.customerId=clone(partyLedgerCloned) ;
    data.list[vatListIndex].partyLedgerId= clone(partyLedgerCloned );

   this.setState({partyLedgerId:clone(partyLedgerCloned),summary,data})
  }
  resetBtn = () => {
    this.setState({showUpdateBtn:false,editInput:false,updateBtnSummary:false});
  };
  getSummaryData =(drAmountSum,vat) =>{
    const {data,summary,partyLedgerId,partyLedgerCloned,documentDate,vatStatusBack,vatStatus} =this.state;
    const totalCrAmount = data.list.reduce((a, b) => ({crAmount: a.crAmount + b.crAmount})) || 0;
    const totalDrAmount = data.list.reduce((a, b) => ({drAmount: a.drAmount + b.drAmount})) || 0;
    data.summary.drAmount = drAmountSum|| 0;
    data.summary.vatAmount = drAmountSum * vat || 0;
    data.summary.crAmount = drAmountSum+  drAmountSum * vat || 0;
    summary.totalCrAmount =totalCrAmount.crAmount;
    summary.totalDrAmount =totalDrAmount.drAmount;
    if( clone(vatStatusBack) ===vatStatus){
      this.setState({updateBtnSummary:false})
    }
    if(documentDate !==summary?.documentDate || partyLedgerId !==partyLedgerCloned  || clone(vatStatusBack) !==vatStatus){
      this.setState({updateBtnSummary:true})
    }
    this.setState({summary,data});
  }
  updateDataSummary = () => {
    const {data,summary} =this.state;
    const customerIds = data?.list.map(x => x.customer.customerId);
    const drCrAmountZero =data?.list?.some(a =>a.drAmount === 0 && a.crAmount===0);
    const partyLedgerIdentical = customerIds.length !== _.uniq(customerIds).length;
    const idJvDetailMaxList =data?.list.sort((a, b) => b.idJournalVoucherDetail - a.idJournalVoucherDetail)[0];
    const vatPartyId = config.getFilteredList(config.VAT_PARTY_ID,data?.list);
    const vatListIndex = config.getFilteredListIndex(config.VAT_INDEX,data?.list,vatPartyId);
    let crListIndex=[],drList=[];
     if(drCrAmountZero && partyLedgerIdentical){
      const crListIndexId = idJvDetailMaxList?.idJournalVoucherDetail;
      crListIndex =data?.list?.findIndex(a =>a.idJournalVoucherDetail === crListIndexId);
      drList = data?.list?.filter(a =>  a.customer?.customerId !== LEDGERS.VAT.ledger_id && a?.idJournalVoucherDetail !== crListIndexId);
    }
    else if(drCrAmountZero){
      crListIndex = config.getFilteredListIndex(config.CR_INDEX,data?.list,vatPartyId);
      drList=config.getFilteredList(config.DR_LIST,data?.list,vatPartyId);
   }
    else{
      drList = data?.list.filter(
        (a) => a?.customer?.customerId !== LEDGERS?.VAT?.ledger_id && a?.crAmount === 0
      );
      crListIndex =data?.list.findIndex(a => a?.drAmount === 0 &&  a?.customer?.customerId !== LEDGERS?.VAT?.ledger_id);
    }
    const vat =this.getVatPer();
    const drAmountSum =drList.reduce( ( sum, { drAmount } ) => sum + drAmount , 0)
    data.list[vatListIndex].drAmount = drAmountSum * vat || 0;
    data.list[crListIndex].crAmount = drAmountSum + drAmountSum * vat || 0;
    this.setState({data,summary,drList},()=>{
      this.getSummaryData(drAmountSum,vat);
    })
  }
  getVatPer = () =>{
    const {vatStatus} =this.state;
    const vat =vatStatus ? VAT_PER_DERIVE : VAT_ZERO_PERCENTAGE;
    return vat;
  }
  
    handleModalOk = () => {
      const { editModalOpen, createCN ,data,validation,summary,partyLedgerId,editModalOpenLedger,partyLedgerList} = this.state;
      const customerIds = data?.list.map(x => x.customer.customerId);
      const drCrAmountZero =data?.list?.some(a =>a.drAmount === 0 && a.crAmount===0);
      const partyLedgerIdentical = customerIds.length !== _.uniq(customerIds).length;
      const idJvDetailMaxList =data?.list.sort((a, b) => b.idJournalVoucherDetail - a.idJournalVoucherDetail)[0];
      const vatListPartyId = config.getFilteredList(config.VAT_PARTY_ID,data?.list);
      const vatListIndex = config.getFilteredListIndex(config.VAT_INDEX,data?.list,vatListPartyId);
      let crListIndex=[],drIdListIndex=[];
       if(drCrAmountZero && partyLedgerIdentical){
        const crListIndexId = idJvDetailMaxList?.idJournalVoucherDetail;
        crListIndex =data?.list?.findIndex(a =>a.idJournalVoucherDetail === crListIndexId);
        drIdListIndex = data?.list?.findIndex(a =>  a.customer?.customerId !== LEDGERS.VAT.ledger_id && a?.idJournalVoucherDetail !== crListIndexId && a?.idJournalVoucherDetail === this.state.idJournalVoucherDetail);
      }
      else if(drCrAmountZero){
       crListIndex = config.getFilteredListIndex(config.CR_INDEX,data?.list,vatListPartyId);
       drIdListIndex = config.getFilteredListIndex(config.DR_WITH_ID_INDEX,data?.list,vatListPartyId, this.state.idJournalVoucherDetail);
      }
      else{
        drIdListIndex = data?.list.findIndex(
          (a) => a.customer.customerId !== LEDGERS.VAT.ledger_id && a.crAmount === 0 && a?.idJournalVoucherDetail === this.state.idJournalVoucherDetail,
        );
        crListIndex =data?.list.findIndex(a => a?.drAmount === 0 &&  a?.customer?.customerId !== LEDGERS?.VAT?.ledger_id);
      }
      if ( editModalOpen) {
        const formValid = validateForm(createCN, validation, valid => this.setState({ validation: valid }));
        if (formValid) {
          data.list[drIdListIndex].drAmount = createCN.drAmount || 0;
          data.list[drIdListIndex].narration = createCN.narration || '';
          data.list[drIdListIndex].customer.customerId = createCN?.customerId;
          data.list[crListIndex].crAmount = createCN?.drAmount + (createCN?.vatAmount || 0);
          this.setState({data,summary},()=>{
            this.getChangedDataList();
            this.updateDataSummary();
          })
          this.resetJVDialog();
      } else {
        this.setState({data})
        // this.resetJVDialog();
      }
    }
    else if(editModalOpenLedger){
      data.list[crListIndex].customer.customerId= partyLedgerId ;
      data.list[vatListIndex].partyLedgerId= partyLedgerId ;
      data.summary.partyLedger = partyLedgerList.find(a=>a.customerId ===partyLedgerId).title;
      this.setState({data},()=>{
        this.updateDataSummary();
      })
      this.resetJVDialog();
    }
    else{
      this.getUpdateApi();
      this.resetJVDialog();
    }
    };
    compareDataList =(otherArray) =>{
      return function(c){
        return otherArray.filter(function(o){
          return o.drAmount == c.drAmount && o.crAmount == c.crAmount && o.narration == c.narration && o.customer.customerId == c.customer.customerId 
        }).length == 0;
      }
    }
    getChangedDataList =() =>{
      const {data,backUpDataList}= this.state;
      let result = data.list.filter(o1 => backUpDataList.some(o2 => o1.idJournalVoucherDetail === o2.idJournalVoucherDetail));
      const filteredData = result.filter(this.compareDataList(backUpDataList))
      this.setState({showUpdateBtn: !!filteredData.length,backUpDataList:clone(backUpDataList)})
      return filteredData;
    }
    getUpdateApi = () => {
      const {data,summary,documentDate} =this.state;
      const {updateApi} =this.props;
      const apiTransformedData =config.updateApiTransformedData(data.list,summary,documentDate);
      updateApi({
          id: data.summary.idJournalVoucher,
          query: apiTransformedData,
        })
          .then(response => {
            const snack = snackService.generateUpdateMessage();
            this.setState({ snack ,showUpdateBtn:false,updateBtnSummary:false});
            this.handleModalClose();
            this.getData();
          })
          .catch(err => {
            const snack = snackService.generateFailureMessage(getValidationErrorMsg(err) || 'Error while updating CN Details');
            this.setState({ snack });
          });
      }
  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };
  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  handleDateChange = (field, value) => {
    const {summary } = this.state
      summary.documentDate =filterUtil.formatToDateMonthYear(value);
      this.setState({ documentDate: summary.documentDate,summary});
  };
  handleEdit= () =>{
    this.setState({editInput:true,editModalOpenLedger:true});
  }
  onEditSummaryClose=()=>{
    this.resetSummary();
    this.setState({editInput:false,updateBtnSummary:false});
  }
  getLedgerSelectOptions = (field,value,type='') => {
    const {validation,ledgerList,ledgerListPartial,partyLedgerList,partyLedgerListPartial} =this.state;
    return(
      <CustomerSelect
      value={value}
        param={field}
        valueKey='customerId'
        clearable
        onHandleChange={this.onChange}
        required
        pageValidation={validation.flag}
        ledgerList={field ==='partyLedgerId' ? partyLedgerList : ledgerList}
        ledgerListPartial={field==='partyLedgerId'? partyLedgerListPartial: ledgerListPartial}
        type={type}
        />
    )
  }


  render() {
    const { serverResponseWaiting, company, user } = this.props;
    const { data, print, dataMiscellaneousList, dataList, printInfo ,editModalOpen,ledgerList,validation,readModalOpen,createCN,snack,partyLedgerList,editInput,editModalOpenLedger,partyLedgerId,drList,summary,backDatedStatus,vatStatus} = this.state;
    return (
      <CreditNoteStyled className={`credit_note-details ${serverResponseWaiting ? 'clickable-false' : ''}`}>
        <div className="edit-cancel print_display_none">
        <Icon name='edit' className ={`${editInput && 'active'}`} onClick={this.handleEdit} />
        <Icon name='close' onClick={this.onEditSummaryClose}/>
        </div>
        <div className={print ? 'no-print' : 'display-block'}>
          <DetailView
            display={{
              header: true,
              footer: true,
              summary: true,
            }}
            serverResponseWaiting={serverResponseWaiting}
            headerConfig={{
              company,
              title: config.title,
              miti: data.summary.mitiTitle,
              date: moment(summary?.documentDate || new Date()).format('DD MMM YYYY'),
            }}
            pageConfig={config}
            data={data}
            onTableBodyClick={this.handleEditIconClick}
            ledgerList={ledgerList}
            print={print}
            handleDateChange={this.handleDateChange}
            drList={drList}
            onChange={this.onChange}
            vatStatus={vatStatus}
            />
          <CNDialogView
            onModalClose={this.handleModalClose}
            createCN ={createCN}
            editModalOpen={editModalOpen}
            readModalOpen={readModalOpen}
            handleInputChange={this.onChange}
            onModalSubmit={this.handleModalOk}
            formEmptyField={validation.flag}
            ledgerList={ledgerList}
            renderLedgerSelectOptions={this.getLedgerSelectOptions}
            partyLedgerList={partyLedgerList}
            editModalOpenLedger={editModalOpenLedger}
            partyLedgerId={partyLedgerId}
            documentDate={summary?.documentDate}
            backDatedStatus={backDatedStatus}
          />

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
          <PageButtonWrapper renderBtn={this.btnWrapper} />
        </div>
          <div className={print ? 'display-block portrait credit_note_print' : 'no-print'}>
            {dataList.map((ceItem, key) => (
              <div className="print-body zoom-less-reduce voucher-print">
                <div className="jv card-body">
                  <Header
                    company={company}
                    divClassName={
                      key === 0 ? 'first-header-bar header-border-bottom' : 'header-bar header-border-bottom'
                    }
                    date={moment(summary?.documentDate || new Date()).format('DD MMM YYYY')}
                    miti={data.summary.mitiTitle}
                    pageTitle={<h2>{config.title}</h2>}
                    dataMiscellaneousList={dataMiscellaneousList}
                  />
                  <div className="custom-three-input-wrapper padding-bottom-0 print-invoice three-input-wrapper">
                    <Grid>
                      {config.getSummaryViewList(data.summary).map((item, key) => {
                        return (
                          <Cell col={4} key={key} className="input-print">
                            <label htmlFor="">{item.title}</label>
                            <p className="text-area print">{item.value}</p>
                          </Cell>
                        );
                      })}
                    </Grid>
                  </div>
                  <div className={` fixed-table-wrapper ${!ceItem.footer ? 'fixed-table-height ' : 'total-footer'}`}>
                    <table>
                      <TableHeader headerDetails={config.header} filterHeaderLabel={false} />
                      {config.getTableBody({ dataList: ceItem.list,remainingLines:ceItem.remainingLines,ledgerList,print})}
                    </table>
                  </div>
                  <footer>
                    {ceItem.footer && (
                      <table className="print-active total-section-wrapper footer clearfix top">
                        <tfoot className="table-tfoot sub-total">
                          {config.getFooterContent(data.summary).map((item, index) => (
                            <tr className={`text-right height-25 font-bold ${item.className}`}>
                              <td className="total-content-data text-right item_title font-bold">{item.title}</td>
                              <td className="total-content-data text-right item_value padding-r-4">
                                {fixedFloatAndCommas(item.value || 0)}
                              </td>
                            </tr>
                          ))}
                        </tfoot>
                      </table>
                    )}
                    {ceItem.footer && (
                      <table className="left-footer print-active total-section-wrapper footer clearfix">
                        <>
                          <VoucherFooter
                            totalInWords={data.summary.totalInWords}
                            lineMargin="padding-r-24"
                            remarksStatus
                            remarks={data.summary.narration}
                            total={data.summary.crAmount}
                            authorizeSign
                            preparedByStatus={false}
                            printAllInfo
                            invoicedBy
                            print
                            printInfo={printInfo}
                            user={user}
                          />
                        </>
                      </table>
                    )}
                  </footer>
                </div>
                <div className="footer-block">
                  <PageNumber value={ceItem.page} totalPage={dataList.length} />
                </div>
              </div>
            ))}
          </div>
      </CreditNoteStyled>
    );
  }
}

CreditNoteDetail.propTypes = propTypes;
CreditNoteDetail.defaultProps = defaultProps;

const CreditNoteDetailWithState = withBaseState(CreditNoteDetail);

export default CreditNoteDetailWithState;
