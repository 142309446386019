import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon } from '../../../../components/BillingMDC';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { VALIDATION_TYPES } from '../../../common/validation';

const CreatePurchaseReturnTableBody = ({
  dataList,
  selectedCheckBox,
  showCheckBox,
  handleEditIconClick,
  handleDeleteIconClick,
  handleCheckBoxClick,
}) => (
  <tbody>
    {dataList.map((data, key) => (
      <tr>
        <td>
          {!showCheckBox && <span>{key + 1}</span>}
          {showCheckBox && (
            <Checkbox
              checked={selectedCheckBox.includes(data.idPurchaseDetail)}
              onChange={({ target: { checked } }) => {
                handleCheckBoxClick(data.idPurchaseDetail, checked);
              }}
            />
          )}
        </td>
        <td>{data?.skuTitle || ''}</td>
        <td>{data?.batchTitle || ''}</td>
        <td className='right-align'>{data?.quantity || 0}</td>
        <td className='right-align'>{data?.normal || 0}</td>
        <td className='right-align'>{data?.damage || 0}</td>
        <td className='right-align'>{data?.shortage || 0}</td>
        <td className='right-align'>{data?.expiry || 0}</td>
        <td className='right-align'>{fixedFloatAndCommas(data?.rate) || 0}</td>
        <td className='right-align'>{fixedFloatAndCommas(data?.amount) || 0}</td>
        <td className='right-align'>{fixedFloatAndCommas(data?.discount) || 0}</td>
        <td className='right-align'>{fixedFloatAndCommas(data?.net_amount || 0)}</td>
        <td className='right-align'>
          <Icon name='edit' onClick={() => handleEditIconClick(data, key)} />
          <Icon name='delete' onClick={() => handleDeleteIconClick(key)} />
        </td>
      </tr>
    ))}
  </tbody>
);

CreatePurchaseReturnTableBody.defaultProps = {
  selectedCheckBox: [],
  showCheckBox: false,
};

CreatePurchaseReturnTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  handleDeleteIconClick: PropTypes.func,
  selectedCheckBox: PropTypes.array,
  handleCheckBoxClick: PropTypes.func,
  showCheckBox: PropTypes.bool,
};

const createPurchaseReturnConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'test',
      title: 'SN',
      className: null,
      sortable: false,
    },
    {
      id: 2,
      label: 'sku',
      title: 'SKU',
      className: null,
      sortable: false,
    },
    {
      id: 3,
      label: 'batch',
      title: 'Batch',
      className: null,
      sortable: false,
    },
    {
      id: 4, label: 'rate', title: 'Rate', className: 'right-align', sortable: false,
    },
    {
      id: 5, label: 'quantity', title: 'Quantity (pcs)', className: 'right-align', sortable: false,
    },
    {
      id: 6, label: 'normal', title: 'Sellable (pcs)', className: 'right-align', sortable: false,
    },
    {
      id: 7, label: 'damage', title: 'Damage (pcs)', className: 'right-align', sortable: false,
    },
    {
      id: 8, label: 'shortage', title: 'Shortage (pcs)', className: 'right-align', sortable: false,
    },
    {
      id: 9, label: 'expiry', title: 'Expiry (pcs)', className: 'right-align', sortable: false,
    },
    {
      id: 10, label: 'discount', title: 'Discount', className: 'right-algin', sortable: false,
    },
    {
      id: 11, label: 'amount', title: 'Amount', className: 'right-align', sortable: false,
    },
    /* {id: 8, label: 'test', title: '', className: null, sortable: false}, */
  ],
  headerTitleToView: ['test', 'sku', 'rate', 'qty', 'promotion', 'discount', 'amount'],
};

const createSkuMapper = (data) => ({
  amount: data.amount || 0,
  idPurchaseDetail: data.idPurchaseDetail || null,
  batch_id: data.batchId || null,
  batchTitle: data.batch || '',
  brand: '',
  brandTitle: '',
  sku_id: data.skuId || '',
  skuTitle: data.sku || '',
  vat: data.vat || 0,
  quantity: data.quantity,
  rate: data.rate,
  business_id: data.businessId || '',
  discount: data.discount || 0,
  net_amount: data.netAmount || 0,
  vendor_invoice_number: data.vendorInvoiceNumber || '',
  actual_quantity: data.actualQuantity ? data.actualQuantity : data.quantity || 0,
  actual_discount: data.originalDiscount ? data.originalDiscount : data.discount || 0,
  vat_percent: data.vatPercent || data.vat_percent || 0,
  bill_discount: data.bill_discount || 0,
  trade_discount: data.trade_discount || 0,
  line: data.line || 0,
  compare_quantity: data.quantity,
  compare_normal: data.normal,
  compare_damage: data.damage,
  compare_expiry: data.expiry,
  compare_shortage: data.shortage,
  tax_exempted_amount: data.vat === 0 ? (data.taxExemptedAmount? data.taxExemptedAmount + data.excise:data.tax_exempted_amount + data.excise ):data.taxExemptedAmount?data.taxExemptedAmount:data.tax_exempted_amount|| 0,
  normal: data.normal || 0,
  damage: data.damage || 0,
  expiry: data.expiry || 0,
  shortage: data.shortage || 0,
  excise: data.excise /(data.actualQuantity ? data.actualQuantity : data.quantity)* data.quantity|| 0,
  excisePercent: data.excisepercent ||0
});

const vendorMapper = (customer) => ({
  id: customer.idLedger || '',
  vendorId: customer.customerId || '',
  title: customer.title || '',
  address: customer.address || '',
  panNo: customer.panNo || null,
});

const message = {
  serverError: '!Server Error',
  success: 'Purchase Return created successfully.',
  error: '!Error while creating Purchase Return',
  invoiceNumberNotFound: '!Invoice Number not found',
  invoiceListNotSelected: 'Purchase not selected.',
  misMatchBusinessId: '!Invoice number belong to the other Business Unit.',
};

const formValidateFieldArray = [
  { title: 'sku_id' },
  { title: 'rate', type: VALIDATION_TYPES.NULL_ERROR },
  { title: 'quantity', type: VALIDATION_TYPES.ZERO_ERROR },
  { title: 'discount', type: VALIDATION_TYPES.ZERO_VALID },
];
const pageValidateFieldArray = [
  { title: 'title' },
  { title: 'remarks' },
  { title: 'purchase_invoice_number' },
  { title: 'panNo', type: VALIDATION_TYPES.PAN_NO },
];
const editFormValidateFieldArray = [
  { title: 'rate', type: VALIDATION_TYPES.NULL_ERROR },
  { title: 'quantity', type: VALIDATION_TYPES.ZERO_ERROR },
  { title: 'discount', type: VALIDATION_TYPES.ZERO_VALID },
];

const quantityDefaultValues = { normal: 0, expiry: 0, shortage:0, damage: 0 };

export {
  createSkuMapper,
  vendorMapper,
  editFormValidateFieldArray,
  formValidateFieldArray,
  message,
  quantityDefaultValues,
  pageValidateFieldArray,
  createPurchaseReturnConfig,
  CreatePurchaseReturnTableBody,
};
