import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { getPercentage } from '../../../../utils/miscellaneous';
import { VAT_PERCENTAGE } from '../../../../data/enums/enums';

const title = {
  original: 'Purchase Return',
};
const getSummaryViewList = (data = {}) => [
  {
    title: 'PRN Invoice Number',
    value: data?.purchaseReturnInvoiceNumber || '',
  },
  {
    title: 'GRN Number',
    value: data?.voucherNumber || '',
  },
  {
    title: 'Vendor',
    value: data?.customer ? data?.customer?.title : '',
  },
  {
    title: 'Address',
    value: data?.address ||  '',
  },
  {
    title: 'Remarks',
    value: data?.remarks || '',
  },
];
const customerDetailMapper = (detail) => ({
  invoiceId: detail.refNumber || '',
  customerName: detail.customer ? detail.customer.title : '',
  customerAddress: detail.customer ? detail.customer.address : '',
  panNo: detail.customer ? detail.customer.panNo : '',
  salesReturnNumber: detail.salesReturnInvoiceNumber || '',
});
const billSummaryMapper = (data) => ({
  totalValue: data.netAmount || 0,
  remarks: data.remarks || '',
  totalInWords: data.totalInWords || '',
});
const getTotalContent = (data) => ({ title: 'Total', value: data.netAmount || 0 });

const PRNDetailBody = ({ dataList = [],printInfoBill={},  remainingLines = [], }) => (
  <tbody>
    {
      dataList.map((data, index) => (
        <tr className='cursor-pointer'>
          <td className='left-align sn'>{data.index ? data.index + 1 : index + 1}</td>
          <td className='left-align sku'>{data.sku}</td>
          {printInfoBill.batchEnabled && <td className='left-align batch'>{data.batch}</td>}
          <td className='right-align rate'>{fixedFloatAndCommas(data.rate)}</td>
          <td className='right-align quantity'>{data.quantity}</td>
          <td className='right-align print_display_none'>{data.normal}</td>
          <td className='right-align print_display_none'>{data.damage}</td>
          <td className='right-align print_display_none'>{data.shortage}</td>
          <td className='right-align print_display_none'>{data.expiry}</td>
          <td className='right-align discount'>{fixedFloatAndCommas(data.discount)}</td>
          <td className='right-align netAmount'>{fixedFloatAndCommas(data.netAmount)}</td>
        </tr>
      ))
    }
    {remainingLines.length ? remainingLines.map((i) => (
      <tr className='blank-tr'>
        <td></td>
        {printInfoBill.batchEnabled && <td></td>}
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    )):null}
  </tbody>
);

PRNDetailBody.propTypes = { dataList: PropTypes.instanceOf(Array).isRequired, };

const prnDetailConfig = [
  {
    id: 1,
    label: '',
    title: 'SN',
    className: 'cursor-pointer',
    sortable: false,
  },
  {
    id: 2,
    label: 'sku',
    title: 'SKU',
    className: 'cursor-pointer',
    sortable: true,
  },
  // {
  //   id: 2, label: 'batch', title: 'Batch', className: 'cursor-pointer', sortable: true,
  // },
  {
    id: 3,
    label: 'rate',
    title: 'Rate',
    className: 'cursor-pointer right-align',
    sortable: true,
  },
  {
    id: 4,
    label: 'quantity',
    title: 'Quantity',
    className: 'cursor-pointer right-align',
    sortable: false,
  },
  {
    id: 5, label: 'normal', title: 'Sellable', className: 'right-align print_display_none', sortable: false,
  },
  {
    id: 6, label: 'damage', title: 'Damage', className: 'right-align print_display_none', sortable: false,
  },
  {
    id: 7, label: 'shortage', title: 'Shortage', className: 'right-align print_display_none', sortable: false,
  },
  {
    id: 7, label: 'expirty', title: 'Expiry', className: 'right-align print_display_none', sortable: false,
  },
  {
    id: 8, label: 'discount', title: 'Discount', className: 'cursor-pointer right-align', sortable: false,
  },
  {
    id: 9, label: 'netAmount', title: 'Net Amount', className: 'cursor-pointer right-align', sortable: false,
  },
];
const lengthyAttributes = ['sku'];
const printEnums = {
  withoutFooterLimit: 44,
  withFooterLimit: 32,
  sku: 69,
  batch: 1,
  lengthyAttributes,
};
const printEnumsBatch = {
  withoutFooterLimit: 54,
  withFooterLimit: 40,
  sku: 40,
  batch: 24,
  lengthyAttributes: [...lengthyAttributes, 'batch'],
};

const config = {
  title,
  header: prnDetailConfig,
  getTableBody: PRNDetailBody,
  getSummaryViewList,
  customerDetailMapper,
  //getFooterContent,
  getTotalContent,
  billSummaryMapper,
  printEnums,
  printEnumsBatch,
  uniqueIdGetter: (i) => i.idPurchaseReturnDetail,
};

export default config;
