import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../../../common/Loading';
import Pagination from '../../../components/Pagination';
import IrdReportPrintBody from '../../../components/PrintComponent/IrdReportPrintBody';
import TableHeader from '../../../components/TableHeader';
import { PAGINATION, SORTING } from '../../../data/enums/enums';
import * as filterUtil from '../../../utils/filterUtil';
import styled from 'styled-components';
// import { config } from '../../irdReports/salesVatReport/config';
import { defaultHeaderConfig } from './config';
import IrdHeader from './IrdHeader';
import { isObjectEmpty } from '../../../utils/objectProcessor';

const IrdView = ({ ...props }) => {
  const {
    display,
    queryParameters,
    nepaliDate,
    domainConfig,
    handlePrintClick,
    controlDisplay,
    resetFilter,
    company,
    handleDateRangeChange,
    handleDateFilterChange,
    handleDownloadClick,
    handleTableSorting,
    tableConfig,
    dataSet,
    columnTotal,
    handlePageSelect,
    tableStructure,
    headerConfig,
    totalData,
    printList,
    printFlag,
    loading,
    colSpan_t_r,
    colSpan_t_l,
    disableUpperHeaderDetails,
    enableTableFooter,
    colSpanTotal,
    nepaliDateExist,
    singleDateExist,
    printColumnTotal,
    printFooterEnable,
    onTableBodyClick,
    totalDetails,
  } = props;
  return (
    <IrdStyled>
      <Loading display={loading} />
      <div className='card-body'>
        <div className={printFlag ? 'no-print' : 'display-block'}>
          <div>
            <IrdHeader
              config={headerConfig}
              display={display}
              queryParameters={queryParameters}
              nepaliDate={nepaliDate}
              domainConfig={domainConfig}
              handlePrintClick={handlePrintClick}
              company={company}
              controlDisplay={controlDisplay}
              resetFilter={resetFilter}
              handleDateRangeChange={handleDateRangeChange}
              handleDateChange={handleDateFilterChange}
              handleDownloadClick={handleDownloadClick}
              nepaliDateExist={nepaliDateExist}
              singleDateExist={singleDateExist}
            />
          </div>
          { !!totalDetails && 
          <div className="total-card">
                <div className="left">
                  <b>Total</b>
                </div>
                <div className="right values">
                {
              totalDetails.map((item,key) => {
                return (
                    <div className='total'>
                    <p className='title'>{item.title}</p>
                    <p className='value'>{item.value}</p>
                    </div>
                );
              })}
            </div>
            </div>
            }
          <div
            className={`fixed-table-wrapper table-scroll ${
              !disableUpperHeaderDetails ? 'upper-th-table-height' : ' th-table-height'
            }`}
          >
            <table className='svr-table'>
              <thead>
              {!disableUpperHeaderDetails && tableStructure.dualTableHeader && (
                  <tr className='table-border-bottom'>
                  {!disableUpperHeaderDetails &&
                    tableConfig?.header?.upperHeaderDetails?.map((data, key) => {
                      if (key === 0) {
                        return (
                          <th className={data.className} colSpan={colSpan_t_l}>
                          <p className={data?.className}>{data.title}</p>
                          <span>{data?.value}</span>
                          </th>
                        );
                      }
                      return (
                        <th className={data.className} colSpan={colSpan_t_r}>
                          <p className={data?.className}>{data.title}</p>
                          <span>{data?.value}</span>
                        </th>
                      );
                    })}
                    </tr>
              )}
              <TableHeader
                headerDetails={tableConfig.header.headerDetails}
                filterHeaderLabel={false}
                handleSorting={handleTableSorting}
                upperHeaderExistTH={tableConfig?.header?.upperHeaderDetails?.length >0}
              />
              </thead>
              {tableConfig.getTableBody({ dataSet,onTableBodyClick })}
              {enableTableFooter && tableStructure.footer && tableConfig.getTableFooter({ columnTotal })}
            </table>
          </div>
          {
            <div>
              <Pagination
                pageSize={queryParameters.pagination.limit}
                currentPage={queryParameters.pagination.page}
                orientation='top'
                totalItems={totalData}
                onPageSelect={handlePageSelect}
              />
            </div>
          }
        </div>
        <div className={printFlag ? 'display-block landscape' : 'no-print'}>
          <IrdReportPrintBody
            tableConfig={tableConfig}
            columnTotal={isObjectEmpty(printColumnTotal) ? columnTotal : printColumnTotal}
            dataList={printList}
            date={queryParameters.date}
            singleDate={queryParameters.singleDate}
            nepaliDate={nepaliDate}
            company={company}
            domainConfig={domainConfig}
            disableUpperHeaderDetails={disableUpperHeaderDetails}
            colSpanTotal={colSpanTotal}
            nepaliDateExist={nepaliDateExist}
            singleDateExist={singleDateExist}
            printFlag={printFlag}
            printFooterEnable ={printFooterEnable}
          />
        </div>
      </div>
    </IrdStyled>
  );
};

export default IrdView;

IrdView.propTypes = {
  columnTotal: PropTypes.instanceOf(),
  printColumnTotal: PropTypes.instanceOf(),
  company: PropTypes.shape({
    address: PropTypes.string,
    panNumber: PropTypes.number,
    title: PropTypes.string,
    phone: PropTypes.string,
  }),
  controlDisplay: PropTypes.func.isRequired,
  dataSet: PropTypes.instanceOf(),
  domainConfig: PropTypes.shape({
    domain: PropTypes.string,
    downloadList: PropTypes.instanceOf(Array),
    title: PropTypes.string,
  }),
  handleDateRangeChange: PropTypes.func.isRequired,
  handleDownloadClick: PropTypes.func,
  handlePageSelect: PropTypes.func.isRequired,
  handlePrintClick: PropTypes.func,
  handleTableSorting: PropTypes.func.isRequired,
  nepaliDate: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
  }),
  queryParameters: PropTypes.shape({
    search: PropTypes.string,
    sort: PropTypes.shape({
      label: PropTypes.string,
      order: PropTypes.string,
    }),
    pagination: PropTypes.shape({
      page: PropTypes.number,
      limit: PropTypes.number,
    }),
    filter: PropTypes.shape(),
    date: PropTypes.shape(),
  }),
  display: PropTypes.shape({
    searchBox: PropTypes.bool,
    filter: PropTypes.bool,
    menu: PropTypes.bool,
    print: PropTypes.bool,
  }),
  resetFilter: PropTypes.func,
  tableConfig: PropTypes.shape({
    getTableBody: PropTypes.func.isRequired,
    getTableFooter: PropTypes.func,
    header: PropTypes.instanceOf().isRequired,
  }).isRequired,
  tableStructure: PropTypes.shape({
    dualTableHeader: PropTypes.bool,
    footer: PropTypes.bool,
  }),
  headerConfig: PropTypes.shape({
    print: PropTypes.bool,
    download: PropTypes.bool,
    filter: PropTypes.bool,
  }),
  totalData: PropTypes.number,
  printList: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  colSpan_t_r: PropTypes.number,
  disableUpperHeaderDetails: PropTypes.number,
  enableTableFooter: PropTypes.bool,
  nepaliDateExist: PropTypes.bool,
  printFlag: PropTypes.bool,
};

IrdView.defaultProps = {
  columnTotal: {},
  printColumnTotal: {},
  company: {},
  dataSet: [],
  handleDownloadClick: () => null,
  handlePrintClick: () => null,
  resetFilter: () => null,
  tableStructure: {
    dualTableHeader: true,
    footer: true,
  },
  display: {
    filter: false,
    print: false,
    download: true,
  },
  nepaliDate: {
    start: '',
    end: '',
  },
  domainConfig: {
    domain: '',
    title: '',
  },
  queryParameters: {
    search: '',
    sort: {
      label: '',
      order: SORTING.ASC,
    },
    pagination: {
      page: PAGINATION.PAGE,
      limit: PAGINATION.LIMIT,
    },
    filter: { dse_user_id: [], },
    date: {
      start: filterUtil.getStartOfCurrentMonth(),
      end: filterUtil.getCurrentDay(),
    },
  },
  headerConfig: defaultHeaderConfig,
  totalData: 0,
  printList: [],
  loading: false,
  colSpan_t_r: 8,
  disableUpperHeaderDetails: true,
  enableTableFooter: true,
  nepaliDateExist: true,
  printFlag: false,
};


const IrdStyled = styled.div`
p{
  margin:0;
}
.total-card{
  display:flex;
  justify-content:space-between;
  align-items:center;
  background: #F4F4F4;
  border:1px solid #EFEFEF;
  padding:6px 28px;
  margin-bottom:24px;
  font-size:14px;
  font-weight:700;
  .left{
    b{
      font-weight:400;
    }
  }
  .right{
    display: flex;
    gap: 50px;
    .total{
      text-align: right;
    }
  }
  .title{
    font-size:9px;
    font-weight:400;
    color:rgba(0,0,0,0.53);
  }
}
`;
export  {IrdStyled};
