import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InvoiceDetails from '../detail';
import CreateInvoiceDialog from './DialogView';
import history from '../../../../utils/history';
import { LEDGERS, RATE_WITH_VAT_FACTOR, VAT_PERCENTAGE } from '../../../../data/enums/enums';
import * as filterUtil from '../../../../utils/filterUtil';
import TableHeader from '../../../../components/TableHeader';
import { clone, compareList } from '../../../../utils/arrayProcessor';
import { ABBREVIATED_INVOICE_BASE, ABBREVIATED_INVOICE_DETAILS } from '../../../../data/enums/Route';
import { fixedFloat, fixedFloatAndCommas, roundAndCommas } from '../../../../utils/conversion';
import {
  Button,
  Cell,
  Checkbox,
  Fab,
  FormField,
  Grid,
  Icon,
  LinearProgress,
  Snackbar,
} from '../../../../components/BillingMDC';
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import RadioButtonList from '../../../../components/RadioButtonList/RadioButtonList';
import { buApi, catalogsApi, checkIfApiCallSuccess, ledgerApi, salesInvoiceApi } from '../../../common/base.api';
import { getDiscountedValue, handleBatchSelect } from '../../../../utils/handlers';
import {
  createAbbreviatedInvoiceConfig,
  CreateAbbreviatedInvoiceTableBody,
  customerType,
  discountOptions,
  message,
  pageValidateFieldTypes,
} from './config';
import { voucherEncoder } from '../../../../utils/miscellaneous';
import DiscountBarStyled from '../../../common/create/DiscountBarStyled';
import { checkBUId, getIntegratedBUList } from '../../../common/common';
import { JWT } from '../../../../environment';
import { calculateRelativeDiscount ,getCustomerList} from '../../common/helpers';
import CustomerSelect from '../../../../common/CustomerSelect';

class CreateAbbreviateInvoice extends Component {
  // return net value based upon parameters passed
  // promotionList specifies all the promotion for given sku
  // promotionByCriteria specifies the promotion for given criteria.
  // handle when invoice item number changes or updates.
  handleInvoiceItemUpdate = () => {
    // set the invoice total
    const { invoiceList } = this.state;
    // update the sales summary
    let sales = 0;
    let promotionValue = 0;
    let billAmount = 0;

    invoiceList.map(element => (sales += Number(element.net_amount)));
    invoiceList.map(element => (billAmount += Number(element.amount)));
    invoiceList.map(element => (promotionValue += Number(element.promotion_discount)));

    const { billSummary } = this.state;
    billSummary.amount = sales;
    billSummary.invoiceGrossValue = Number(sales.toFixed(4));
    billSummary.promotionDiscountValue = promotionValue;
    billSummary.billAmount = billAmount;

    this.setState({ billSummary }, () => {
      this.setSalesInvoiceSummary();
    });
    // update the sales Invoice Summary
    this.setSalesInvoiceSummary();
  };

  // set the sales invoice summary
  setSalesInvoiceSummary = () => {
    // check the cash discount parameters
    const { billSummary, invoiceList } = this.state;
    const cashDiscountValue = getDiscountedValue(
      billSummary.invoiceGrossValue,
      billSummary.cashDiscountParam,
      billSummary.cashDiscount,
    );
    billSummary.cashDiscountValue = cashDiscountValue;

    const tradeDiscountAmountRef =
      billSummary.tradeDiscountParam === 'per'
        ? billSummary.invoiceGrossValue - billSummary.cashDiscountValue
        : billSummary.invoiceGrossValue;
    const tradeDiscountValue = getDiscountedValue(
      tradeDiscountAmountRef,
      billSummary.tradeDiscountParam,
      billSummary.tradeDiscount,
    );
    billSummary.tradeDiscountValue = tradeDiscountValue;
    const updatedInvoiceList = this.updateDiscountValueOfInvoiceList(
      cashDiscountValue,
      tradeDiscountValue,
      invoiceList,
    );

    // to handle discountOption value selection changes
    if (cashDiscountValue === 0 && tradeDiscountValue === 0 && billSummary.amount !== billSummary.invoiceGrossValue) {
      billSummary.amount = billSummary.invoiceGrossValue;
    }

    let finalInvoice = Number(billSummary.invoiceGrossValue - cashDiscountValue - tradeDiscountValue);
    billSummary.taxExemptedAmount = updatedInvoiceList.reduce((acc, item) => acc + item.tax_exempted_amount, 0);
    billSummary.excise = updatedInvoiceList.reduce((acc, item) => acc +  item.excise, 0); 
    billSummary.actualTaxableAmount = finalInvoice - (billSummary.taxExemptedAmount || 0) + Number(Number(billSummary.excise).toFixed(2));
   
    // to handle negative amount and vat
    if (finalInvoice < 0) {
      finalInvoice = billSummary.invoiceGrossValue;
      billSummary.cashDiscountValue = 0;
      billSummary.cashDiscount = 0;
      billSummary.tradeDiscountValue = 0;
      billSummary.tradeDiscount = 0;
      billSummary.amount = finalInvoice;
    }
    billSummary.invoiceNetValue = Number(Number(finalInvoice).toFixed(4));
    billSummary.amount = Number(finalInvoice).toFixed(4);
    billSummary.vatAmount = updatedInvoiceList.reduce((acc, item) => acc + item.vat, 0);

    // set the bill summary
    //rerun 
    this.setState({ billSummary, invoiceList: updatedInvoiceList });
  };

  checkQuantity = abc => {
    const { stockQuantity } = this.state;
    if (abc > stockQuantity) {
      this.setState({
        showMessage: true,
        message: `Should not be greater than available quantity i.e ${stockQuantity}`,
      });
    }
  };

  getDiscountInput = createSku => {
    return fixedFloat(createSku.quantity * createSku.rateWithVat || 0);
  };
  // handle change of create sku events
  onChange = (field, value, detail = {}) => {
    let { createSKU, editRate } = this.state;
    createSKU[field] = value;
    if (field === 'brand') {
      createSKU.batch_id = '';
      createSKU.rate = 0;
      createSKU.promotion_discount = 0;
      createSKU.freeSku = false;
      createSKU.quantity = 0;
      createSKU.rlp = 0;
      createSKU.rateWithVat = 0;
      createSKU.taxable_amount = 0;
      createSKU.line_amount = 0;
      createSKU.business_id = detail ? detail.businessId : '';
      createSKU.skuTitle = '';
      createSKU.sku_id = null;
      this.resetSKUDialog();
      this.setState(
        { batchList: [], keyValue: detail ? detail.idCatalogDetail : '' },
        () => detail && this.getSKUListUnderBrand(detail?.businessCatalogDetailId, detail.businessId),
      );
      if (value === null) {
        createSKU.brand = '';
        this.setState({ brandSKUList: [] });
      }
    }
    if (field === 'rate' && editRate) {
      createSKU.rate = value / createSKU.rate_with_vat_factor;
      createSKU.rlp = value / createSKU.rate_with_vat_factor;
      createSKU.rateWithVat = Number(value).toFixed(2);
    }

    if (field === 'sku_id') {
      createSKU.skuTitle = detail.title || '';
      createSKU.excisePercent = JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise?detail.excisePercent:0;
      createSKU.rate = detail.rlpWithExcise;
      
      createSKU.business_id = detail.businessId;
      createSKU.rlp = detail.rlpWithExcise;
      detail.rlpWithExcise =  JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise?(detail.rlp)*(1+(detail.excisePercent/100)):detail.rlp;
      createSKU.rate_with_vat_factor = 1 + detail.vatPercent / 100;
      createSKU.rateWithVat = Number(detail.rlpWithExcise * createSKU.rate_with_vat_factor).toFixed(2);
      createSKU.freeSku = false;
      createSKU.vat_percent = detail.vatPercent;
      createSKU.quantity = 0;
      createSKU = {
        ...(handleBatchSelect(createSKU, (detail.batchList.length > 0 && detail.batchList[0]) || {}) || createSKU),
      };
      this.setState(
        {
          batchList: detail.batchList || [],
        },
        () => this.getAvailableStock(createSKU.sku_id, createSKU.batch_id, detail.businessId),
      );
    }

    if (field === 'batch_id') {
      // update batch id accordingly;
      createSKU.freeSku = false;
      createSKU.quantity = 0;
      this.getAvailableStock(createSKU.sku_id, createSKU.batch_id, detail.businessId);
    }

    if (field === 'quantity') {
      this.checkQuantity(value);

      createSKU.promotion_discount = 0;
      createSKU.discount_input = 0;
      if (createSKU.freeSku) {
        createSKU.discount_input = this.getDiscountInput(createSKU);
      }
    }

    if (field === 'discount_label') {
      // createSKU.promotion_discount = 0;
      // createSKU.discount_input = 0;
    }

    if (field === 'discount_input') {
      // update discount number accordingly;
      if (createSKU.discount_label === 'rs') {
        createSKU.discount_input = Number(value.toFixed(2));
      }
    }
    if (field === 'freeSku') {
      createSKU.discount_input = this.getDiscountInput(createSKU);
      createSKU.discount_label = 'rs';
      if (!value) {
        createSKU.rate = createSKU.rateWithVat;
        createSKU.discount_input = 0;
      }
    }

    // update the amount with rate and amount.
    createSKU.amount = Number(Number(createSKU.quantity * createSKU.rateWithVat).toFixed(2));

    const discount =
      createSKU.discount_label === 'rs'
        ? Number(createSKU.discount_input)
        : Number(createSKU.discount_input * createSKU.amount * 0.01).toFixed(2);

    createSKU.promotion_discount = discount;

    createSKU.net_amount = createSKU.freeSku ? 0 : Number(Number(createSKU.amount - discount).toFixed(2)) || 0;

    createSKU.taxable_amount = createSKU.freeSku
      ? 0
      : Number((createSKU.net_amount / createSKU.rate_with_vat_factor).toFixed(2)) || 0;

    // createSKU.vat = createSKU.freeSku ? 0 : Number((createSKU.taxable_amount*0.13).toFixed(2)) || 0;
    createSKU.excise =  Number(Number(createSKU.excisePercent / 100 ) * createSKU.net_amount).toFixed(2) || 0;
    createSKU.vat = Number(Number((createSKU.vat_percent / 100) * createSKU.net_amount+ Number(createSKU.excise)).toFixed(2)) || 0;
    // create SKU
    this.setState({ createSKU });
  };

  // on change of the bill summary & update bill summary details.
  onChangeBillSummary = (field, value) => {
    const { billSummary } = this.state;
    billSummary[field] = value;
    if (field === 'cashDiscountParam') {
      billSummary.cashDiscount = 0;
    }
    if (field === 'tradeDiscountParam') {
      billSummary.tradeDiscount = 0;
    }
    this.setState({ billSummary }, () => {
      this.setSalesInvoiceSummary();
    });
  };

  // update the invoice grossValue
  createSalesInvoice = () => {
    const { invoiceList, createSKU } = this.state;
    // calculate the price
    createSKU.price = createSKU.quantity * createSKU.rateWithVat - createSKU.discount;
    invoiceList.push(createSKU);
    this.setState({ invoiceList }, () => {
      this.handleModalClose();
      // handle invoice item update
      this.handleInvoiceItemUpdate();
    });
  };

  // entire promotionList for the sku.
  // the promotion criteria list to be handled
  // editSalesInvoice
  editSalesInvoice = (data, index) => {
    const { invoiceList, createSKU } = this.state;
    invoiceList[index] = createSKU;
    this.setState({ invoiceList }, () => {
      this.handleModalClose();
      // handle invoice
      this.handleInvoiceItemUpdate();
    });
  };

  // creation of the new invoice
  // close the modal resetting  all the value
  handleModalClose = () => {
    this.resetSKUDialog();
    this.setState({
      modalOpen: false,
      editModalOpen: false,
      deleteModalOpen: false,
      batchList: [],
      brandSKUList: [],
    });
  };

  // reset SKU Dialog
  resetSKUDialog = () => {
    const createSKU = {
      brand: '',
      business_id: '',
      sku: '',
      sku_id: 0,
      skuTitle: '',
      rate: 0,
      quantity: 0,
      batch_id: '',
      batchTitle: '',
      amount: 0,
      promotion_discount_id: 0,
      promotion_discount: 0,
      discount_label: 'rs',
      discount_input: 0,
      promotion_title: '',
      bill_discount: 0,
      trade_discount: 0,
      net_amount: 0,
      vat: 0,
      freeSku: false,
      rlp: 0,
    };
    const skuList = [];

    this.setState({
      createSKU,
      formEmptyField: false,
      skuList,
    });
  };

  // delete the sales invoice
  deleteSalesInvoice = () => {
    const { invoiceList, indexInInvoiceList } = this.state;
    invoiceList.splice(indexInInvoiceList, 1);
    this.setState({ invoiceList }, () => {
      this.handleModalClose();
      this.handleInvoiceItemUpdate();
    });
  };

  validateForm = (formObject, config, flag) => {
    const data = Object.keys(formObject).some(k => {
      if (config.indexOf(k) > -1) {
        const value = formObject[k];
        if (!value) {
          this.setState({ [flag]: true });
          return true;
        }
        if (k === 'quantity' && value && flag === 'formEmptyField') {
          const { stockQuantity } = this.state;
          const valid = value > stockQuantity;
          if (valid) {
            this.setState({ [flag]: true });

            return true;
          }
          return false;
        }
        return false;
      }
      return false;
    });

    return !data;
  };

  // on edit icon click , pop up the dialog with pre filled parameters.
  handleEditIconClick = (data, index) => {
    const{brandList} =this.state;
    const businessCatalogDetailIdx =brandList.find(a=>a.indexArr===data.brand)?.businessCatalogDetailId;
    this.populateBrandSKUList(businessCatalogDetailIdx, data.sku_id, data.business_id);
    businessCatalogDetailIdx !==undefined ? this.getSKUListUnderBrand(businessCatalogDetailIdx, data.business_id) :this.getSkuList(data?.skuTitle);
    const createSKU = {
      brand: data.brand,
      sku: data.sku,
      skuTitle: data.skuTitle,
      business_id: data.business_id,
      sku_id: data.sku_id,
      rate: data.rate,
      rlp: data.rlp || data.rate,
      quantity: data.quantity,
      batch_id: data.batch_id,
      batchTitle: data.batchTitle,
      amount: data.amount,
      promotion_discount: data.promotion_discount,
      promotion_discount_id: data.promotion_discount_id,
      promotion_title: data.promotion_title,
      bill_discount: data.bill_discount,
      trade_discount: data.trade_discount,
      net_amount: data.net_amount,
      vat: data.vat,
      discount_label: data.discount_label,
      discount_input: data.discount_input,
      freeSku: data.freeSku || false,
      createSku: data.rlpWithExcise || data.rate,
      rateWithVat: data.rateWithVat,
      vat_percent: data.vat_percent,
      taxable_amount: data.taxable_amount || 0,
      line_amount: data.line_amount || 0,
      rate_with_vat_factor: data.rate_with_vat_factor || 0,
      excise: data.excise,
      excisePercent: data.excisePercent
    };

    this.setState({
      createSKU,
      editModalOpen: true,
      indexInInvoiceList: index,
    });
  };

  // handle delete icon click.
  handleDeleteIconClick = index => {
    this.setState({
      deleteModalOpen: true,
      indexInInvoiceList: index,
    });
  };

  // handle cancel button click
  handleCancelClick = () => {
    history.push(`/${ABBREVIATED_INVOICE_BASE}`);
  };

  handleSaveClick = () => {
    const valid = this.handleInvoiceValidation();
    if (valid) {
      this.setSalesInvoice();
    }
  };

  handleInvoiceValidation = () => {
    const { customerDetails, invoiceList, cashStatus, pageValidateFieldArray, billSummary, idAgent } = this.state;
    let validationFields = pageValidateFieldArray;
    const checkForValidCashParty = LEDGERS.CASH.validInvoiceAmount >= billSummary.invoiceNetValue;
    const isAbiAmountValid = billSummary.invoiceNetValue <= LEDGERS.CASH.validAbiAmount;

    /* todo: need to handle  displaying error message from common section */

    if (invoiceList.length < 1) {
      this.setState({
        showMessage: true,
        success: false,
        message: message.invoiceListEmptyError,
        directToPrintFlag: false,
      });

      return false;
    }

    if (idAgent === 0) {
      this.setState({
        showMessage: true,
        success: false,
        message: message.agent,
        directToPrintFlag: false,
        pageEmptyField: true,
      });

      return false;
    }

    if (!isAbiAmountValid) {
      this.setState({
        showMessage: true,
        success: false,
        message: message.abiAmountLimitError,
        directToPrintFlag: false,
      });

      return false;
    }

    if (!checkForValidCashParty) {
      if (cashStatus && customerDetails.customerId === LEDGERS.CASH.ledger_id) {
        this.setState({
          showMessage: true,
          success: false,
          message: message.cashBillError,
          directToPrintFlag: false,
        });

        return false;
      }

      return this.validateForm(customerDetails, validationFields, 'pageEmptyField');
    }
    validationFields = cashStatus ? pageValidateFieldTypes.cash : pageValidateFieldTypes.customer;
    this.updatePageValidateFields(validationFields);

    return this.validateForm({ remarks: billSummary.remarks, ...customerDetails }, validationFields, 'pageEmptyField');
  };

  // on okay click for the modal
  handleModalOk = () => {
    const { modalOpen, editModalOpen, createSKU, formValidateFieldArray, indexInInvoiceList } = this.state;
    if (modalOpen || editModalOpen) {
      const valid = this.validateForm(createSKU, formValidateFieldArray, 'formEmptyField');
      if (valid) {
        if (modalOpen) {
          this.createSalesInvoice();
        } else {
          this.editSalesInvoice(createSKU, indexInInvoiceList);
        }
      }
    } else {
      this.deleteSalesInvoice();
    }
  };

  handleCheckBoxClick = (fieldName, flag) => {
    this.setState({ [fieldName]: flag });
    const { billSummary } = this.state;
    // if the flag is set off reset value to 0 and disable
    if (fieldName === 'cashDiscountCheckBoxFlag') {
      billSummary.cashDiscountValue = 0;
      billSummary.cashDiscount = 0;
      billSummary.cashDiscountParam = discountOptions[0].value;
    } else if (fieldName === 'tradeDiscountCheckBoxFlag') {
      billSummary.tradeDiscountValue = 0;
      billSummary.tradeDiscount = 0;
      billSummary.tradeDiscountParam = discountOptions[0].value;
    }
    this.setState({ billSummary }, () => this.setSalesInvoiceSummary());
  };

  handleCashCheckClick = flag => {
    const { pageValidateFieldArray } = this.state;
    const validationType = flag ? pageValidateFieldTypes.cash : pageValidateFieldArray;
    this.setState(
      {
        cashStatus: flag,
      },
      () => this.updatePageValidateFields(validationType),
    );
  };

  handleBUClick = id => {
    this.setState({ businessUnitId: id }, () => {
      this.getBrandList();
      this.getAgentList();
    });
  };

  updatePageValidateFields = list => {
    const { pageValidateFieldArray } = this.state;
    if (!compareList(list, pageValidateFieldArray)) this.setState({ pageValidateFieldArray: list });
  };

  // get the brand list
  getBrandList = () => {
    const { businessUnitId, buList } = this.state;
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
    catalogsApi.getBrandList({ business_id: checkBUId(businessUnitId) }).then(response => {
      if (checkIfApiCallSuccess(response)) {
        const filteredBrandList = response.data.list.filter(list => !integratedBUIds.includes(list.businessId));
        const insertIndexArr=filteredBrandList.map((a,index)=>{return {...a, indexArr:index+1}});
        this.setState({ brandList: insertIndexArr });
      }
    });
  };

  // get the sku list under the brand
  getSKUListUnderBrand = (brandId, businessId) => {
    // const brandId = this.state.createSKU.brand;
    // bu_id=1&bu_brand_id=3

    catalogsApi
      .getSkuUnderBrand({
        business_id: businessId,
        brand_id: brandId,
      })
      .then(response => {
        if (checkIfApiCallSuccess(response)) {
          const list = response.data.list;
          this.setState({ skuList: list, brandSKUList: list });
        }
      });
  };

  // get sku list
  getSkuList = async inputValue => {
    const { businessUnitId, buList } = this.state;
    const integratedBUIds = getIntegratedBUList(buList).map(list => list.businessId);
    const nonIntegratedBUIds = buList.filter(list => !integratedBUIds.includes(list.businessId)).map(list=>list.businessId);

    const response = await catalogsApi.getSkuUnderOtherBUList({ business_id: nonIntegratedBUIds });
    const filteredBrandList = response.data.list.filter(list => !integratedBUIds.includes(list.businessId));
    const filteredSKUList = filteredBrandList.filter(list =>
      list.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
    this.setState({ skuList: filteredSKUList });
    return filteredSKUList;
  };

  getBrandSkuList = async inputValue => {
    const { brandSKUList } = this.state;
    const filteredSKUList = brandSKUList.filter(list => list.title.toLowerCase().includes(inputValue.toLowerCase()));
    this.setState({ skuList: filteredSKUList });
    return filteredSKUList;
  };

  getAvailableStock = (skuId, batchId, businessId) => {
    catalogsApi
      .getBatchAvailableStock({ business_id: businessId, sku_id: skuId, batch_id: batchId })
      .then(response => {
        if (response.data.length > 0) {
          this.setState({ stockQuantity: response.data[0].available_quantity });
        } else {
          this.setState({
            showMessage: true,
            message: 'Stock quantity not available i.e. 0',
          });
        }
      })
      .catch(err => {
        this.setState({
          showMessage: true,
          message: '!Error while fetching available stock quantity',
        });
      });
  };

  populateBrandSKUList = (brandId, skuId, businessId) => {
    const { businessUnitId } = this.state;

    catalogsApi
      .getSkuUnderBrand({
        business_id: businessId,
        brand_id: brandId,
      })
      .then(response => {
        if (checkIfApiCallSuccess(response)) {
          const skuList = response.data.list;
          const selectedSKU = skuList.find(sku => sku.businessSkuId === skuId);
          const batchList = selectedSKU.batchList ? selectedSKU.batchList : [];
          this.setState({ skuList: response.data.list, batchList });
        }
      });
  };

  // get BU list
  getBUList = () => {
    buApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ buList: response.data.list });
      }
    });
  };

  getAgentList = () => {
    salesInvoiceApi.getAgentList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        const agentList = response.data.list.filter(a=>a.status) || [];
        this.setState({ agentList });
      }
    });
  };

  // handle the customer drop down change
  onCustomerDropDownChange = (field, id, customer) => {
    const { billSummary } = this.state;
    const customerDetails = {
      id: customer?.idLedger || '',
      customerId: customer?.customerId || '',
      name: customer?.title || '',
      address: customer?.address || '',
      panNo: customer?.panNo || '',
      phoneNumber: customer?.phoneNumber || '',
    };
    billSummary.billName = customer?.title || '';

    this.setState({ customerDetails, billSummary });
  };

  onAgentDropDownChange = (field, id, agent) => {
    this.setState({ idAgent: agent.idAgent });
  };

  directToPrint = () => {
    const { showMessage, success, printDetails } = this.state;
    if (!showMessage && success) {
      const modifiedId = voucherEncoder(printDetails.invoiceNumber);
      this.setState({ startPrint: true });
      history.push(`/${ABBREVIATED_INVOICE_DETAILS}/${modifiedId}`, {
        details: printDetails,
        firstCopy: true,
      });
    }
  };

  getInvoiceList = () => clone(this.state.invoiceList);

  // set the sales invoice calling to the server. //also set custome ref id
  setSalesInvoice = () => {
    const { idAgent, billSummary, customerDetails, businessUnitId, printDetails, cashStatus } = this.state;
    const { user } = this.props;
    const invoiceList = this.getInvoiceList();
    const filteredInvoiceList = invoiceList.map(skuDetails => {
      delete skuDetails.sku;
      delete skuDetails.skuTitle;
      delete skuDetails.brand;
      // delete skuDetails['rate'];
      delete skuDetails.price;
      delete skuDetails.promotion_title;
      skuDetails.customer_ref_id = customerDetails.customerId || 0;
      //skuDetails.excise = Number (Number(skuDetails.amount) - Number(skuDetails.promotion_discount) -  Number(skuDetails.trade_discount) - Number(skuDetails.bill_discount)) * skuDetails.excisePercent /100
      return skuDetails;
    });
    const objectDetail = {
      business_id: checkBUId(businessUnitId),
      customer_ref_id: customerDetails?.customerId >0 ? customerDetails?.customerId : cashStatus ? LEDGERS.CASH.ledger_id: '',
      entered_by: user.idUsers,
      amount: billSummary.billAmount,
      taxable_amount: billSummary.taxableAmount || 0,
      tax_exempted_amount: billSummary.taxExemptedAmount || 0,
      bill_discount: billSummary.cashDiscountValue || 0,
      trade_discount: billSummary.tradeDiscountValue || 0,
      promotion_discount: billSummary.promotionDiscountValue || 0,
      customer_name: billSummary.billName,
      vat: billSummary.vatAmount,
      remarks: billSummary.remarks,
      net_amount: billSummary.invoiceNetValue,
      skuDetail: filteredInvoiceList,
      customer_type: cashStatus ? customerType.CASH : customerType.CUSTOMER,
      agentId: idAgent,
      isAbbreviatedInvoice: true,
      excise: Number((billSummary.excise||0)),
    };

    this.setState({ loading: true, saveDisable: true });
    salesInvoiceApi
      .create(objectDetail)
      .then(response => {
        this.setState({ loading: false });
        // todo direct to print page.
        if (response.success) {
          printDetails.invoiceNumber = response.data.detail.invoice_number;
          this.salesId = response.data.detail.invoice_number;
          this.firstCopy = true;

          this.setState({
            showMessage: true,
            success: true,
            message: message.success,
            printDetails,
            directToPrintFlag: true,
            saveDisable: true,
          });
        }
      })
      .catch(error => {
        {
          this.setState({
            loading: false,
            showMessage: true,
            success: false,
            message: message.error,
            directToPrintFlag: false,
            saveDisable: false,
          });
        }
      });
  };

  handlePostPrint = () => {
    this.setState({ startPrint: false }, () => history.push(`/${ABBREVIATED_INVOICE_BASE}`));
  };

  constructor(props) {
    super(props);

    this.state = {
      success: true,
      loading: false,
      modalOpen: false,
      startPrint: false,
      cashStatus: false,
      showMessage: false,
      saveDisable: false,
      editModalOpen: false,
      formEmptyField: false,
      pageEmptyField: false,
      deleteModalOpen: false,
      directToPrintFlag: false,
      stockQuantity: 0,
      cashDiscountCheckBoxFlag: false,
      tradeDiscountCheckBoxFlag: false,
      businessUnitId: null,
      message: message.success,
      indexInInvoiceList: 0,
      printDetails: {
        printedBy: '',
        invoiceNumber: '',
        transaction: 'SALES',
      },
      createSKU: {
        brand: '',
        sku: '',
        sku_id: 0,
        skuTitle: '',
        batchTitle: '',
        rate: 0,
        quantity: 0,
        batch_id: '',
        amount: 0,
        promotion_title: '',
        promotion_discount: 0,
        bill_discount: 0,
        trade_discount: 0,
        net_amount: 0,
        discount_label: discountOptions[0].value,
        discount_input: 0,
        vat: 0,
        promotion_discount_id: 0,
        freeSku: false,
        rlp: 0,
        taxable_amount: 0,
        line_amount: 0, //todo remove:: kept for testing
        vat_percent: VAT_PERCENTAGE,
        rate_with_vat_factor: RATE_WITH_VAT_FACTOR,
        tax_exempted_amount: 0,
      },
      customerDetails: {
        id: '',
        customerId: '',
        name: '',
        address: '',
        phoneNumber: '',
        panNo: '',
      },
      billSummary: {
        invoiceGrossValue: 0,
        cashDiscountParam: discountOptions[0].value,
        cashDiscount: 0,
        cashDiscountValue: 0,
        tradeDiscountParam: discountOptions[0].value,
        tradeDiscount: 0,
        tradeDiscountValue: 0,
        promotionDiscountValue: 0,
        vatAmount: 0,
        invoiceNetValue: 0,
        amount: 0,
        billAmount: 0,
        remarks: '',
        billName: '',
        actualTaxableAmount: 0,
        taxExemptedAmount: 0,
      },
      date: {
        start: filterUtil.getStartOfCurrentMonth(),
        end: filterUtil.getCurrentDay(),
      },
      formValidateFieldArray: ['sku_id', 'quantity', 'batch_id'],
      pageValidateFieldArray: pageValidateFieldTypes.customer,
      invoiceList: [],
      brandList: [],
      skuList: [],
      batchList: [],
      buList: [],
      promotionList: [],
      promotionByCriteria: [],
      agentList: [],
      brandSKUList: [],
      idAgent: 0,
      keyValue: '',
      editRate: false,
      editRatePermission: false,
      ledgerList:[],
      ledgerListPartial:[],
    };
    this.salesId = '';
    this.firstCopy = false;
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.getBUList();
    this.setRlpEditStatus();
    this.getLedgerList();
  }
  getLedgerList = async () => {
    const ledgerListAll =await getCustomerList('customerVendor');
    this.getOtherListList(ledgerListAll)
  }
  getOtherListList = (ledgerListAll) =>{
    const ledgerListPartial = ledgerListAll.slice(0,300) ;
    this.setState({ledgerList:ledgerListAll,ledgerListPartial}) 
  }

  updateDiscountValueOfInvoiceList(cashDiscountValue, tradeDiscountValue) {
    const invoiceList = this.getInvoiceList();
    const grossInvoiceNetAmount = invoiceList.reduce((acc, item) => acc + item.net_amount, 0);
    return invoiceList.map(invoice => {
      const bill_discount = calculateRelativeDiscount(grossInvoiceNetAmount, cashDiscountValue, invoice.net_amount);
      const trade_discount = calculateRelativeDiscount(grossInvoiceNetAmount, tradeDiscountValue, invoice.net_amount);
      const line_amount = invoice.net_amount - bill_discount - trade_discount;
      const taxable_amount = (invoice.net_amount - bill_discount - trade_discount) / invoice.rate_with_vat_factor;
      const exciseapplicableamount = Number(((invoice.rate * invoice.quantity) - invoice.promotion_discount)* invoice.excisePercent/100);
      const vat = Number(Number((invoice.vat_percent / 100) * taxable_amount).toFixed(2)) || 0;
      const tax_exempted_amount = vat === 0 ? taxable_amount - vat : 0;
      return {
        ...invoice,
        bill_discount: bill_discount || 0,
        trade_discount: trade_discount || 0,
        taxable_amount: taxable_amount || 0,
        line_amount: line_amount || 0,
        vat: vat || 0,
        tax_exempted_amount: tax_exempted_amount || 0,
        excise: exciseapplicableamount || 0,
      };
    });
  }

  calculateBillDiscount(invoice, totalBillDiscount, grossInvoiceNetAmount) {
    return Number((invoice.net_amount / grossInvoiceNetAmount) * totalBillDiscount) || 0;
  }

  render() {
    const {
      buList,
      skuList,
      loading,
      createSKU,
      brandList,
      batchList,
      agentList,
      modalOpen,
      cashStatus,
      idAgent,
      showMessage,
      invoiceList,
      saveDisable,
      editModalOpen,
      formEmptyField,
      deleteModalOpen,
      stockQuantity,
      businessUnitId,
      cashDiscountCheckBoxFlag,
      tradeDiscountCheckBoxFlag,
      keyValue,
      editRate,
      brandSKUList,
      customerDetails,
      ledgerListPartial,
      ledgerList,
    } = this.state;
    return (
      <div className='abi-create'>
        {loading && (
          <div className="linear-progress-wrapper temp-progress-wrapper">
            <LinearProgress accent indeterminate />
          </div>
        )}
        {!this.state.startPrint && (
          <div className={`sales-invoice-create abi-create pad-b-24${loading ? 'clickable-false' : ''}`}>
            <div className="message-snackbar">
              {
                <Snackbar
                  className={this.state.success ? 'success-message' : ''}
                  timeout={this.state.success ? 3000 : 4000}
                  onTimeout={() => {
                    this.setState({ showMessage: false }, () => {
                      if (this.state.directToPrintFlag) {
                        this.directToPrint();
                      }
                    });
                  }}
                  open={showMessage}
                >
                  {this.state.message}
                </Snackbar>
              }
            </div>
            <div className="card-header-bar clearfix" ref="tableReference">
              <div className="header-left">
                <h2>Abbreviated Invoice</h2>
              </div>
              <div className="header-right">{this.state.date.end}</div>
            </div>
            <div className="card-body">
              <div className="radio-list-wrapper clearfix">
                <div className="float-left">
                  {buList.length && (
                    <RadioButtonList data={buList} disableRosiaBu handleBuClick={this.handleBUClick} otherBU />
                  )}
                </div>
                <div className="float-right cash-checkbox">
                  <Checkbox
                    checked={cashStatus}
                    onChange={({ target: { checked } }) => {
                      this.handleCashCheckClick(checked);
                    }}
                  />
                  <label>Cash</label>
                </div>
              </div>
              <div className={businessUnitId !== null ? '' : 'block-overlay'}>
                <div className="three-input-wrapper">
                  <Grid>
                    <Cell col={3} className="input-select">
                      <label>Customer Name</label>
                      <CustomerSelect
                      value ={customerDetails?.name}
                      param='name'
                      valueKey='title'
                      clearable
                      onHandleChange={this.onCustomerDropDownChange}
                      ledgerList={ledgerList}
                      ledgerListPartial={ledgerListPartial}
                      type='customerVendor'
                      />
                    </Cell>
                    <Cell col={2}>
                      <BillingTextField
                        value={this.state.customerDetails.address}
                        floatingLabel="Address"
                        required={false}
                        className="billing-required no-pointer-events right-side-gap"
                        emptyField={this.state.pageEmptyField}
                      />
                    </Cell>
                    <Cell col={2}>
                      <BillingTextField
                        value={this.state.customerDetails.panNo}
                        floatingLabel="PAN Number"
                        type="number"
                        rule="isInt"
                        required
                        className="billing-required no-pointer-events right-side-gap"
                      />
                    </Cell>
                    <Cell col={3} className="input-field">
                      <BillingTextField
                        value={this.state.billSummary.billName}
                        param="billName"
                        floatingLabel="Bill Name"
                        handleChange={(param, value) => {
                          const { billSummary } = this.state;
                          billSummary[param] = value;
                          this.setState({ billSummary });
                        }}
                      />
                    </Cell>
                    <Cell col={2} className="input-field">
                      <BillingTextField
                        value={this.state.billSummary.remarks}
                        param="remarks"
                        floatingLabel="Remarks"
                        /* required */
                        className="billing-required"
                        handleChange={(param, value) => {
                          const { billSummary } = this.state;
                          billSummary[param] = value;
                          this.setState({ billSummary });
                        }}
                        /* emptyField={this.state.pageEmptyField} */
                      />
                    </Cell>
                    <Cell col={3} className="input-select">
                      <label>Salesperson</label>
                      <BillingSelect
                        name="form-field-name"
                        param="idAgent"
                        clearable={false}
                        value={idAgent}
                        labelKey="name"
                        valueKey="idAgent"
                        options={agentList}
                        handleChange={this.onAgentDropDownChange}
                        multipleParam
                        required
                        emptyField={this.state.pageEmptyField}
                      />
                    </Cell>
                  </Grid>
                </div>
                <div className="table-wrapper">
                  <div ref="fixedTableBody" className="fixed-table-wrapper">
                    <table>
                      <TableHeader
                        headerDetails={createAbbreviatedInvoiceConfig.headerDetails}
                        filterHeaderLabel={false}
                        handleSorting={this.handleTableSorting}
                      />
                      {invoiceList.map((data, key) => (
                        <tbody>
                          <CreateAbbreviatedInvoiceTableBody
                            data={data}
                            index={key}
                            deleteSalesInvoice={this.deleteSalesInvoice}
                            handleEditIconClick={this.handleEditIconClick}
                            handleDeleteIconClick={this.handleDeleteIconClick}
                          />
                        </tbody>
                      ))}
                    </table>
                  </div>

                  {/* Fab Icon */}
                  <div className="mini-fab-button">
                    <Fab mini onClick={() => this.setState({ modalOpen: true })}>
                      <Icon name="add" />
                    </Fab>
                  </div>
                </div>
                {/* Sales invoice table summary goes over here. */}
                <DiscountBarStyled>
                  <div className="total-section-wrapper">
                    <Grid>
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        {' '}
                        Sub Total
                      </Cell>
                      <Cell col={1} className="right-align">
                        {' '}
                        {this.state.billSummary.invoiceGrossValue}
                      </Cell>
                    </Grid>

                    <Grid>
                      <Cell col={3}> </Cell>
                      <Cell col={8} className="sub-total-wrap right-align">
                        <FormField id="cashDiscountCheckbox">
                          <Checkbox
                            checked={this.state.cashDiscountCheckBoxFlag}
                            onChange={({ target: { checked } }) => {
                              this.handleCheckBoxClick('cashDiscountCheckBoxFlag', checked);
                            }}
                          />
                          <label>Bill Discount</label>
                        </FormField>

                        <span
                          className={cashDiscountCheckBoxFlag ? 'sub-total-wrap' : 'sub-total-wrap no-pointer-events'}
                        >
                          <BillingSelect
                            className="display-inline-table"
                            name="form-field-name"
                            clearable={false}
                            value={discountOptions.filter(
                              option => option.value === this.state.billSummary.cashDiscountParam,
                            )}
                            options={discountOptions}
                            onChange={e => this.onChangeBillSummary('cashDiscountParam', e.value)}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ value }) => value}
                          />
                          <span className="default-left-margin-9">
                            <BillingTextField
                              value={this.state.billSummary.cashDiscount}
                              handleChange={this.onChangeBillSummary}
                              type="number"
                              param="cashDiscount"
                              max={
                                this.state.billSummary.cashDiscountParam === 'rs'
                                  ? this.state.billSummary.invoiceGrossValue
                                  : '99'
                              }
                            />
                          </span>
                        </span>
                      </Cell>
                      <Cell col={1} className="right-align default-top-padding-12">
                        {fixedFloatAndCommas(this.state.billSummary.cashDiscountValue)}
                      </Cell>
                    </Grid>

                    <Grid>
                      <Cell col={3}> </Cell>
                      <Cell col={8} className="right-align sub-total-wrap">
                        <FormField id="tradeDiscountCheckBox">
                          <Checkbox
                            checked={this.state.tradeDiscountCheckBoxFlag}
                            onChange={({ target: { checked } }) => {
                              this.handleCheckBoxClick('tradeDiscountCheckBoxFlag', checked);
                            }}
                          />
                          <label>Trade Discount</label>
                        </FormField>
                        <span
                          className={tradeDiscountCheckBoxFlag ? 'sub-total-wrap' : 'sub-total-wrap no-pointer-events'}
                        >
                          <BillingSelect
                            className="display-inline-table"
                            name="form-field-name"
                            clearable={false}
                            value={discountOptions.filter(
                              option => option.value === this.state.billSummary.tradeDiscountParam,
                            )}
                            options={discountOptions}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ value }) => value}
                            onChange={e => this.onChangeBillSummary('tradeDiscountParam', e.value)}
                          />
                          <span className="default-left-margin-9">
                            <BillingTextField
                              value={this.state.billSummary.tradeDiscount}
                              handleChange={this.onChangeBillSummary}
                              type="number"
                              param="tradeDiscount"
                              max={
                                this.state.billSummary.tradeDiscountParam === 'rs'
                                  ? this.state.billSummary.invoiceGrossValue
                                  : '99'
                              }
                            />
                          </span>
                        </span>
                      </Cell>
                      <Cell col={1} className="right-align default-top-padding-12">
                        {fixedFloatAndCommas(this.state.billSummary.tradeDiscountValue)}
                      </Cell>
                    </Grid>
                    {/* {JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise && <Grid className="padding-top-24">
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        {' '}
                        Excise Duty
                      </Cell>
                      <Cell col={1} className="right-align">
                        {' '}
                        {fixedFloatAndCommas(this.state.billSummary.excise) || 0}
                      </Cell>
                    </Grid>
  } */}
                    <Grid className="padding-top-24">
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        {' '}
                        Amount
                      </Cell>
                      <Cell col={1} className="right-align">
                        {' '}
                        {roundAndCommas(this.state.billSummary.invoiceNetValue) || 0}
                      </Cell>
                    </Grid>

                    <Grid>
                      <Cell col={8} />
                      <Cell col={3} className="right-align active-opacity-text">
                        Total
                      </Cell>
                      <Cell col={1} className="right-align font-bold">
                        {roundAndCommas(this.state.billSummary.invoiceNetValue)}
                      </Cell>
                    </Grid>
                  </div>
                </DiscountBarStyled>
              </div>

              {/* Dialog View */}
              <CreateInvoiceDialog
                handleModalClose={this.handleModalClose}
                modalOpen={modalOpen}
                editModalOpen={editModalOpen}
                deleteModalOpen={deleteModalOpen}
                createSKU={createSKU}
                handleInputChange={this.onChange}
                onModalSubmit={this.handleModalOk}
                skuList={skuList}
                brandList={brandList}
                batchList={batchList}
                formEmptyField={formEmptyField}
                stockQuantity={stockQuantity}
                keyValue={keyValue}
                editRate={editRate}
                editRatePermission={this.state.editRatePermission}
                brandSKUList={brandSKUList}
                loadOptions={createSKU.brand === '' ? this.getSkuList : this.getBrandSkuList}
              />
            </div>
            <div className="newinvoice-btn-wrapper">
              <Grid>
                <Cell col={8} />
                <Cell col={4} className="right-align">
                  <Button
                    accent
                    className="cancel-btn modal-btn"
                    onClick={() => {
                      this.handleCancelClick();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    accent
                    className={saveDisable ? 'save-btn modal-btn btn-disabled' : 'save-btn modal-btn'}
                    disabled={saveDisable}
                    onClick={() => {
                      this.handleSaveClick();
                    }}
                  >
                    Save
                  </Button>
                </Cell>
              </Grid>
            </div>
          </div>
        )}
        {this.state.startPrint && (
          <InvoiceDetails id={this.salesId} firstCopy={this.firstCopy} postPrintHandler={this.handlePostPrint} />
        )}
      </div>
    );
  }

  setRlpEditStatus() {
    const settings = localStorage.getItem(JWT.LOCAL_STORAGE.SETTINGS.NAME);
    if (settings) {
      let parsedSettings = JSON.parse(settings);
      if (parsedSettings) {
        this.setState({ editRate: !!parsedSettings.editRate });
      }
    }
    this.setState({ editRatePermission: !!this.props.permission.includes('EditRate') });
  }
}

CreateAbbreviateInvoice.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.billing.user || null,
  company: state.billing.company || null,
  bu_id: state.billing.bu_id || null,
  permission: state.billing.permission || [],
});

const CreateAbbreviatedInvoice = connect(mapStateToProps)(CreateAbbreviateInvoice);

export default CreateAbbreviatedInvoice;
