import React from 'react';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { LEDGERS } from '../../../data/enums/enums';

const VATSummaryBody = ({ dataList ,remainingLines,columnTotal ={},footerStatus =false,print=false }) => {
  return(
    <>
      {
        <tbody>
          {dataList.map((data, index) => (
            <tr>
              <td className='left-align sn display_print_only'>{data.index ? data.index + 1 : index + 1}</td>
              <td className='left-align' className='ledger-name'>
                {data.ledger_name}
              </td>
              <td className='pan-no'>{data.pan_no}</td>
              <td className='right-align taxable-sales-amount'>{fixedFloatAndCommas(data.taxable_sales_amount)}</td>
              <td className='right-align taxable-sales-vat'>{fixedFloatAndCommas(data.vat)}</td>
              <td className='right-align tax-exempted-amount'>{fixedFloatAndCommas(data.tax_exempted_amount)}</td>
            </tr>
          ))}
          { footerStatus &&
  <Total columnTotal ={columnTotal} print={print}/>
          }
          {remainingLines && remainingLines.length
            ? remainingLines.map((i) => (
              <tr className='blank-tr'>
                <td></td>
                <td colSpan ='14' className=''></td>
              </tr>
            ))
            : null}
        </tbody>
      }
    </>
  )
};
const Total = ({ columnTotal = {} ,print=false }) => (
  <tr className='total'>
    <td colSpan={print ? '3' : '2'} className='font-bold right-align'>
          Grand Total
    </td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.totalTaxableAmount)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.totalTaxableVat)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.totalTaxExemptedSales)}</td>
  </tr>
)

const VATSummaryTableFooter = ({ columnTotal = {}, print = false, footerStatus = true }) => (
  <tfoot className='column-total'>
    {footerStatus && (
      <Total columnTotal ={columnTotal} print={print}/>
    )}
  </tfoot>
);
const ledgerTypeViewOptions = [
  {
    id: LEDGERS.CUSTOMER.type,
    label: 'customer',
    title: 'Sales',
  },
  {
    id: LEDGERS.VENDOR.type,
    label: 'vendor',
    title: 'Purchase',
  },
];

const VATSummaryConfig = [
  {
    id: 1,
    label: 'sn',
    title: 'SN',
    className: 'cursor-pointer sn display_print_only',
    sortable: false,
  },
  {
    id: 2,
    label: 'ledger_name',
    title: 'Customer Name',
    className: 'cursor-pointer ledger-name',
    sortable: true,
  },
  {
    id: 3,
    label: 'pan_no',
    title: 'PAN Number',
    className: 'pan-no',
    sortable: false,
  },
  {
    id: 4,
    label: 'taxable-sales-amount',
    title: 'Taxable Value',
    className: 'right-align taxable-sales-amount',
    sortable: false,
  },
  {
    id: 5,
    label: 'taxable-sales-vat',
    title: 'VAT',
    className: 'right-align taxable-sales-vat',
    sortable: false,
  },
  {
    id: 6,
    label: 'tax_exempted_amount',
    title: 'Tax Free Sale ',
    className: 'right-align tax-exempted-sales',
    sortable: false,
  },
];
const lengthyAttributes = ['ledger_name'];
const printEnums = {
  withoutFooterLimit: 41,
  withFooterLimit: 39,
  ledger_name: 62 ,
  lengthyAttributes,
};

const config = {
  header: VATSummaryConfig,
  getTableBody: VATSummaryBody,
  printEnums,
  getTableFooter: VATSummaryTableFooter,
  uniqueIdGetter: (i) => i.pan_no,
  ledgerTypeViewOptions,
};

export default config;
