import { compose } from 'recompose';

import JvDetail from './JournalVoucherDetail';

import apiInterceptor from '../../../api/interceptor';

import { jvApi } from '../../common/base.api';

const composedJournalVoucherDetail = compose(apiInterceptor({ ...jvApi }))(JvDetail);

export default composedJournalVoucherDetail;
