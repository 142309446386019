import styled from 'styled-components';

const DayBookStyled = styled.div`
@media print {
  /* daybook-summary styling */ 
&.daybook-summary .print-body{
  height: 100%;
  page-break-after: always;
  padding:0 !important;
  margin:0.28in 0.3in !important;
   .fixed-table-wrapper{
     height:1420px !important;
  table{
    &.pdf-table tr{
      border-bottom: none;
    }
    tbody{
      .blank-tr{
        height:23px!important;
      }
      td{
        font-size: 15px !important;
      }
    }
    .total{
      border-top: 1px solid rgba(0,0,0,0.50);
      border-bottom: 1px solid rgba(0,0,0,0.50) !important;
      font-weight:600;
    }
    tfoot{
      td{
        font-weight: 500 !important;
    border-top: 1px solid rgba(0,0,0,0.50);
    padding-top: 10px !important;
      }
    }
  }
}
.footer-block{
            position:unset;
          }
}
}
@media print and (max-width:5.83in) {
  &.daybook-summary .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
          height:1405px !important;
        }
    }
  }
`;
export default DayBookStyled;
