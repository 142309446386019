import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const PurchaseReturnVatReportTableBody = ({ dataSet = [] ,remainingLines,columnTotal ={},footer =false, onTableBodyClick }) => {
  return(
    <>
      {
        dataSet.map((data) => (
          <tr className='cursor-pointer'>
            <td className='left-align bill-no link-voucher' onClick={() => onTableBodyClick(data)}>{data.bill_no}</td>
            <td className='left-align miti'>{data.miti_title}</td>
            <td className='left-align invoice-number'>{data.invoice_number}</td>
            <td className='left-align ledger-name'>{data.ledger_name}</td>
            <td className='left-align pan-no'>{data.pan_no}</td>
            <td className='left-align sku'>{data.business_alias || 'miscellaneous'}</td>
            <td className='right-align quantity'>{data.quantity || 0}</td>
            <td className='right-align tax'>
              {data.tax_exempted_amount ? fixedFloatAndCommas(data.tax_exempted_amount) : 0}
            </td>
            <td className='right-align tax-amount'>
              {data.taxable_purchase_amount ? fixedFloatAndCommas(data.taxable_purchase_amount) : 0}
            </td>
            <td className='right-align tax-vat'>
              {data.taxable_purchase_vat ? fixedFloatAndCommas(data.taxable_purchase_vat) : 0}
            </td>
            <td className='right-align import-amount'>{data.import_amount ? fixedFloatAndCommas(data.import_amount) : 0}</td>
            <td className='right-align import-vat'>{data.import_vat ? fixedFloatAndCommas(data.import_vat) : 0}</td>
            <td className='right-align capitalize'>
              {data.capitalized_purchase_amount ? fixedFloatAndCommas(data.capitalized_purchase_amount) : 0}
            </td>
            <td className='right-align capitalize-vat'>
              {data.capitalized_purchase_vat ? fixedFloatAndCommas(data.capitalized_purchase_vat) : 0}
            </td>
            { JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise &&  <td className='right-align'>
        {data.excise ? fixedFloatAndCommas(data.excise) : data.excise}
      </td>}
          </tr>
        ))}
      { footer &&
    <Total columnTotal ={columnTotal}/>
      }
      {remainingLines && remainingLines.length
        ? remainingLines.map((i) => (
          <tr className='blank-tr'>
            <td></td>
            <td colSpan ='14' className=''></td>
          </tr>
        ))
        : null}

    </>
  )
};

PurchaseReturnVatReportTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
};
const Total = ({ columnTotal = {} }) => (
  <tr className='total'>
    <td colSpan='3' />
    <td c className='left-align font-bold pad-l-10' colSpan={3}>
    Total
    </td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.totalQuantity)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.taxExemptedPurchase)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.totalTaxableAmount)}</td>
    <td className='font-bold right-align'>{fixedFloatAndCommas(columnTotal.vat)}</td>
    <td colSpan={4}></td>
    { JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise &&  <td className='right-align'>
        {columnTotal.excise ? fixedFloatAndCommas(columnTotal.excise) : columnTotal.excise}
      </td>}
  </tr>
);

const PRVRTableFooter = ({ columnTotal = {} }) => (
  <tfoot className='right-content-footer'>
    <Total columnTotal ={columnTotal}/>
  </tfoot>
);

const purchaseReturnVatReportConfig = {
  headerDetails: [
    {
      id: 1,
      label: 'bill_no',
      title: 'PR Bill Number',
      className: 'cursor-pointer left-align bill-no',
      sortable: true,
    },
    {
      id: 3,
      label: 'miti_title',
      title: 'Miti',
      className: 'cursor-pointer left-align miti',
      sortable: true,
    },
    {
      id: 4,
      label: 'invoice_number',
      title: 'Ref Number',
      className: 'cursor-pointer left-align invoice-number',
      sortable: true,
    },
    {
      id: 5,
      label: 'ledger_name',
      title: 'Vendor Name',
      className: 'cursor-pointer left_align ledger-name',
      sortable: true,
    },
    {
      id: 6,
      label: 'pan_no',
      title: 'PAN No.',
      className: 'cursor-pointer left-align pan-no',
      sortable: false,
    },
    {
      id: 15,
      label: 'business_alias',
      title: 'Item name',
      className: 'cursor-pointer left-align sku',
      sortable: true,
    },
    {
      id: 16,
      label: 'quantity',
      title: 'Quantity',
      className: 'cursor-pointer right-align quantity',
      sortable: true,
    },
    {
      id: 8,
      label: 'tax_exempted_purchase',
      title: 'Tax Exempted',
      className: 'cursor-pointer right-align tax',
      sortable: true,
    },
    {
      id: 9,
      label: 'taxable_purchase_amount',
      title: 'Purchase Return',
      className: 'cursor-pointer right-align tax-amount',
      sortable: true,
    },
    {
      id: 10,
      label: 'taxable_purchase_vat',
      title: 'VAT',
      className: 'cursor-pointer right-align tax-vat',
      sortable: true,
    },
    {
      id: 11,
      label: 'import_amount',
      title: 'Import Amount',
      className: 'cursor-pointer right-align import-amount',
      sortable: true,
    },
    {
      id: 12,
      label: 'import_vat',
      title: 'Import VAT',
      className: 'cursor-pointer right-align import-vat',
      sortable: true,
    },
    {
      id: 13,
      label: 'capitalized_purchase_amount',
      title: 'Capitalized Amount',
      className: 'cursor-pointer right-align capitalize',
      sortable: true,
    },
    {
      id: 14,
      label: 'capitalized_purchase_vat',
      title: 'Capitalized VAT',
      className: 'cursor-pointer right-align capitalize-vat',
      sortable: true,
    },
    { id: 15, label: 'excise', title: 'Excise', className: 'cursor-pointer right-align', sortable: true },
   
  ],
};
const downloadFormat = [{ id: 1, value: 'Download Purchase Return Vat Book CSV' }];
const lengthyAttributes = ['business_alias','ledger_name'];
const printEnums = {
  withoutFooterLimit: 38,
  withFooterLimit: 37,
  business_alias: 20,
  ledger_name: 52,
  lengthyAttributes,
};
const config = {
  header: purchaseReturnVatReportConfig,
  getTableBody: PurchaseReturnVatReportTableBody,
  getTableFooter: PRVRTableFooter,
  printEnums,
  uniqueIdGetter: (i) => i.id
};
const getTotalDetails = (data = {}) => {
  const totalDetails = [
    {
      id: 1,
      label: 'total',
      title: 'Total',
      className: 'total left-align bill-no',
      sortable: true,
    },
    {
      id: 3,
      label: 'miti_title',
      title: '',
      className: 'left-align miti',
      sortable: true,
    },
    {
      id: 4,
      label: 'invoice_number',
      title: '',
      className: 'left-align invoice-number',
      sortable: true,
    },
    {
      id: 5,
      label: 'ledger_name',
      title: '',
      className: 'left_align ledger-name',
      sortable: true,
    },
    {
      id: 6,
      label: 'pan_no',
      title: '',
      className: 'left-align pan-no',
      sortable: false,
    },
    {
      id: 15,
      label: 'business_alias',
      title: '',
      className: 'left-align sku',
      sortable: true,
    },
    {
      id: 16,
      label: 'quantity',
      title: 'Quantity',
      className: 'right-align quantity',
      sortable: true,
      value:data?.totalQuantity || 0,
    },
    {
      id: 8,
      label: 'tax_exempted_purchase',
      title: 'Tax Exempted',
      className: 'right-align tax',
      sortable: true,
      value:fixedFloatAndCommas(data?.taxExemptedPurchase )|| 0,
    },
    {
      id: 9,
      label: 'taxable_purchase_amount',
      title: 'Purchase Return',
      className: 'right-align tax-amount',
      sortable: true,
      value:fixedFloatAndCommas(data?.totalTaxableAmount) || 0,
    },
    {
      id: 10,
      label: 'taxable_purchase_vat',
      title: 'VAT',
      className: 'right-align tax-vat',
      sortable: true,
      value:fixedFloatAndCommas(data?.vat) || 0,
    },
    {
      id: 11,
      label: 'import_amount',
      title: '',
      className: 'right-align import-amount',
      sortable: true,
    },
    {
      id: 12,
      label: 'import_vat',
      title: '',
      className: 'right-align import-vat',
      sortable: true,
    },
    {
      id: 13,
      label: 'capitalized_purchase_amount',
      title: '',
      className: 'right-align capitalize',
      sortable: true,
    },
    {
      id: 14,
      label: 'capitalized_purchase_vat',
      title: '',
      className: 'right-align capitalize-vat',
      sortable: true,
    },
    { id: 15, label: 'excise', title: '', className: 'right-align', sortable: true },
  ];
  if(!(JSON.parse(localStorage.getItem('rosiaCompany'))?.enableExcise)){
    totalDetails.pop()
  }
  return totalDetails;
};

export {
  purchaseReturnVatReportConfig, PurchaseReturnVatReportTableBody, config, downloadFormat,getTotalDetails
};
