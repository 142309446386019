import React, { useRef } from 'react';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { getPercentage } from '../../../../utils/miscellaneous';
import { VAT_PERCENTAGE } from '../../../../data/enums/enums';
import numberToWords from '../../../../utils/numberToTextConverter';
import { salesInvoiceDetailConfig } from '../../../../v1/views/SalesInvoice/SalesInvoiceDetailConfig';

const TITLE = {
  ORIGINAL: 'ABBREVIATED INVOICE',
  ORIGINAL2: 'ABBREVIATED INVOICE',
  COPY: 'ABBREVIATED INVOICE (Copy Of Original)',
  COPYOF: '(Copy Of Original)',
};
const TEMPORARY_TITLE = {
  ORIGINAL: 'ESTIMATE',
  ORIGINAL2: 'ESTIMATE',
  COPY: 'ESTIMATE',
};
const PRINT_COPY = 2;

const MESSAGE = { NEXT_PAGE: 'Continued on next page ...', };

const getTaxableAmount = (netValue = 0, promotionDiscount = 0, tradeDiscount = 0, cashDiscount = 0) =>
  Number(Number(netValue - (promotionDiscount + tradeDiscount + cashDiscount)).toFixed(2));

const getTotals = (data) => {
  const billPercentage = getPercentage(data.billDiscount || 0, data.subTotal);
  const tradePercentage = getPercentage(data.tradeDiscount || 0, data.subTotal - data.billDiscount);

  const taxableAmount = getTaxableAmount(
    data.amount || 0,
    data.promotionDiscount || 0,
    data.tradeDiscount || 0,
    data.billDiscount || 0,
  );
  const subTotal = data.subTotal ? Number(Number(data.subTotal).toFixed(2)) : 0;
  return {
    subTotal,
    billPercentage,
    taxableAmount,
    tradePercentage,
  };
};
const getBillTotalList = (data = {}) => {
  /* const billPer = getPercentage(data.billDiscount || 0, data.subTotal);
  const tradePer = getPercentage(data.tradeDiscount || 0,
    (data.subTotal - data.billDiscount));

  const taxableAmount = getTaxableAmount(
    data.amount || 0,
    data.promotionDiscount || 0,
    data.tradeDiscount || 0,
    data.billDiscount || 0
  ); */
  const { billPercentage: billPer, taxableAmount, tradePercentage: tradePer, subTotal } = getTotals(data);
  const billTotalList = [
    {
      title: 'Sub Total',
      value: data.subTotal ? Number(Number(data.subTotal).toFixed(2)) : 0,
    },
    {
      title: `Bill Discount${billPer ? ` (${billPer}%)` : ''}`,
      value: data.billDiscount || 0,
    },
    {
      title: `Trade Discount${tradePer ? ` (${tradePer}%)` : ''}`,
      value: data.tradeDiscount || 0,
    }
    //,
    // JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise ?{ title: 'Excise Duty', value: data.excise }:{},
  ];

  return billTotalList;
};

const getBillSummaryDetails = (data = {}) => {
  const billSummaryDetails = [
    { title: 'Invoice Id', value: data?.invoiceNumber || '' },
    {
      title: 'Customer Name',
      value: data?.customerName || '',
    },
    {
      title: 'Address',
      value: data?.customerDetail ? data?.customerDetail?.address : '',
    },
    {
      title: 'Phone Number',
      value: data?.customerDetail ? data?.customerDetail?.phoneNumber : '',
    },
    {
      title: 'PAN No',
      value: data?.customerDetail ? data?.customerDetail?.panNo : '',
    },
    {
      title: 'Salesperson',
      value: data?.agentDetail ? data?.agentDetail?.name : '',
    },
  ];

  return billSummaryDetails;
};

const getBillFooterSummary = (data = {}) => {
  const inWords = data.netAmount ? numberToWords(data.netAmount || 0) : '';
  const billFooterSummary = {
    remarks: data.remarks || '',
    grossValue: data.netAmount ? fixedFloatAndCommas(data.netAmount) : 0,
    totalInWords: inWords,
    paymentMode: data.paymentMode || ' ',
  };

  return billFooterSummary;
};

const customerDetailMapper = (detail) => ({
  invoiceId: detail.refNumber || '',
  customerName: detail.customer ? detail.customer.title : '',
  customerAddress: detail.customer ? detail.customer.address : '',
  panNo: detail.customer ? detail.customer.address : '',
  salesReturnNumber: detail.salesReturnInvoiceNumber || '',
});
const billSummaryMapper = (data) => ({
  totalValue: data.netAmount || 0,
  remarks: data.remarks || '',
  totalInWords: data.totalInWords || '',
});

const getTotalContent = (data) => ({
  title: 'Total',
  value: data.netAmount || 0,
});

const headerConfig = [
  {
    id: 1,
    label: 'sn',
    title: 'SN',
    className: null,
    sortable: false,
  },
  {
    id: 2,
    label: 'sku',
    title: 'SKU',
    className: null,
    sortable: false,
  },
  {
    id: 4,
    label: 'quantity',
    title: 'Quantity',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 5,
    label: 'rate',
    title: 'Rate',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 6,
    label: 'amount',
    title: 'Amount',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'discount',
    title: 'Discount',
    className: ' right-align',
    sortable: false,
  },
  {
    id: 8,
    label: 'net_amount',
    title: 'Net Amount',
    className: 'right-align',
    sortable: false,
  },
];

const AbiDetailBody = ({ dataList = [], printInfoBill }) => {
  return (
    <tbody>
      {dataList.map((data, index) => (
        <AbiDetailRow data={data} index={index} printInfoBill={printInfoBill} />
      ))}
    </tbody>
  );
};

const AbiDetailRow = ({ data = [], index, printInfoBill }) => {
  const printCssClass = {
    quantity: printInfoBill ? 'quantity' : '',
    rate: printInfoBill ? 'rate' : '',
    discount: printInfoBill ? 'discount' : '',
    netAmount: printInfoBill ? 'netAmount' : '',
    sku: printInfoBill ? 'sku' : '',
  };
  return (
    <tr className='cursor-pointer'>
      <td className='sn'>{data.index ? data.index + 1 : index + 1}</td>
      <td className={'hide-overflow-text' + printCssClass.sku}>{data.sku}</td>
      {printInfoBill.batchEnabled && <td className='batch'>{data.batch}</td>}
      <td className={'right-align ' + printCssClass.quantity}>{data.quantity}</td>
      <td className={'right-align ' + printCssClass.rate}>
        {fixedFloatAndCommas(data.rate * data.exciseFactor * (1 + data.vatPercent / 100))}
      </td>
      <td className={'right-align ' + printCssClass.amount}>{fixedFloatAndCommas(data.amount)}</td>
      <td className={'right-align ' + printCssClass.discount}>{fixedFloatAndCommas(data.promotionDiscount)}</td>
      <td className={'right-align ' + printCssClass.netAmount}>{fixedFloatAndCommas(data.netAmount)}</td>
    </tr>
  );
};

const lengthyAttributes = ['sku'];
const printEnums = {
  withoutFooterLimit:42,
  withFooterLimit: 30,
  sku: 54,
  batch: 1,
  lengthyAttributes,
};
const printEnumsBatch = {
  withoutFooterLimit:52,
  withFooterLimit: 36,
  sku: 36,
  batch: 16,
  lengthyAttributes: [...lengthyAttributes, 'batch'],
};


const config = {
  title: 'Abbreviated Invoice',
  header: headerConfig,
  getTableBody: AbiDetailBody,
  getSummaryViewList: getBillSummaryDetails,
  customerDetailMapper,
  // getFooterContent,
  getTotalContent,
  billSummaryMapper,
  printEnums,
  printEnumsBatch,
  uniqueIdGetter: (i) => i.idSalesDetail
};
export {
  TITLE,
  MESSAGE,
  PRINT_COPY,
  TEMPORARY_TITLE,
  getBillTotalList,
  getBillFooterSummary,
  getBillSummaryDetails,
  config,
  getTotals,
  AbiDetailBody,
  AbiDetailRow,
};
