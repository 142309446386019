import { getCurrentDay, getEndOfCurrentMonth } from '../../../../utils/filterUtil';
import {
  ACTION, VAT_PERCENTAGE, VAT_ZERO_PERCENTAGE,
} from '../../../../data/enums/enums';

const skuDetailsMapper = (data = {}) => ({
  title: data.title || '',
  description: data.description || '',
  rlp: data.rlp || 0,
  status: data.status || 0,
  psku: Number(data.psku) || 0,
  su: data.su || '',
  upc: data.upc || '',
  skucode: data.skucode || '',
  catalogSkusList: data.catalogSkusList || [],
  batchList: data.batchList || [],
  businessId: data.businessId || '',
  businessSkuId: data.businessSkuId || null,
  excisePercent: data.excisePercent || 0,
  vatPercent: data.vatPercent !== undefined ? data.vatPercent : VAT_PERCENTAGE,
});

const formValidationFields = [{ title: 'title' }, { title: 'description' }, { title: 'rlp' }];

const getTransformedCatalogDetails = (catalogDetails) => catalogDetails.map((catalog) => ({
  businessCatalogId: catalog.businessCatalogId,
  catalogDetailTitle: catalog.catalogDetailTitle || 'Default',
  catalogDetailId: catalog.businessCatalogDetailId,
}));
const VATList = [{ value: VAT_ZERO_PERCENTAGE, label: '0 %' }, { value: VAT_PERCENTAGE, label: '13 %' }];

const getTransformedBatchList = (batchList, businessId) => batchList.map((batch) => ({
  businessBatchId: batch.businessBatchId || null,
  businessId: businessId || null,
  businessSkuId: batch.businessSkuId || null,
  // updatedAt: batch.updatedAt || getCurrentDay(),
  title: batch.title || '',
  dlp: batch.dlp || 0,
  vat: batch.vat || 13,
  mfgDate: batch.mfgDate || getCurrentDay(),
  expiryDate: batch.expiryDate || getEndOfCurrentMonth(),
}));

const getApiTransformedData = (data, eventType) => {
  let formattedData = { ...data };
  if (eventType === ACTION.ADD) {
    const { businessSkuId, ...modifiedData } = data;
    formattedData = { ...modifiedData };
  }

  return {
    ...formattedData,
    catalogSkusList: getTransformedCatalogDetails(data.catalogSkusList),
    batchList: getTransformedBatchList(data.batchList, data.businessId),
  };
};

const message = {
  ADD: 'SKU created successfully!',
  UPDATE: 'SKU updated successfully!',
  SERVER_ERROR: '!Server Error',
  CREATE_ERROR: '!Error while creating SKU',
  UPDATE_ERROR: '!Error while updating SKU',
};

export {
  skuDetailsMapper, formValidationFields, getApiTransformedData, message, VATList,
};
