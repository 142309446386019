import styled from 'styled-components';

const SKUDetailStyled = styled.div`
  .card-body {
    padding: 24px;
    .form-wrap {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      h6 {
        color: rgba(0, 0, 0, 0.54);
        margin-bottom: 4px;
        font-weight: 500;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 24px;
      }
      .form-switchs,
      .form-inputs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 530px;
        width: 49%;
        margin-bottom: 24px;
        .label {
          flex: 1;
          font-size: 16px;
          margin-right: 16px;
        }
        .input {
          width: 328px;
          width: 62%;
          .mdc-textfield {
            margin: 0;
          }
          .mdc-textfield {
            height: unset;
          }
          label {
            display: none;
          }
          input {
            background: rgba(0, 0, 0, 0.087);
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            padding: 0;
            border: 0;
            padding: 0 16px;
          }
        }
        &.select{
          .input{
          .select-css{
            border:0;
          }
          .zindex-2__value-container{
            padding: 0 16px;
            font-size: 16px;
          }
          .zindex-2__control{
            background: rgba(0,0,0,0.087);
            height: 40px;
            line-height:40px;
            border-radius: 4px;
            border: 0;
          }
          .zindex-2__indicators{
            height:40px;
          }
          .zindex-2__menu {
            z-index:2;
          }
          }
        }
      }
    }
    .form-multi-select-wrap {
      padding-bottom: 8px;
      .form-multi-select {
        display: flex;
        width: 570px;
        /* width: 54%; */
        flex-wrap: wrap;
        margin-bottom: 16px;
        .material-icons {
          margin-left: 0;
          line-height: 24px;
          padding: 8px 4px;
          padding-left: 0;
        }
        .mdc-fab--mini {
          margin: 10px;
          height: 20px;
          width: 20px;
          line-height: 18px;
          margin-left: 0px;
          .material-icons {
            font-size: 14px;
            margin: -10px;
            margin-left: -6px;
          }
        }
        & > div {
          .select-css {
            margin-right: 16px;
            border-bottom: none;
            .zindex-2__control {
              border: 1px solid hsl(0, 0%, 80%);
              border-radius: 4px;
              min-height: 38px;
            }
          }
          &:nth-child(1) {
            width: 186px;
            width: 32%;
          }
          &:nth-child(2) {
            width: 328px;
            width: 57%;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .batch-li {
      h6 {
        margin: 0;
        border: 0;
        margin-bottom: 8px;
      }
      table {
        thead {
          tr {
            height: 26px;
            th {
              font-size: 12px;
              line-height: 15px;
              color: rgba(0, 0, 0, 0.54);
              font-weight: 500;
            }
          }
        }
        tbody {
          tr {
            height: 48px;
            td {
              font-size: 16px;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.8);
              font-weight: 400;
            }
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
  &.sku_details,
  &.sku_create{
    .disabled_class{
      .zindex-2__indicators{
        display:none;
      }
    }
    .zindex-2__control--is-disabled{
      background-color:#FFFFFF !important;
    }
    .zindex-2__single-value--is-disabled{
      color:rgb(51, 51, 51);
    }
  }
  .mini-fab-button .mdc-fab--mini {
    height: 32px;
    width: 32px;
    line-height: 32px;
    position: unset;
    margin-left: 20px;
    margin-top: 12px;
    .material-icons {
      font-size: 16px;
      margin-top: 8px;
    }
  }
  .form-multi-select-wrap {
    .mini-fab-button .mdc-fab--mini {
      margin: 0;
      font-size: 24px;
    }
  }

  .btns {
    text-align: right;
    margin-top: 24px;
  }

  .date-picker-holder-active {
    padding-left: 0;
  }
  .mdc-dialog.mdc-dialog--open {
    .mdc-dialog__body {
      .date-range-picker-wrapper {
        top: 29px;
      }
      .calendar-status {
        top: -29px;
      }
    }
  }
  .sku-batch .mdc-dialog__surface {
    .dialog-upperpart {
      .date-range-picker-wrapper {
        .mdc-layout-grid {
          padding: 0;
        }
      }
    }
  }

  @media only screen and (max-device-width: 767px) {
    .card-body .form-multi-select-wrap .form-multi-select {
      width: 100%;
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .card-body .form-multi-select-wrap .form-multi-select {
      width: 100%;
    }
  }
  @media only screen and (min-width: 1824px) {
    .card-body .form-multi-select-wrap .form-multi-select {
      width: 42%;
    }
  }
  &.sku_create {
    .batch-li {
      display: none !important;
    }
  }
  &.sku_create,
  &.sku_details {
    .su,
    .sku_code,
    .psku {
      display: none !important;
    }
  }
  .confirmation {
    & > p {
      margin: 0;
    }
  }
`;
export default SKUDetailStyled;
