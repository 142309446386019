import PropTypes from 'prop-types';
import React from 'react';
import TableHeader from '../../../components/TableHeader';
import Pagination from '../../../components/Pagination';
import { PAGINATION } from '../../../data/enums/enums';
import CreateBtn from '../../common/pagination/CreateBtn/CreateBtn';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  display: PropTypes.shape({
    searchBox: PropTypes.bool,
    filter: PropTypes.filter,
    menu: PropTypes.menu,
  }),
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
  }),
  handleTableSorting: PropTypes.func,
  onTableBodyClick: PropTypes.func,
  data: PropTypes.shape({
    list: PropTypes.instanceOf(Array),
    total: PropTypes.number,
  }),
  onPageSelect: PropTypes.func,
  config: PropTypes.shape({
    header: PropTypes.instanceOf(Array),
    getTableBody: PropTypes.func,
    getTableFooter: PropTypes.func,
  }).isRequired,
  footerConfig: PropTypes.shape({ pagination: PropTypes.bool, }),
  dyanmicHeaderExist: PropTypes.bool,
  enableTableFooter: PropTypes.bool,
  agentList:PropTypes.array,
};

const defaultProps = {
  serverResponseWaiting: false,
  display: {
    searchBox: false,
    filter: false,
    menu: false,
  },
  dyanmicHeaderExist: false,
  pagination: {
    page: PAGINATION.PAGE,
    limit: PAGINATION.LIMIT,
  },
  handleTableSorting: () => null,
  onTableBodyClick: () => null,
  data: {
    list: [],
    total: 0,
  },
  onPageSelect: () => null,
  footerConfig: { pagination: true, },
  enableTableFooter: false,
  agentList:[],
};

React.createRef();

const refs = {
  tableHeader: React.createRef(),
  tableFooter: React.createRef(),
};

const getHeader = (config, handleTableSorting, dynamicHeaderExist, dynamicHeaderData, header) => {
  const { multipleTableHeader = false } = config;
  if (!dynamicHeaderExist) {
    return multipleTableHeader ? (
      <>
        <thead className='upperHeader'>
          {
            config.header.upperHeaders.map((h) => h.map((data) => (
              <th className={data.className} colSpan={data.columns}>
                {data.title}
              </th>
            )))
          }
        </thead>
        <TableHeader
          headerDetails={config.header.tableHeader || header}
          handleSorting={handleTableSorting}
        />
      </>
    )
      : (
        <TableHeader
          headerDetails={config.header || header}
          handleSorting={handleTableSorting}
        />
      );
  }
  return (
    <>
      <thead>
        {dynamicHeaderData && dynamicHeaderData.map((data) => <th>{data}</th>)}
      </thead>
    </>
  );
};

const TableView = ({
  serverResponseWaiting,
  handleTableSorting, // this.handleTableSorting,
  onTableBodyClick, //
  data, // list and total
  onPageSelect, // this.onPageSelect,
  pagination,
  config,
  footerConfig,
  createHandler,
  editHandler,
  deleteHandler,
  btnDisplay,
  onTableClickData,
  viewType,
  header,
  handleInputChange,
  userGroupList,
  dynamicHeaderExist,
  dynamicHeaderData,
  enableTableFooter,
  columnTotal,
  queryParameters,
  onTableSpanClick,
  dialogType,
  activeIndex,
  tagList,
  agentList,
}) => (
  <div>
    <div className={serverResponseWaiting ? 'clickable-false' : ''}>
      <div
        className={`fixed-table-wrapper hr-scrollable-table ${
          btnDisplay ? 'end-list-table' : 'no-end-list-table'
        }`}
      >
        <table ref={refs.tableFooter}>
          { getHeader(config, handleTableSorting, dynamicHeaderExist, dynamicHeaderData, header)}
          {config.getTableBody({
            dataList: data.list,
            onRowClick: onTableBodyClick,
            onSpanClick:onTableSpanClick,
            onTableClickData,
            editHandler,
            deleteHandler,
            viewType,
            handleInputChange,
            userGroupList: userGroupList || [],
            queryParameters,
            columnTotal,
            dialogType,
            activeIndex,
            tagList,
            agentList,
          })}
          {enableTableFooter && config.getTableFooter({ columnTotal })}
        </table>
      </div>
      <CreateBtn createHandler={createHandler} btnDisplay={btnDisplay} />
      {footerConfig.pagination && (
        <div ref={refs.tableFooter}>
          <Pagination
            pageSize={pagination.limit}
            currentPage={pagination.page}
            orientation='top'
            totalItems={data.total}
            onPageSelect={onPageSelect}
          />
        </div>
      )}
    </div>
  </div>
);

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

export default TableView;
