import React from 'react'
import styled from 'styled-components';
import {
  Dialog,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  Button,
} from '../../../components/BillingMDC';

const WarningDialog = ({ warningModal, handleWarningClose, onModalSubmit, id }) => {
  return (
    <WarningStyled>
    <Dialog
      open={warningModal}
      onClose={() => {
        handleWarningClose();
      }}
    >
      <DialogHeader>
        <DialogTitle>Warning</DialogTitle>
      </DialogHeader>
      {
        <div className='default-margin-24'>
          {`The entered PAN number already exists in the system. Do you still want to ${id=== undefined ?'create' : 'update'} Ledger?`}
        </div>
      }
      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            handleWarningClose();
          }}
        >
          Cancel
        </Button>
        <Button
          accent
          className={'dialog-ok modal-btn'}
          onClick={() => {
            onModalSubmit();
          }}
        >
          Ok
        </Button>
      </DialogFooter>
    </Dialog>
    </WarningStyled>
  )
};

export default WarningDialog;


export const WarningStyled = styled.div`
footer{
  border-top:1px solid #eeeeee;
}
`;