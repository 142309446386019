import PropTypes from 'prop-types';
import React from 'react';
import TableView from './TableView';
import PageHeader, { defaultHeaderConfig as defaultPageHeaderConfig } from './PageHeader';
import Loading from '../../../common/Loading';
import DrawerWithFilter from '../DrawerFilter';
import * as filterUtil from '../../../utils/filterUtil';
import { PAGINATION, SORTING } from '../../../data/enums/enums';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  display: PropTypes.shape({
    searchBox: PropTypes.bool,
    filter: PropTypes.filter,
    menu: PropTypes.menu,
  }),
  queryParameters: PropTypes.shape({
    search: PropTypes.string,
    sort: PropTypes.shape({
      label: PropTypes.string,
      order: PropTypes.string,
    }),
    pagination: PropTypes.shape({
      page: PropTypes.number,
      limit: PropTypes.number,
    }),
    filter: PropTypes.shape(),
    date: PropTypes.shape(),
  }),
  tableConfig: PropTypes.shape({
    header: PropTypes.instanceOf(Array),
    getTableBody: PropTypes.func,
  }),
  controlDisplay: PropTypes.func,
  handleDownloadClick: PropTypes.func,
  domainConfig: PropTypes.shape({
    title: PropTypes.string,
    downloadList: PropTypes.instanceOf(Array),
    domain: PropTypes.string,
  }).isRequired,
  footerConfig: PropTypes.shape({
    pagination: PropTypes.bool,
  }),
  handleFilterChange: PropTypes.func,
  userGroupList:PropTypes.array,
  dyanmicHeaderExist:PropTypes.bool,
  showInputOption:PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
  display: {
    searchBox: false,
    filter: false,
    menu: false,
  },
  tableConfig: {
    header: [],
    getTableBody: () => null,
  },
  queryParameters: {
    search: '',
    sort: {
      label: '',
      order: SORTING.ASC,
    },
    pagination: {
      page: PAGINATION.PAGE,
      limit: PAGINATION.LIMIT,
    },
    filter: {
      dse_user_id: [],
    },
    date: {
      start: filterUtil.getStartOfCurrentMonth(),
      end: filterUtil.getCurrentDay(),
    },
  },
  footerConfig: {
    pagination: true,
  },
  userGroupList: [],
  controlDisplay: () => null,
  handleDownloadClick: () => null,
  handleFilterChange: () => null,
  dyanmicHeaderExist: false,
  showInputOption: false,
};

// before Search Component
// afterSearch Component
// After Date Component
// After Download Component
// After Filter Component.

const PageView = ({
  serverResponseWaiting,
  display, // searchBox, filter, menu
  queryParameters, //
  handleSearchChange, // this.handleSearchChange,
  clearSearchText, // this.clearSearchText
  handleDownloadClick, // this.handleDownloadClick
  controlDisplay, // this.controlDisplay
  handleTableSorting, // this.handleTableSorting,
  headerConfig, //
  onTableBodyClick, //
  data, // list and total
  resetFilter, // this.resetFilter,
  onPageSelect, // this.onPageSelect,
  handleDateRangeChange, // this.handleDateRangeChange
  tableConfig,
  domainConfig,
  handleUploadClick,
  handlePrintClick,
  footerConfig,
  handleFilterChange,
  handleDateFilterChange,
  createHandler,
  editHandler,
  deleteHandler,
  onTableClickData,
  showCollapsibleList,
  userGroupList,
  dynamicHeaderExist,
  dynamicHeaderData,
  enableTableFooter,
  columnTotal,
  showInputOption,
  onInputChange,
  inputData,
  inputTitle,
  viewOptions,
  onTableSpanClick,
  filterDisable,
  dialogType,
  activeIndex,
}) => (
  <>
    <Loading display={serverResponseWaiting} />
    <div className={`${serverResponseWaiting ? 'clickable-false' : ''}`}>
      <PageHeader
        handleSearchChange={handleSearchChange}
        handleDownloadClick={handleDownloadClick}
        controlDisplay={controlDisplay}
        clearSearchText={clearSearchText}
        queryParameters={queryParameters}
        domainConfig={domainConfig}
        display={display}
        config={headerConfig || defaultPageHeaderConfig}
        handleUploadClick={handleUploadClick}
        resetFilter={resetFilter}
        handleDateRangeChange={handleDateRangeChange}
        handlePrintClick={handlePrintClick}
        handleFilterChange={handleFilterChange}
        handleDateChange={handleDateFilterChange}
        showCollapsibleList={showCollapsibleList}
        showInputOption={showInputOption}
        onInputChange={onInputChange}
        inputData={inputData}
        inputTitle={inputTitle}
        viewOptions={viewOptions}
        filterDisable={filterDisable}
      />

      <TableView
        display={display}
        onTableBodyClick={onTableBodyClick}
        onPageSelect={onPageSelect}
        handleTableSorting={handleTableSorting}
        data={data}
        config={tableConfig}
        serverResponseWaiting={serverResponseWaiting}
        pagination={queryParameters.pagination}
        footerConfig={footerConfig}
        createHandler={createHandler}
        editHandler={editHandler}
        deleteHandler={deleteHandler}
        btnDisplay={headerConfig.create}
        onTableClickData={onTableClickData}
        onTableSpanClick={onTableSpanClick}
        queryParameters={queryParameters}
        userGroupList={userGroupList}
        dynamicHeaderData={dynamicHeaderData}
        dynamicHeaderExist={dynamicHeaderExist}
        enableTableFooter={enableTableFooter}
        columnTotal={columnTotal}
        handleInputChange={onInputChange}
        dialogType={dialogType}
        activeIndex={activeIndex}
      />
    </div>
  </>
);

PageView.propTypes = propTypes;

PageView.defaultProps = defaultProps;

export default PageView;
