import React from 'react';
import {
  Redirect, Route, Switch
} from 'react-router-dom';
import ErrorPage from './components/Error';
import {
  AGEING,
  STOCK_AGEING,
  BUSINESS_GROUPS,
  COMPANY,
  CUSTOMER_LEDGER_BASE,
  CUSTOMER_LEDGER_DETAILS,
  AGGREGATE_CUSTOMER_LEDGER_BASE,
  AGGREGATE_CUSTOMER_LEDGER_DETAILS,
  DATABASE_LOG,
  ENTRY_LOG,
  ERR_404,
  ERR_BASE,
  GRN_CREATE,
  GRN_DETAILS,
  INVENTORY_BASE,
  JOURNAL_VOUCHER_BASE,
  JOURNAL_VOUCHER_CREATE,
  JOURNAL_VOUCHER_DETAILS,
  LEDGERS,
  OPENING_BALANCE,
  OUTSTANDING,
  SALES_PERSON_OUTSTANDING,
  PRN_CREATE,
  PRN_DETAILS,
  PURCHASE_RETURN_BASE,
  PURCHASE_RETURN_VAT,
  PURCHASE_VAT,
  RECEIPT,
  RECONCILE,
  PAYMENT,
  ROSIA_SALES_PRINT,
  ROSIA_SRN_PRINT,
  SALES_CREATE,
  SALES_INVOICE_BASE,
  SALES_MATERIALIZED_VIEW,
  SALES_DETAILS,
  SALES_PRINT,
  SALES_RETURN_BASE,
  SALES_RETURN_VAT,
  SALES_VAT,
  SKU,
  SKU_CREATE,
  SKU_DETAILS,
  SRN_CREATE,
  SRN_DETAILS,
  STOCK_JOURNAL_BASE,
  STOCK_JOURNAL_CREATE,
  STOCK_JOURNAL_DETAILS,
  STOCK_LEDGER_BASE,
  STOCK_LEDGER_DETAILS,
  STOCK_VALUATION_REPORT,
  USERS,
  VAT,
  DAY_BOOK_SUMMARY,
  AGENT,
  AREA,
  ACCOUNT_GROUP,
  FISCAL_YEAR_MIGRATION,
  TRIAL_BALANCE,
  PROFIT_LOSS,
  BALANCE_SHEET,
  ABBREVIATED_INVOICE_BASE,
  ABBREVIATED_INVOICE_DETAILS,
  ABBREVIATED_INVOICE_CREATE,
  CONTRA_ENTRY_BASE,
  CONTRA_ENTRY_DETAILS,
  CONTRA_ENTRY_CREATE,
  TAG,
  ROSIA_ABBREVIATED_INVOICE_PRINT,
  ROSIA_PURCHASE_RETURN_PRINT,
  DEBIT_NOTE,
  DEBIT_NOTE_CREATE,
  DEBIT_NOTE_DETAILS,
  CREDIT_NOTE,
  CREDIT_NOTE_CREATE,
  CREDIT_NOTE_DETAILS,
  SETTINGS,
  PICK_LIST,
  PDC,
  PROMOTION,
  PROMOTION_CREATE,
  PROMOTION_DETAILS,
  USER_GROUP,
  NEW_SALES_VAT,
  NEW_PURCHASE_VAT,
  NEW_PURCHASE_RETURN_VAT,
  NEW_SALES_RETURN_VAT,
  SALES_REPORT,
  SRN_REPORT,
  PRN_REPORT,
  GRN_REPORT,
  VAT_SUMMARY_REPORT,
  DAMAGE_EXPIRY_VALUATION,
  LEDGER_WISE,
  TAG_WISE,
  TAG_WISE_DETAILS,
  NETSALES
} from './data/enums/Route';

import {
  // DatabaseLog,
  PrintSalesInvoice,
  // StockJournalDetail,
  // VatReport,
} from './v1/views/index';

import {
  AgeingReport,
  StockAgeingReport,
  BusinessGroups,
  Company,
  CreateGRN,
  CreateJournalVoucher,
  CreatePurchaseReturn,
  CreateSalesInvoice,
  CreateSRN,
  CreateStockJournal,
  Customer as CustomerLedger,
  EntryLog,
  GRN,
  GRNDetails,
  JournalVoucherDetail,
  JournalVoucherReport,
  LedgerReport,
  AggregateLedgerReport,
  LedgerReportDetail,
  OpeningBalance,
  OutstandingReport,
  NetSalesReport,
  SalesPersonOutstandingReport,
  PurchaseReturn,
  PurchaseReturnDetails,
  PurchaseVatReport,
  Receipt,
  Reconcile,
  Payment,
  SalesInvoice,
  SalesInvoiceDetail,
  SalesMaterializedView,
  SalesReturn,
  SalesVatReport,
  NewSalesVatReport,
  SKU as SkuView,
  SkuDetail,
  SRNDetails,
  SRNVatReport,
  StockJournal,
  StockLedger,
  StockLedgerDetail,
  StockValuationReport,
  User,
  PurchaseReturnVatReport,
  VatReport,
  AggregateDetails,
  SalesWithoutPrintCount,
  DayBookSummary,
  Agent,
  Area,
  AccountGroup,
  TrialBalance,
  ProfitLoss,
  BalanceSheet,
  FiscalYearMigration,
  AbbreviatedInvoice,
  CreateAbbreviatedInvoice,
  AbbreviateInvoiceDetail,
  CreateContraEntry,
  ContraEntryDetail,
  ContraEntry,
  Tag,
  CompanySettings,
  DebitNote,
  CreateDebitNote,
  DebitNoteDetail,
  CreditNote,
  CreateCreditNote,
  CreditNoteDetail,
  PickList,
  PostDatedCheque,
  Promotion,
  PromotionDetail,
  UserGroup,
  UserGroupDetails,
  NewPurchaseVatReport,
  NewPurchaseReturnVatReport,
  NewSRNVatReport,
  SalesReport,
  SRNReport,
  PRNReport,
  GRNReport,
  VATSummary,
  DamageExpiryValuation,
  LedgerWise,
  TagWise,
  DataBaseLog,
  StockJournalDetails,
  TagWiseDetail,
  LedgerWiseDetail,
} from './views';

const routes = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}${COMPANY}`} component={Company} />
    <Route exact path={`${match.url}${SALES_INVOICE_BASE}`} component={SalesInvoice} />
    <Route
      exact
      path={`${match.url}${SALES_INVOICE_BASE}-no-print-db24cfe4-8b83-42dd-929e-5c70c2647315`}
      component={SalesWithoutPrintCount}
    />
    <Route exact path={`${match.url}${SALES_CREATE}`} component={CreateSalesInvoice} />
    <Route exact path={`${match.url}${SALES_PRINT}`} component={PrintSalesInvoice} />
    <Route exact path={`${match.url}${SALES_DETAILS}/:id`} component={SalesInvoiceDetail} />
    <Route
      exact
      path={`${match.url}${ROSIA_SALES_PRINT}/:invoiceHash/:id/:userId/:firstCopy`}
      component={SalesInvoiceDetail}
    />
    <Route
      exact
      path={`${match.url}${ROSIA_ABBREVIATED_INVOICE_PRINT}/:invoiceHash/:id/:userId/:firstCopy`}
      component={AbbreviateInvoiceDetail}
    />
    <Route
      exact
      path={`${match.url}${ ROSIA_PURCHASE_RETURN_PRINT}/:invoiceHash/:id/:userId`}
      component={PurchaseReturnDetails}
    />

    <Route exact path={`${match.url}${ABBREVIATED_INVOICE_BASE}`} component={AbbreviatedInvoice} />

    <Route exact path={`${match.url}${ABBREVIATED_INVOICE_CREATE}`} component={CreateAbbreviatedInvoice} />

    <Route exact path={`${match.url}${ABBREVIATED_INVOICE_DETAILS}/:id`} component={AbbreviateInvoiceDetail} />

    <Route exact path={`${match.url}${INVENTORY_BASE}`} component={GRN} />
    <Route exact path={`${match.url}${GRN_CREATE}`} component={CreateGRN} />
    <Route exact path={`${match.url}${GRN_DETAILS}/:id`} component={GRNDetails} />
    <Route exact path={`${match.url}${SALES_RETURN_BASE}`} component={SalesReturn} />
    <Route exact path={`${match.url}${SRN_CREATE}`} component={CreateSRN} />
    <Route exact path={`${match.url}${SRN_DETAILS}/:id`} component={SRNDetails} />
    <Route exact path={`${match.url}${ROSIA_SRN_PRINT}/:invoiceHash/:id/:userId`} component={SRNDetails} />

    <Route exact path={`${match.url}${SALES_MATERIALIZED_VIEW}`} component={SalesMaterializedView} />
    <Route exact path={`${match.url}${ENTRY_LOG}`} component={EntryLog} />

    <Route exact path={`${match.url}${LEDGERS}`} component={CustomerLedger} />
    {/*  <Route exact path={`${match.url}${CUSTOMER_LEDGER_DETAILS}/:id`} component={CustomerLedgerDetail}/> */}
    <Route exact path={`${match.url}${PURCHASE_RETURN_BASE}`} component={PurchaseReturn} />
    <Route exact path={`${match.url}${PRN_CREATE}`} component={CreatePurchaseReturn} />
    <Route exact path={`${match.url}${PRN_DETAILS}/:id`} component={PurchaseReturnDetails} />

    <Route exact path={`${match.url}${SETTINGS}`} component={CompanySettings} />

    <Route exact path={`${match.url}${SKU}`} component={SkuView} />
    <Route exact path={`${match.url}${SKU_CREATE}`} component={SkuDetail} />
    <Route exact path={`${match.url}${SKU_DETAILS}/:id`} component={SkuDetail} />
    <Route exact path={`${match.url}${PROMOTION_CREATE}`} component={PromotionDetail} />

    <Route
      exact
      path={`${match.url}${PROMOTION_DETAILS}/:id`}
      component={PromotionDetail}
    />
    <Route exact path={`${match.url}${USERS}`} component={User} />
    <Route exact path={`${match.url}${AGENT}`} component={Agent} />
    <Route exact path={`${match.url}${AREA}`} component={Area} />
    <Route
      exact
      path={`${match.url}${STOCK_LEDGER_BASE}`}
      component={StockLedger}
    />
    <Route
      exact
      path={`${match.url}${STOCK_LEDGER_DETAILS}/:id`}
      component={StockLedgerDetail}
    />
    <Route exact path={`${match.url}${LEDGER_WISE}`} component={LedgerWise} />
    <Route exact path={`${match.url}${TAG_WISE}`} component={TagWise} />
    <Route exact path={`${match.url}${TAG_WISE_DETAILS}/:id`} component={TagWiseDetail} />

    <Route exact path={`${match.url}${VAT}`} component={VatReport} />
    <Route exact path={`${match.url}${SALES_VAT}`} component={SalesVatReport} />
    <Route exact path={`${match.url}${NEW_SALES_VAT}`} component={NewSalesVatReport} />
    <Route
      exact
      path={`${match.url}${SALES_RETURN_VAT}`}
      component={SRNVatReport}
    />
    <Route
      exact
      path={`${match.url}${NEW_SALES_RETURN_VAT}`}
      component={NewSRNVatReport}
    />
    <Route
      exact
      path={`${match.url}${PURCHASE_VAT}`}
      component={PurchaseVatReport}
    />
    <Route
      exact
      path={`${match.url}${PURCHASE_RETURN_VAT}`}
      component={PurchaseReturnVatReport}
    />
    <Route
      exact
      path={`${match.url}${NEW_PURCHASE_VAT}`}
      component={NewPurchaseVatReport}
    />
    <Route
      exact
      path={`${match.url}${NEW_PURCHASE_RETURN_VAT}`}
      component={NewPurchaseReturnVatReport}
    />

    <Route
      exact
      path={`${match.url}${STOCK_JOURNAL_BASE}`}
      component={StockJournal}
    />
    <Route
      exact
      path={`${match.url}${NEW_PURCHASE_VAT}`}
      component={NewPurchaseVatReport}
    />
    <Route
      exact
      path={`${match.url}${NEW_PURCHASE_RETURN_VAT}`}
      component={NewPurchaseReturnVatReport}
    />

    <Route exact path={`${match.url}${DATABASE_LOG}`} component={DataBaseLog} />
    <Route exact path={`${match.url}${BUSINESS_GROUPS}`} component={BusinessGroups} />
    <Route exact path={`${match.url}${STOCK_JOURNAL_BASE}`} component={StockJournal} />
    <Route exact path={`${match.url}${STOCK_JOURNAL_DETAILS}/:id`} component={StockJournalDetails} />
    <Route exact path={`${match.url}${STOCK_JOURNAL_CREATE}`} component={CreateStockJournal} />

    <Route
      exact
      path={`${match.url}${JOURNAL_VOUCHER_BASE}`}
      component={JournalVoucherReport}
    />
    <Route
      exact
      path={`${match.url}${JOURNAL_VOUCHER_CREATE}`}
      component={CreateJournalVoucher}
    />
    <Route
      exact
      path={`${match.url}${JOURNAL_VOUCHER_DETAILS}/:id`}
      component={JournalVoucherDetail}
    />
    <Route exact path={`${match.url}${AGEING}`} component={AgeingReport} />
    <Route exact path={`${match.url}${STOCK_AGEING}`} component={StockAgeingReport} />

    <Route
      exact
      path={`${match.url}${OUTSTANDING}`}
      component={OutstandingReport}
    />
     <Route
      exact
      path={`${match.url}${NETSALES}`}
      component={NetSalesReport}
    />
    <Route
      exact
      path={`${match.url}${SALES_PERSON_OUTSTANDING}`}
      component={SalesPersonOutstandingReport}
    />
    <Route
      exact
      path={`${match.url}${OPENING_BALANCE}`}
      component={OpeningBalance}
    />

    <Route
      exact
      path={`${match.url}${CUSTOMER_LEDGER_BASE}`}
      component={LedgerReport}
    />
    <Route
      exact
      path={`${match.url}${CUSTOMER_LEDGER_DETAILS}/:id`}
      component={LedgerReportDetail}
    />
    <Route
      exact
      path={`${match.url}${AGGREGATE_CUSTOMER_LEDGER_BASE}`}
      component={AggregateLedgerReport}
    />
    <Route
      exact
      path={`${match.url}${AGGREGATE_CUSTOMER_LEDGER_DETAILS}/:id`}
      component={AggregateDetails}
    />
    <Route
      exact
      path={`${match.url}${DAY_BOOK_SUMMARY}`}
      component={DayBookSummary}
    />

    <Route exact path={`${match.url}${RECEIPT}`} component={Receipt} />
    <Route exact path={`${match.url}${RECONCILE}/:id`} component={Reconcile} />
    <Route exact path={`${match.url}${PAYMENT}`} component={Payment} />
    <Route exact path={`${match.url}${PDC}`} component={PostDatedCheque} />
    <Route exact path={`${match.url}${STOCK_VALUATION_REPORT}`} component={StockValuationReport} />
    <Route exact path={`${match.url}${ACCOUNT_GROUP}`} component={AccountGroup} />
    <Route exact path={`${match.url}${FISCAL_YEAR_MIGRATION}`} component={FiscalYearMigration} />
    <Route exact path={`${match.url}${TRIAL_BALANCE}`} component={TrialBalance} />
    <Route exact path={`${match.url}${PROFIT_LOSS}`} component={ProfitLoss} />
    <Route exact path={`${match.url}${BALANCE_SHEET}`} component={BalanceSheet} />
    <Route exact path={`${match.url}${CONTRA_ENTRY_BASE}`} component={ContraEntry} />
    <Route exact path={`${match.url}${CONTRA_ENTRY_CREATE}`} component={CreateContraEntry} />
    <Route exact path={`${match.url}${CONTRA_ENTRY_DETAILS}/:id`} component={ContraEntryDetail} />

    <Route exact path={`${match.url}${TAG}`} component={Tag} />

    <Route exact path={`${match.url}${DEBIT_NOTE}`} component={DebitNote} />

    <Route exact path={`${match.url}${DEBIT_NOTE_CREATE}`} component={CreateDebitNote} />

    <Route
      exact
      path={`${match.url}${DEBIT_NOTE_DETAILS}/:id`}
      component={DebitNoteDetail}
    />
    <Route
      exact
      path={`${match.url}${CREDIT_NOTE}`}
      component={CreditNote}
    />

    <Route
      exact
      path={`${match.url}${CREDIT_NOTE_CREATE}`}
      component={CreateCreditNote}
    />

    <Route
      exact
      path={`${match.url}${CREDIT_NOTE_DETAILS}/:id`}
      component={CreditNoteDetail}
    />

    <Route
      exact
      path={`${match.url}${USER_GROUP}`}
      component={UserGroup}
    />
    <Route
      exact
      path={`${match.url}${USER_GROUP}/:id`}
      component={UserGroupDetails}
    />

    <Route
      exact
      path={`${match.url}${PROMOTION}`}
      component={Promotion}
    />

    <Route exact path={`${match.url}${PICK_LIST}`} component={PickList} />
    <Route exact path={`${match.url}${SALES_REPORT}`} component={SalesReport} />
    <Route exact path={`${match.url}${PRN_REPORT}`} component={PRNReport} />
    <Route exact path={`${match.url}${SRN_REPORT}`} component={SRNReport} />
    <Route exact path={`${match.url}${GRN_REPORT}`} component={GRNReport} />
    <Route exact path={`${match.url}${VAT_SUMMARY_REPORT}`} component={VATSummary} />
    <Route
      exact
      path={`${match.url}${PICK_LIST}`}
      component={PickList}
    />
    <Route
      exact
      path={`${match.url}${SALES_REPORT}`}
      component={SalesReport}
    />
    <Route
      exact
      path={`${match.url}${PRN_REPORT}`}
      component={PRNReport}
    />
    <Route
      exact
      path={`${match.url}${SRN_REPORT}`}
      component={SRNReport}
    />
    <Route
      exact
      path={`${match.url}${GRN_REPORT}`}
      component={GRNReport}
    />
    <Route
      exact
      path={`${match.url}${VAT_SUMMARY_REPORT}`}
      component={VATSummary}
    />

    <Route
      exact
      path={`${match.url}${DAMAGE_EXPIRY_VALUATION}`}
      component={DamageExpiryValuation}
    />

    <Route exact path={`${match.url}${ERR_BASE}/:id`} component={ErrorPage} />
    <Route
      render={() => <Redirect to={`${match.url}${ERR_BASE}/${ERR_404}`} />}
    />

    {/* <Route exact path={`${match.url}${SALES_RETURN_BASE}`} component={SalesReturn}/>
    <Route exact path={`${match.url}${SRN_DETAILS}/:id`} component={SRNDetails}/>
    <Route exact path={`${match.url}${SRN_CREATE}`} component={CreateSRN}/>
    <Route exact path={`${match.url}${ROSIA_SRN_PRINT}/:id/:userId`} component={SalesReturnPrintDetail}/>

    <Route exact path={`${match.url}${INVENTORY_BASE}`} component={GoodReceivedNote}/>
    <Route exact path={`${match.url}${GRN_CREATE}`} component={CreateGRN}/>
    <Route exact path={`${match.url}${GRN_DETAILS}/:id`} component={GRNDetails}/>

    <Route exact path={`${match.url}${PURCHASE_RETURN_BASE}`} component={purchaseReturn}/>
    <Route exact path={`${match.url}${PRN_DETAILS}/:id`} component={PurchaseReturnDetails}/>
    <Route exact path={`${match.url}${PRN_CREATE}`} component={CreatePurchaseReturn}/>

    <Route exact path={`${match.url}${STOCK_JOURNAL_BASE}`} component={StockJournal}/>
    <Route exact path={`${match.url}${STOCK_JOURNAL_DETAILS}/:id`} component={StockJournalDetail}/>
    <Route exact path={`${match.url}${STOCK_JOURNAL_CREATE}`} component={CreateStockJournal}/>

    <Route exact path={`${match.url}${SKU}`} component={Sku}/>
    <Route exact path={`${match.url}${SKU_PRICE}`} component={SKUPrice}/>
    <Route exact path={`${match.url}${SKU_PRICE_HISTORY}`} component={SKUPriceHistory}/>
    <Route exact path={`${match.url}${PROMOTION}`} component={Promotion}/>
    <Route exact path={`${match.url}${LEDGERS}`} component={Ledgers}/>
    <Route exact path={`${match.url}${OPENING_BALANCE}`} component={OpeningBalance}/>

    <Route exact path={`${match.url}${AGEING}`} component={AgeingReport}/>
    <Route exact path={`${match.url}${OUTSTANDING}`} component={OutstandingReport}/>
    <Route exact path={`${match.url}${VAT}`} component={VatReport}/>
    <Route exact path={`${match.url}${SALES_VAT}`} component={SalesVatReport}/>
    <Route exact path={`${match.url}${SALES_RETURN_VAT}`} component={SRNVatReport}/>
    <Route exact path={`${match.url}${PURCHASE_VAT}`} component={PurchaseVatReport}/>
    <Route exact path={`${match.url}${PURCHASE_RETURN_VAT}`} component={PurchaseReturnVatReport}/>

    <Route exact path={`${match.url}${JOURNAL_VOUCHER_BASE}`} component={JournalVoucherReport}/>
    <Route exact path={`${match.url}${JOURNAL_VOUCHER_CREATE}`} component={CreateJournalVoucher}/>
    <Route exact path={`${match.url}${JOURNAL_VOUCHER_DETAILS}/:id`} component={JournalVoucherDetail}/>
    <Route exact path={`${match.url}${JOURNAL_VOUCHER_PRINT}/:id`} component={JournalVoucherPrint}/>
    <Route exact path={`${match.url}${ROSIA_JOURNAL_VOUCHER_PRINT}/:id/:userId`} component={JournalVoucherPrint}/>

    <Route exact path={`${match.url}${CUSTOMER_LEDGER_BASE}`} component={CustomerLedger}/>
    <Route exact path={`${match.url}${CUSTOMER_LEDGER_DETAILS}/:id`} component={CustomerLedgerDetail}/>

    <Route exact path={`${match.url}${STOCK_LEDGER}`} component={StockLedger}/>
    <Route exact path={`${match.url}${SALES_MATERIALIZED_VIEW}`} component={salesMaterializedView}/>

    <Route exact path={`${match.url}${ENTRY_LOG}`} component={EntryLog}/>
    <Route exact path={`${match.url}${DATABASE_LOG}`} component={DatabaseLog}/> */}
  </Switch>
);

export default routes;
