import React from 'react';
import {
  Grid, Cell, Icon
} from '../../../components/BillingMDC';
import DateToRender from '../../../components/DateToRender';
import Download from '../Download';
import DrawerWithFilter from '../DrawerFilter';

const IrdHeader = ({
  company,
  nepaliDate,
  domainConfig,
  handleDownloadClick,
  config,
  handlePrintClick,
  display,
  controlDisplay,
  resetFilter,
  queryParameters,
  handleDateRangeChange,
  handleDateChange,
  nepaliDateExist,
  singleDateExist,
}) => (
  <div className='header-bar'>
    <Grid>
      <Cell col={4} tablet={8}>
        <div className='header-title'>{company.title}</div>
        <span className='header-title-content'>
          <span className='active-opacity-text'>Address: </span>
          <span>{company.address}</span>
        </span>
        <span className='header-title-content'>
          <span className='active-opacity-text'>Phone: </span>
          <span>{company.phone}</span>
        </span>
        <span className='no-margin-bottom header-title-content'>
          <span className='active-opacity-text'>PAN No: </span>
          <span>{company.panNumber}</span>
        </span>
      </Cell>
      <Cell col={4}>
        <div className='center-align'>
          <h2>{domainConfig.title}</h2>
          <div className='default-margin-top-12'>
            {!singleDateExist && <h4>Value Added Tax Ledger</h4>}
            <span className='header-title-content no-margin-bottom'>
              <span className='active-opacity-text'>
                {singleDateExist ? 'As of Date: ' : 'Report from'}{' '}
                <span className='date-rendered'>
                  <DateToRender
                    date={queryParameters.date ? queryParameters.date : queryParameters.singleDate}
                    singleDateExist
                  />
                </span>
              </span>
            </span>
            {nepaliDateExist && (
              <span className='header-title-content'>
                <span className='active-opacity-text'>
                  Report from
                  <span className='date-rendered'>
                    <span> {nepaliDate.start}</span>
                    <span> - {nepaliDate.end}</span>
                  </span>
                </span>
              </span>
            )}
          </div>
        </div>
      </Cell>
      <Cell col={4}>
        <div className='header-menu-right'>
          <span>
            {' '}
            <span className=''>Date: </span>
            <span>{queryParameters.date ? queryParameters.date.end : queryParameters.singleDate}</span>
          </span>
          {config.download && <Download config={domainConfig} onClick={handleDownloadClick} />}
          {config.print && (
            <span onClick={() => handlePrintClick()}>
              <Icon name='print' />
            </span>
          )}
          {config.filter && (
            <span onClick={() => controlDisplay('filter', true)}>
              <Icon name='filter_list' />
            </span>
          )}
        </div>
      </Cell>
    </Grid>
    <DrawerWithFilter
      display={display.filter}
      displayController={controlDisplay}
      resetFilter={resetFilter}
      queryParameters={queryParameters}
      onDateRangeChange={handleDateRangeChange}
      onDateChange={handleDateChange}
    />
  </div>
);

export default IrdHeader;
