import React, { Fragment } from 'react';
import {
  Button,
  Cell,
  Checkbox,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Fab,
  Grid,
  Icon,
} from '../../../../components/BillingMDC';
import { fixedFloat } from '../../../../utils/conversion';
import BillingSelect from '../../../../components/Select/BillingSelect';
import BillingTextField from '../../../../components/TextField/BillingTextField';
import BatchDialog from './BatchDialog';
import BillingAsyncSelect from '../../../../components/AsyncSelect/AsyncSelect';
import { discountOptions } from '../../sales/create/config';

const GRNDialog = ({
  createModalOpen,
  editModalOpen,
  deleteModalOpen,
  state,
  closeModal,
  onChange,
  handleModalOkClick,
  batchDetails,
  onBatchInputChange,
  onBatchFormSubmit,
  onBatchFormCancel,
  batchFormOpen,
  onBatchFabClick,
  batchDialogValidation,
  brandSKUList,
  loadOptions,
}) => (
  <Dialog
    open={createModalOpen || editModalOpen || deleteModalOpen}
    onClose={() => {
      closeModal();
    }}
  >
    <DialogHeader>
      {createModalOpen && <DialogTitle>Select SKU</DialogTitle>}
      {editModalOpen && <DialogTitle>Edit SKU</DialogTitle>}
      {deleteModalOpen && <DialogTitle>Delete SKU</DialogTitle>}
    </DialogHeader>
    {!deleteModalOpen && (
      <DialogBody>
        <div className='dialog-upperpart'>
          <div className='row_one row '>
            <Grid className='grid-padding'>
              <Cell col={6} tablet={8}>
                <label>Brand</label>
                <BillingSelect
                  name='form-field-name'
                  param='brand'
                  clearable={true}
                  value={state?.createSKU?.brand}
                  valueKey='indexArr'
                  labelKey='title'
                  options={state?.brandList}
                  onChange={(e) => onChange('brand', e?.indexArr, e)}
                />
              </Cell>

              <Cell col={6} tablet={8}>
                <label>SKU</label>
                <BillingAsyncSelect
                  param='sku_id'
                  placeholder='Search/Select...'
                  loadOptions={loadOptions}
                  handleChange={onChange}
                  options={state.skuList}
                  defaultOptions={brandSKUList}
                  getOptionLabel={({ title }) => title}
                  getOptionValue={({ businessSkuId }) => businessSkuId}
                  multipleParam
                  required
                  emptyField={state.formEmptyField}
                  value={state.skuList.filter(({ businessSkuId }) => businessSkuId === state.createSKU.sku_id)}
                  valueKey='businessSkuId'
                />
              </Cell>
              {!batchFormOpen ? (
                <>
                  <Cell col={4} tablet={8}>
                    <label>Batch</label>
                    <div className='form-wrap'>
                      <BillingSelect
                        name='form-field-name'
                        param='batch_id'
                        clearable={false}
                        value={state.createSKU.batch_id}
                        valueKey='businessBatchId'
                        labelKey='title'
                        options={state.batchList}
                        handleChange={onChange}
                        required
                        emptyField={state.formEmptyField}
                        multipleParam
                      />

                      <span className='mini-fab-button'>
                        <Fab
                          mini
                          onClick={() => onBatchFabClick({ createModalOpen: true })}
                          disabled={!state.createSKU.sku_id}
                          className={`${!state.createSKU.sku_id ? 'disabled' : ''}`}
                        >
                          <Icon name='add' />
                        </Fab>
                      </span>
                    </div>
                  </Cell>
                  <Cell col={2}>
                    <div className='cash-checkbox'>
                      <Checkbox
                        checked={state.createSKU.freeSku}
                        onChange={({ target: { checked } }) => {
                          onChange('freeSku', checked);
                        }}
                      />
                      <label>Free SKU</label>
                    </div>
                  </Cell>
                  <Cell col={4} tablet={8}>
                    <label htmlFor=''>Rate</label>
                    <div className='form-wrap'>
                      <BillingTextField
                        value={fixedFloat(state.createSKU.rate)}
                        handleChange={onChange}
                        param='rate'
                        type='number'
                        required={!state.createSKU.freeSku}
                        zeroError={false}
                        disabled={state.createSKU.freeSku}
                        className='billing-required right-side-gap'
                        emptyField={state.createSKU.freeSku ? false : state.formEmptyField}
                        errorMessage={state.createSKU.rate === 0 ? 'Should not be 0' : 'Should not be empty'}
                      />
                    </div>
                  </Cell>
                  {/* <Grid className='grid-padding'>*/}

                  {/*  </Grid>*/}
                </>
              ) : null}
            </Grid>
          </div>

          {!batchFormOpen ? (
            <Fragment>
              <div className='row_two row '>
                <label>Quantity (Pcs)</label>
                <Grid className='grid-padding'>
                  <Cell col={4} tablet={8} className='relative-container'>
                    <label htmlFor=''>Sellable</label>
                    <div className='form-wrap'>
                      <BillingTextField
                        value={state.createSKU.normal}
                        handleChange={onChange}
                        param='normal'
                        required={false}
                        zeroError
                        type='number'
                        rule='isInt'
                        className='billing-required right-side-gap'
                      />
                    </div>
                  </Cell>
                  <Cell col={4} tablet={8} className='relative-container'>
                    <label htmlFor=''>Shortage</label>
                    <div className='form-wrap'>
                      <BillingTextField
                        value={state.createSKU.shortage}
                        handleChange={onChange}
                        param='shortage'
                        required={false}
                        zeroError
                        type='number'
                        rule='isInt'
                        className='billing-required right-side-gap'
                      />
                    </div>
                  </Cell>
                  <Cell col={4} tablet={8} className='relative-container'>
                    <label htmlFor=''>Damage</label>
                    <div className='form-wrap'>
                      <BillingTextField
                        value={state.createSKU.damage}
                        handleChange={onChange}
                        param='damage'
                        required={false}
                        zeroError
                        type='number'
                        rule='isInt'
                        className='billing-required right-side-gap'
                      />
                    </div>
                  </Cell>
                </Grid>
              </div>
              <div className='row_three row'>
                <Grid className='grid-padding three'>
                  <Cell col={2}>
                    <label className='label'>Discount Type</label>
                    <BillingSelect
                      name='form-field-name'
                      param='discount_label'
                      clearable={false}
                      value={discountOptions.filter((list) => list.value === state.createSKU.discount_label)}
                      options={discountOptions}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ value }) => value}
                      disabled={state.createSKU.freeSku}
                      onChange={(e) => onChange('discount_label', e.value)}
                    />
                  </Cell>
                  <Cell col={4}>
                    <BillingTextField
                      disabled={state.createSKU.freeSku}
                      value={state.createSKU.discount_input}
                      handleChange={onChange}
                      floatingLabel={state.createSKU.discount_label === 'rs' ? 'Discount' : 'Discount (%)'}
                      param='discount_input'
                      type='number'
                      className='billing-required right-side-gap'
                      max={
                        state.createSKU.discount_label === 'rs' ? state.createSKU.quantity * state.createSKU.rate : 100
                      }
                      emptyField={state.formEmptyField}
                    />
                  </Cell>
                  <Cell col={6} tablet={8}>
                    <BillingTextField
                      value={state.createSKU.net_amount === 0 ? '0' : state.createSKU.net_amount}
                      handleChange={onChange}
                      floatingLabel='Net Amount'
                      param='net_amount'
                      required
                      zeroError
                      type='number'
                      className='billing-required no-pointer-events'
                      emptyField={state.formEmptyField}
                      errorMessage={state.createSKU.net_amount ? 'Should not be 0' : 'Should not be empty'}
                    />
                  </Cell>
                </Grid>
              </div>
            </Fragment>
          ) : null}
          {batchFormOpen ? (
            <div className='row row_one'>
              <Grid className='grid-padding width-100'>
                <Cell col={12} tablet={8}>
                  <BatchDialog
                    batch={batchDetails}
                    batchDialogValidation={!batchDialogValidation}
                    onInputChange={onBatchInputChange}
                    onFormCancel={onBatchFormCancel}
                    onFormSubmit={onBatchFormSubmit}
                    batchFormOpen={batchFormOpen}
                  />
                </Cell>
              </Grid>
            </div>
          ) : null}
        </div>
      </DialogBody>
    )}

    {deleteModalOpen && <div className='default-margin-24'>Are you sure you want to delete ?</div>}

    {
      <DialogFooter>
        <Button
          accent
          className='dialog-cancel modal-btn'
          onClick={() => {
            if (batchFormOpen) {
              onBatchFormCancel();
            } else closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          accent
          className='dialog-ok modal-btn'
          onClick={() => {
            if (batchFormOpen) {
              onBatchFormSubmit();
            } else handleModalOkClick();
          }}
        >
          Ok
        </Button>
      </DialogFooter>
    }
  </Dialog>
);

export default GRNDialog;
