import styled from 'styled-components';

const SRNStyled = styled.div`
  .header-menu-right .drawer_filter{
      span{
        top: 18px;
      }
    }
`;
export default SRNStyled;
