import React from 'react';
import { roundAndCommas } from '../../../utils/conversion';

const LedgerBody = ({ dataList, onRowClick }) => (
  <tbody>
    {dataList.map((data) => (
      <tr onClick={() => onRowClick(data)}>
        <td className='left-align'>{data.name}</td>
        <td className='right-align'>{data.openingBalance ? roundAndCommas(Math.abs(data.openingBalance)) : 0}</td>
        <td className='right-align'>{data.openingBalance === 0 ? ' ' : data.openingBalance > 0 ? 'Dr' : 'Cr'}</td>
        <td className='right-align'>{data.debit ? roundAndCommas(data.debit) : 0}</td>
        <td className='right-align'>{data.credit ? roundAndCommas(data.credit) : 0}</td>
        <td className='right-align'>{data.closingBalance ? roundAndCommas(Math.abs(data.closingBalance)) : 0}</td>
        <td className='right-align'>{data.closingBalance === 0 ? ' ' : data.closingBalance > 0 ? 'Dr' : 'Cr'}</td>
      </tr>
    ))}
  </tbody>
);

const stockLedgerConfig = [
  {
    id: 2,
    label: 'title',
    title: 'Ledger',
    className: '',
    sortable: false,
  },
  {
    id: 3,
    label: 'opening_balance',
    title: 'Opening',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 4,
    label: 'opening_balance_status',
    title: '',
    className: '',
    sortable: false,
  },
  {
    id: 5,
    label: 'debit',
    title: 'Debit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 6,
    label: 'credit',
    title: 'Credit',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'closing_balance',
    title: 'Closing',
    className: 'right-align',
    sortable: false,
  },
  {
    id: 7,
    label: 'closing_balance_status',
    title: '',
    className: '',
    sortable: false,
  },
];

const config = {
  header: stockLedgerConfig,
  getTableBody: LedgerBody,
};

export default config;
