import { config ,getTotalDetails} from './config';
import '../../../styles/printStyle.css';
import { DOMAIN } from '../../../data/enums/enums';
import IrdView from '../../common/irdReport/IrdView';
import withBaseState from '../../common/withBaseState';
import PropTypes from '../../../../node_modules/prop-types';
import * as queryService from '../../common/query.service';
import React, { Component } from '../../../../node_modules/react';
import * as downloadService from '../../common/download.service';
import { defaultHeaderConfig } from '../../common/irdReport/config';
import { SRN_VAT_REPORT_CONFIG} from '../../common/domain.config';
import { clone } from '../../../utils/arrayProcessor';
import { getIrdSalesColumnTotals } from '../../../utils/handlers';
import * as snackService from '../../common/snack.service';
import {groupPrintDataSet} from "../../common/print.service";
import VatReportStyle from '../NewSRNVatReport/VatReportStyle';
import {onRowItemClick} from "../../fundamentals/common/helpers";

class SRNVatReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSet: [],
      display: {
        searchBox: false,
        filter: false,
      },
      printData: {
        list: [],
        total: {},
      },
      printButtonClicked: false,
      queryParameters: { ...queryService.queryParameters },
      loading: false,
      columnTotal: {
        totalSales: 0,
        taxExemptedSales: 0,
        exportSales: 0,
        discount: 0,
        totalTaxableAmount: 0,
        vat: 0,
      },
    };
    const { downloadList } = this.props;
    this.dataListLength = 0;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('contextmenu', this.handleMouseClick);
  }

  /** remove eventlistener on component unmount* */
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('contextmenu', this.handleMouseClick);
  }

  loadTableData = async(print=false) => {
    const self = this;
    const { getList } = this.props;
    const { queryParameters } = this.state;
    let query = clone(queryParameters);
    query.pagination.limit = 2000;
   await getList(print ? query:queryParameters)
      .then(response => {
        const nepaliDate = {
          start: response.nepali_date.start_date_nepali,
          end: response.nepali_date.end_date_nepali,
        };
        const columnTotal = getIrdSalesColumnTotals(response);
        const data = response.list;
        self.setState({
          dataSet: data,
          totalData: response.total,
          nepaliDate,
          columnTotal,
        },() => print && this.groupPrintDataSetNew());
      })
      .catch(error => {
        console.error(error);
        this.sendErrorMessage();
      });
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  handleDownloadClick = (type, index) => {
    const { user, downloadList } = this.props;
    const { queryParameters } = this.state;
    const domain = DOMAIN.SRN_VAT;
    downloadList({
      type,
      query: queryParameters,
      extraString: `&user_id=${user.idUsers}`,
      domain,
    }).then(response => downloadService.resolver(response));
  };

  handlePrintClick = async() => {
    const self = this;
    await this.loadTableData(true);
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false });
    };
  };
  groupPrintDataSetNew() {
    const { dataSet  } = this.state;
    const {company} =this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(dataSet);
    const dataList = groupPrintDataSet(orders, printInfoBill, config);
    this.setState({ dataList });
  }


  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };

  handleMouseClick = e => {
    e.preventDefault();
    alert('Default menu stopped from poping up');
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;

    this.setState(display);
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  render() {
    const {
      dataSet,
      display,
      printButtonClicked,
      loading,
      queryParameters,
      totalData,
      columnTotal,
      dataList,
      nepaliDate,
      printData,
    } = this.state;
    const { company, serverResponseWaiting } = this.props;
    return (
      <VatReportStyle>
      <div className="srn-vat upperTotalExist">
        <IrdView
          company={company}
          dataSet={dataSet}
          display={display}
          printList={dataList}
          tableConfig={{...config,header:{...config?.header,upperHeaderDetails:getTotalDetails(columnTotal)}}}
          totalData={totalData}
          nepaliDate={nepaliDate}
          columnTotal={columnTotal}
          printFlag={printButtonClicked}
          loading={serverResponseWaiting}
          queryParameters={queryParameters}
          printColumnTotal={printData.total}
          controlDisplay={this.controlDisplay}
          domainConfig={SRN_VAT_REPORT_CONFIG}
          handlePrintClick={this.handlePrintClick}
          handleDownloadClick={this.handleDownloadClick}
          resetFilter={this.basePaginationService.resetFilter}
          headerConfig={{ ...defaultHeaderConfig, print: true }}
          handlePageSelect={this.basePaginationService.onPageSelect}
          handleTableSorting={this.basePaginationService.handleTableSorting}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          colSpan_t_l={9}
          colSpanTotal={4}
          printFooterEnable ={false}
          onTableBodyClick={onRowItemClick}
          enableTableFooter={false}
          disableUpperHeaderDetails={false}
          colSpan_t_l={1}
          colSpan_t_r={1}
        />
      </div>
      </VatReportStyle>
    );
  }
}

SRNVatReport.contextTypes = {
  router: PropTypes.object,
};

// const mapStateToProps = (state) => ({
//   user: state.billing.user || null,
//   company: state.billing.company || null,
// });
// const srnVatReport = connect(mapStateToProps)(SRNVatReport);
const CustomerWithState = withBaseState(SRNVatReport);

export default CustomerWithState;
