import React, { Fragment, Component } from 'react';
import orderBy from 'lodash/orderBy';
import View from './ View';
import { ACTION, VAT_PERCENTAGE } from '../../../../data/enums/enums';
import { formValidationFields, getApiTransformedData, skuDetailsMapper, message } from './config';
import { SKU_CONFIG } from '../../../common/domain.config';
import { validateForm } from '../../../common/validation';
import history from '../../../../utils/history';
import { SKU_DETAILS, SKU } from '../../../../data/enums/Route';
import * as snackService from '../../../common/snack.service';
import BillingSnackBar from '../../../../components/BillingMDC/BillingSnackbar';
import Loading from '../../../../common/Loading';

const propTypes = {};

const defaultProps = {};

class Detail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const id = props.match.params.id ? parseInt(props.match.params.id) : 0;
    this.state = {
      exciseFlag : JSON.parse(localStorage.getItem('rosiaCompany')).enableExcise,
      data: skuDetailsMapper({}),
      serverData: skuDetailsMapper({}),
      id,
      dialogBox: false,
      crudMode: id ? ACTION.READ : ACTION.ADD,
      actionType: ACTION.NULL,
      validation: {
        flag: false,
        fieldList: formValidationFields,
      },
      snack: { ...snackService.snackParameters },
      cancelClicked: false,
    };
    this.catalogDetailRefs = React.createRef();
    this.batchRefs = React.createRef();
  }

  componentDidMount() {
    const { id } = this.state;
    if (id) this.getSkuDetails(id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.cancelClicked) {
      this.setState({ cancelClicked: false });
    }
  }

  getSkuDetails = id => {
    const { getSkuDetails } = this.props;
    getSkuDetails({ idSku: id }).then(response => {
      const data = skuDetailsMapper(response.details || {});
      data.catalogSkusList = orderBy(data.catalogSkusList, 'businessCatalogId');
      this.setState({ data, crudMode: ACTION.READ, serverData: { ...data } });
    });
  };

  handleInputChange = (param, value) => {
    const { data } = this.state;
    data[param] = value;
    this.setState({ data });
  };

  getValidation = () => {
    const { data, validation } = this.state;
    const getDetailsValidation = validateForm(data, validation, valid => this.setState({ validation: valid }));
    if (!getDetailsValidation) return false;
    return this.catalogDetailRefs.current.accessWrappedComponentMethod('getValidation')();
  };

  handleSaveClick = () => {
    const { data, crudMode, dialogBox } = this.state;
    const isValid = this.getValidation();
    if (isValid) {
      const { catalogBars = [], businessId = 0 } =
        this.catalogDetailRefs.current.accessWrappedComponentMethod('exportData')();
      data.batchList = this.batchRefs.current.exportData();
      data.catalogSkusList = [...catalogBars];
      data.businessId = businessId;
      const dialogStatus = data.vatPercent !== VAT_PERCENTAGE && true;
      if (crudMode === ACTION.ADD) {
        this.getCreateSKU(dialogStatus, data);
      } else {
        this.getUpdateSKU(dialogStatus, data);
      }
    }
  };
  getCreateSKU = (dialogStatus, data) => {
    const { dialogBox } = this.state;
    this.setState({ dialogBox: dialogStatus, actionType: ACTION.DELETE });
    if (dialogStatus) {
      if (dialogBox) {
        this.createSku(data);
        this.onCancel();
        this.historyPush();
      }
    } else {
      this.createSku(data);
      this.historyPush();
    }
  };
  historyPush = () => {
    setTimeout(() => {
      history.push(`/${SKU}`);
    }, 1000);
  };
  getUpdateSKU = (dialogStatus, data) => {
    const { dialogBox } = this.state;
    this.setState({ dialogBox: dialogStatus, actionType: ACTION.DELETE });
    if (dialogStatus) {
      if (dialogBox) {
        this.updateSku(data);
        this.onCancel();
        this.historyPush();
      }
    } else {
      this.updateSku(data);
      this.historyPush();
    }
  };
  createSku = accumulatedData => {
    const { crudMode } = this.state;
    const apiTransformedData = getApiTransformedData(accumulatedData, crudMode);
    const { createSKUWithBatchList } = this.props;

    createSKUWithBatchList(apiTransformedData)
      .then(response => {
        if (response) {
          const id = response.length > 0 ? response[0].skuInsertion.idSku || null : null;
          this.setState({ snack: { ...snackService.generateSuccessMessage(message.ADD) } }, () =>
            history.push(`/${SKU_DETAILS}/${id}`),
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          snack: {
            ...snackService.generateFailureMessage(message.CREATE_ERROR),
          },
        });
      });
  };

  updateSku = accumulatedData => {
    const { crudMode, id } = this.state;
    const { updateSKUWithBatchList } = this.props;
    const apiTransformedData = getApiTransformedData(accumulatedData, crudMode);

    updateSKUWithBatchList(apiTransformedData)
      .then(response => {
        if (response) {
          this.getSkuDetails(id);
          this.setState({
            snack: { ...snackService.generateSuccessMessage(message.UPDATE) },
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          snack: {
            ...snackService.generateFailureMessage(message.UPDATE_ERROR),
          },
        });
      });
  };

  handleCancelClick = () => {
    const { id, serverData } = this.state;
    if (id) {
      this.setState({
        data: { ...serverData },
        cancelClicked: true,
        crudMode: ACTION.READ,
      });
    } else {
      this.setState({
        data: skuDetailsMapper({}),
        cancelClicked: true,
      });
    }
  };
  onCancel = () => {
    const { data } = this.state;
    this.setState({
      data: { ...data },
      actionType: ACTION.NULL,
      dialogBox: false,
    });
  };

  updateCrudMode = crudMode => {
    this.setState({ crudMode });
  };

  getTitle = () => {
    const { id, crudMode, data } = this.state;
    if (id) {
      if (crudMode !== ACTION.READ) return `${crudMode} ${data.title}`;
      return `${data.title}`;
    }

    return `${crudMode} ${SKU_CONFIG.title}`;
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  render() {
    const { crudMode, validation, data, snack, cancelClicked, dialogBox, actionType , exciseFlag} = this.state;
    const { serverResponseWaiting } = this.props;
    const title = this.getTitle();
    return (
      <>
        <Loading display={serverResponseWaiting} />
        <View
          handleInputChange={this.handleInputChange}
          crudMode={crudMode}
          enableErrorDisplay={validation.flag}
          title={title}
          data={data}
          updateCrudMode={this.updateCrudMode}
          catalogDetailRef={this.catalogDetailRefs}
          batchRef={this.batchRefs}
          handleCancelClick={this.handleCancelClick}
          handleSaveClick={this.handleSaveClick}
          cancelFlag={cancelClicked}
          onCancel={this.onCancel}
          dialogBox={dialogBox}
          actionType={actionType}
          excise= {exciseFlag}
        />
        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default Detail;
