import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../../../common/Loading';
import { Icon, Textfield } from '../../../components/BillingMDC';
import DateToRender from '../../../components/DateToRender';
import Download from '../Download';
import DrawerWithFilter from '../DrawerFilter';
import PageHeaderStyled from './PageHeaderStyled';
import Switch from '../../../components/Switch';

const defaultHeaderConfig = {
  search: true,
  date: true,
  singleDate: false,
  upload: true,
  print: false,
  download: true,
  filter: true,
};

const propTypes = {
  display: PropTypes.shape({ searchBox: PropTypes.bool, }),
  config: {
    search: PropTypes.bool,
    date: PropTypes.bool,
    singleDate: PropTypes.bool,
    upload: PropTypes.bool,
    download: PropTypes.bool,
    filter: PropTypes.bool,
  },
  handleFilterChange: PropTypes.func,
  handleDateRangeChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  disableStartDate: PropTypes.bool,
  disableEndDate: PropTypes.bool,
  viewOptions: PropTypes.object,
  showInputOption: PropTypes.bool,
  filterDisable:PropTypes.bool,
  serverResponseWaiting:PropTypes.bool,
};

const defaultProps = {
  display: { searchBox: false, },
  config: { ...defaultHeaderConfig, },
  handleFilterChange: () => null,
  handleDateRangeChange: () => null,
  handleDateChange: () => null,
  disableStartDate: false,
  disableEndDate: false,
  showInputOption: false,
  viewOptions: { list: [], handler: () => null, selectedValue: null },
  filterDisable:false,
  serverResponseWaiting:false,
};

const PageHeader = ({
  display, // searchBox, filter, menu
  queryParameters, //
  handleSearchChange, // this.handleSearchChange,
  clearSearchText, // this.clearSearchText
  handleDownloadClick, // this.handleDownloadClick
  controlDisplay, // this.controlDisplay
  resetFilter,
  handleDateRangeChange,
  handleDateChange,
  domainConfig,
  handleUploadClick,
  handlePrintClick,
  handleFilterChange,
  config,
  disableStartDate,
  disableEndDate,
  viewOptions,
  handleFlagChange,
  showCollapsibleList,
  showInputOption,
  onInputChange,
  inputData,
  inputTitle,
  filterDisable,
  serverResponseWaiting,
}) =>{
  const filteredList  =queryParameters && queryParameters.filter && Object.values(queryParameters?.filter).flat().length;
  return(
    <PageHeaderStyled>
      <Loading display={serverResponseWaiting} />
      <div className={`card-header-bar ${serverResponseWaiting ? 'clickable-false' : ''}`}>
        <h2>{domainConfig.title || ''}</h2>
        <div className='header-menu'>
          <div className='header-menu-left '>
            {config.search ? (
              <>
                <span>
                  <span className='search-icon-wrapper' onClick={() => controlDisplay('searchBox', !display.searchBox)}>
                    <Icon name='search' className='search-icon material-icons' />
                  </span>
                  <span className={`top-search-input-wrapper ${(display.searchBox || queryParameters?.search?.length >0 ) ? 'input-active' : 'input-inactive'}`}>
                    {(display.searchBox  || queryParameters?.search?.length>0) && (
                      <Textfield
                        value={queryParameters.search}
                        onChange={(e) => {
                          handleSearchChange(e.target.value);
                        }}
                        autoFocus
                      />
                    )}
                    {queryParameters.search && (
                      <Icon name='close' className='cross-icon material-icons' onClick={() => clearSearchText()} />
                    )}
                  </span>
                </span>
              </>
            ) : null}
          </div>

          <div className='header-menu-right'>
            {config.date ? (
              <span className='date-rendered'>
                <Icon name='date_range' className='material-icons' />
                <DateToRender date={queryParameters.date} />
              </span>
            ) : null}
            {config.reference_date ? (
              <span className='date-rendered'>
                <Icon name='date_range' className='material-icons' />
                <DateToRender date={queryParameters.reference_date} />
              </span>
            ) : null}

            {config.singleDate ? (
              <span className='date-rendered'>
                <Icon name='date_range' className='material-icons' />
                <span>{queryParameters.singleDate}</span>
              </span>
            ) : null}

            {config.upload ? (
              <span onClick={() => handleUploadClick()}>
                <Icon name='file_upload' />
              </span>
            ) : null}

            {config.download ? <Download config={domainConfig} onClick={handleDownloadClick} /> : null}
            {config.print ? (
              <span onClick={() => handlePrintClick()}>
                <Icon name='print' />
              </span>
            ) : null}

            {config.filter ? (
              <div className={`${
                filterDisable ? 'cursor-not-allow' : ''}`}>
                <span onClick={() => controlDisplay('filter', true)} className={`drawer_filter${
                  filterDisable ? 'pointer-event-none' : ''}`}>
                  <Icon name='filter_list' />
                  {filteredList >0 &&  <span>{filteredList}</span>}
                </span>
              </div>
            ) : null}
          </div>

          {config.filter ? (
            <DrawerWithFilter
              display={display.filter}
              displayController={controlDisplay}
              resetFilter={resetFilter}
              queryParameters={queryParameters}
              onDateRangeChange={handleDateRangeChange}
              onDateChange={handleDateChange}
              handleFilterChange={handleFilterChange}
              disableStartDate={disableStartDate}
              disableEndDate={disableEndDate}
              viewOptions={viewOptions}
              config={config}
              handleFlagChange={handleFlagChange}
              showCollapsibleList={showCollapsibleList}
              showInputOption={showInputOption}
              onInputChange={onInputChange}
              inputData={inputData}
              inputTitle={inputTitle}
              filterDisable={filterDisable}
            />
          ) : null}
        </div>
      </div>
    </PageHeaderStyled>
  )};

PageHeader.propTypes = propTypes;

PageHeader.defaultProps = defaultProps;

export { defaultHeaderConfig };

export default PageHeader;
