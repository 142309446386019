import styled from 'styled-components';

const JVStyled = styled.div`
&.j-v-detail{
      .header-bar{
        .calendar-arrow-icon,
        .calendar-date,.calendar-status{
          display:none;
        }
            .date-range-picker-wrapper {
              position: relative;
              outline: none;
              position: absolute;
              right: 105px;
              top: -25px;
              .rdrCalendarWrapper{
                right: 0px;
              top: 48px;
              }
          }
      }
        .edit-cancel{
          text-align: right;
          margin-bottom:8px;
          i{
            border:1px solid rgba(0, 0, 0, 0.2);
            border-radius:4px;
            margin-left:10px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            &.active{
              color:#2196f3;
              border:1px solid #2196f3;
            }
          }
        }
        .dialog-upperpart.dialog-view{
          .select-css{
            margin-top:7px;
          }
        }
  }
  &.jv-create {
    .Select {
      margin-top: 6px;
    }
    .dialog-upperpart {
      .mdc-textfield {
        height: 50px !important;
      }
      .date-range-picker-wrapper {
        border-bottom: 1px solid var(--gray-color);
        & > div {
          padding: 0;
        }
      }
      .date-picker-holder-active,
      .date-picker-holder {
        padding: 0;
        padding-top: 2px;
        .calendar-arrow-icon,
        & > .calendar-date {
          border-bottom: 0;
        }
        .calendar-status {
          display: none;
        }
        .calendar-arrow-icon {
          position: absolute;
          right: 10px;
          padding: 0;
        }
      }
    }
  }

  @media print{
    &.j-v-detail{
      .jvd{
        .print-body{
          height: 100% !important;
        padding:0 !important;
        margin:0.28in 0.3in !important;
        page-break-after: always;
        .padding-b-12{
        padding-bottom:0 !important;
    }
        .fixed-table-wrapper{
          &.fixed-table-height{
            height: 1450px !important;
            margin:4px 0 !important;
          }
          &.payment-total-footer{
            height: 1260px !important;
             margin:4px 0 !important;
          }
          table{
            height:100%;
            td,th{
              width:70px !important;
              min-width:70px !important;
              max-width:70px !important;
              line-height:20px !important;
              padding-right:0 !important;
              padding-right:10px !important;
              &:not(:last-child){
                padding:0 !important;
                padding:4px 10px 4px 0 !important;
              }
            }
            tbody{
              .total{
                td{
                font-weight: 600;
                vertical-align: middle !important;
                border-top:1px solid rgba(0, 0, 0, 0.54);
                border-bottom:1px solid rgba(0, 0, 0, 0.54);
                }
              }
              tr{
                height:27.5px !important;
                td{
                  &.tag,&.narration,&.title{
                    width:135px !important;
                    min-width:135px !important;
                    max-width:135px !important;
                    white-space: normal !important;
                    line-height:15px !important ;
                  }
                  &.narration{
                    padding-right:0 !important;
                  }
                  &.voucher-num,&.ref-no{
                    min-width: 140px !important;
                  }
                }
              }
            }
          }
        }
          .total-section-wrapper{
            margin-bottom:50px;
          }
        }
      }
    }
  }
  @media print and (max-width:5.83in) {
    &.j-v-detail .jvd .print-body{
        height: 100% !important;
        padding:0 !important;
        margin:0.4in 0.45in !important;
        .fixed-table-wrapper{
          &.fixed-table-height{
            height: 1430px !important;
          }
          &.payment-total-footer{
            height: 1240px !important;
          }
        }
    }
  }
`;
export default JVStyled;
