import { decimalRoundingType } from '../data/enums/enums';

const fixedFloat = (item, digits = 2) => {
  if (item) {
    return Number(Number(item).toFixed(digits));
  }
  return item;
};

const fixedFloatAndCommas = (item, maxDigit = 2, minDigit = 0) =>
  item
    ? item.toLocaleString(undefined, {
      maximumFractionDigits: maxDigit,
      minimumFractionDigits: minDigit,
    })
    : item;

const roundAndCommas = (item, digit = 2, defaultV = null) => {
  if (item) {
    const tenToN = 10 ** digit;
    const number = Math.round(item * tenToN) / tenToN;
    return number ? number.toLocaleString() : defaultV;
  }
  return item;
};

const decimalRounding = (item, type) => {
  switch (type) {
  case decimalRoundingType.ROUND_UP:
    return Math.ceil(item);

  case decimalRoundingType.ROUND_DOWN:
    return Math.floor(item);

  case decimalRoundingType.ROUND_TO_NEAREST_WHOLE_NUMBER:
    return Math.round(item);
  }
};

export {
  fixedFloat, fixedFloatAndCommas, roundAndCommas, decimalRounding
};
