const schema = 'https://';
// const serverUrl = 'billing.rosia.one/';
const localUrl = '127.0.0.1:8080';
const serverUrl = window.location.hostname;
// const serverUrl = 'qa.billing.rosia.one';
const devConfig = {
  //baseUrl: 'http://127.0.0.1:8850/api/v1/',
  baseUrl: 'https://staging.billing.rosia.one/api/v1/',
  imageUrl: 'https://storage.googleapis.com/mr_audit',
};
const prodConfig = {
  baseUrl: `${schema + serverUrl}/api/v1/`,
  imageUrl: 'https://storage.googleapis.com/mr_audit',
};
if (process.env.NODE_ENV === 'production') {
  module.exports = prodConfig;
} else {
  module.exports = devConfig;
}
