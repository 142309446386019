import React, { Component } from 'react';
import tableConfig from './config';
import { connect } from 'react-redux';
import withBaseState from '../common/withBaseState';
import * as queryService from '../common/query.service';
import * as snackService from '../common/snack.service';
import { BillingSnackBar } from '../../components/BillingMDC';
import PageView from '../common/pagination/PageView';
import { PICK_LIST_CONFIG } from '../common/domain.config';
import TableHeader from '../../components/TableHeader/TableHeader';
import * as filterUtil from '../../utils/filterUtil';
import PageNumber from '../../components/PrintComponent/PageNumber';
import PickListStyled from './PickListStyled';
import { clone } from '../../utils/arrayProcessor';
import {groupPrintDataSet} from "../common/print.service";
import {storeInLocalStorage ,fetchFromLocalStorage} from "../common/common";
import * as downloadService from '../common/download.service';
import { pickListApi } from '../common/base.api'

class PickList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        searchBox: false,
        filter: false,
      },
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          business_id: [],
          agent_ids: [],
        },
      },
      date: filterUtil.getCurrentDate(),
      printButtonClicked: false,
      data: {
        list: [],
        total: 0,
      },
      dataList: [],
      printData: [],
      salesPersonName:[],
    };
    this.dataListLength = 0;
    const { downloadList } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadList,
    );
    this.basePaginationService.resetFilter();
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
    window.addEventListener('keydown', this.handleKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
  setFilterStateInStorage = () => {
    const { queryParameters,display} = this.state;
    storeInLocalStorage(
      'DISPLAY',
      display,
      'PICK_LIST'
    );
    storeInLocalStorage(
      'FILTER',
      {
       queryParameters,
      },
      "PICK_LIST"
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters,display} =this.state;
    const localData = fetchFromLocalStorage('FILTER', "PICK_LIST");
    const localDisplay = fetchFromLocalStorage('DISPLAY', 'PICK_LIST');
    this.setState(
      { queryParameters:localData?.queryParameters || queryParameters ,
        display:{...localDisplay,filter:false} || display},
      () => {
        this.loadTableData();
      },
    );
  };

  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.handlePrintClick();
    }
  };
  
  loadTableData = async (print =false)=> {
    const { queryParameters, data } = this.state;
    const { getPickList } = this.props;
    print || this.setFilterStateInStorage();
    let query = clone(queryParameters);
    query.pagination.limit = data?.total;
    await getPickList(print ? query:queryParameters)
      .then(response => {
        data.list = response.list || [];
        data.total = response.totalCount || 0;
        this.setState({ data },()=>{
          print && this.groupPrintDataSetNew();
        });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage();
      });
  };

  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };
  handlePrintClick = async() => {
    const self = this;
    this.getSalesPerson();
    await this.loadTableData(true);
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false });
    };
  };
  getSalesPerson = () => {
  const {queryParameters} =this.state;
  const {filterDetails} =this.props;
  const getSalesPersonIds = queryParameters.filter.agent_ids;
  const salesPersonList =filterDetails.agent_ids.filter(({id}) => getSalesPersonIds.includes(id));
  const salesPerson =salesPersonList.map(person => person.title);
  this.setState({salesPersonName:salesPerson});
  }

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  groupPrintDataSetNew() {
    const { data  } = this.state;
    const {company} =this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(data.list);
    const dataList = groupPrintDataSet(orders, printInfoBill, tableConfig);
    this.setState({ dataList });
  }
  handleClearSearch =() =>{
    const {display}=this.state;
    this.setState({display:{...display,searchBox:false,search:false}});
    this.basePaginationService.clearSearchText();
  }

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        business_id: [],
        agent_ids: [],
      },
    });
  }
  handleDownloadClick = (type, label) => {
    const { queryParameters } = this.state;

    if (label === 'sales-summary') {
      pickListApi.downloadSalesSummary({ type, query: queryParameters }).then(response => downloadService.resolver(response.data));
    } else {
      pickListApi.downloadList({ type, query: queryParameters }).then(response => downloadService.resolver(response.data));
    }
  };

  render() {
    const { display, queryParameters, data, snack, printButtonClicked, date, dataList,salesPersonName } = this.state;
    const { serverResponseWaiting, company} = this.props;
    return (
      <PickListStyled className="pick-list ">
        <div className={printButtonClicked ? 'no-print' : 'display-block'}>
          <PageView
            domainConfig={PICK_LIST_CONFIG}
            headerConfig={{
              search: true,
              date: true,
              upload: false,
              download: true,
              filter: true,
              create: false,
              print: true,
            }}
            controlDisplay={this.controlDisplay}
            serverResponseWaiting={serverResponseWaiting}
            resetFilter={this.handleResetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.handleDownloadClick}
            display={display}
            data={data}
            handlePrintClick={this.handlePrintClick}
            queryParameters={queryParameters}
            tableConfig={tableConfig}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            onTableBodyClick={this.handleTableBodyClick}
            onPageSelect={this.basePaginationService.onPageSelect}
            handleFilterChange={this.basePaginationService.handleFilterChange}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            clearSearchText={this.handleClearSearch}
          />
        </div>

        <div className={printButtonClicked ? 'display-block portrait' : 'no-print'}>
          <div>
            {dataList.map((chunk, key) => {
              const { list = [], page = 0 ,remainingLines=[]} = chunk;
              return (
                <div className="print-body zoom-reduce">
                  <div className="pickList-body card-body">
                    <header>
                      <div className="top">
                        <div className="text-left">
                          <p>{company.title}</p>
                        </div>
                        <div className="text-right">
                          <p>Date: {filterUtil.formatToReadableDate(date)}</p>
                        </div>
                      </div>
                      <div className="bottom">
                        <h3>{tableConfig.title}</h3>
                        <p>
                          <span>{tableConfig.title} Period:</span>
                          <strong>
                            {' '}
                            {queryParameters.date.start} -{queryParameters.date.end}
                          </strong>
                        </p>
                      </div>
                    <h5 className="sales-person"> Sales Person: <b>{salesPersonName.join(", ") || 'All'}</b></h5>
                    </header>
                    <div className="fixed-table-wrapper">
                      <table className="pdf-table">
                        <TableHeader headerDetails={tableConfig.header} filterHeaderLabel={false} />
                        {tableConfig.getTableBody({ dataList: list,remainingLines:remainingLines })}
                      </table>
                    </div>
                  </div>
                  <div className="footer-block">
                    <PageNumber value={page} totalPage={dataList.length} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
      </PickListStyled>
    );
  }
}
const mapStateToProps = state => ({
  filterDetails: state.filterDetails.metaData || {},
});

const PickListWithState = connect(mapStateToProps)(withBaseState(PickList));

export default PickListWithState;
