import React, { Component } from 'react';
import {ReceiptDialog,ConfirmationDialog} from './Dialog';
import tableConfig from './config';
import ReceiptStyled from './ReceiptStyled';
import * as filterUtil from '../../../utils/filterUtil';
import { clone } from '../../../utils/arrayProcessor';
import { ACTION, paymentTypes } from '../../../data/enums/enums';
import { validateForm } from '../../common/validation';
import withBaseState from '../../common/withBaseState';
import * as snackService from '../../common/snack.service';
import * as queryService from '../../common/query.service';
import { RECEIPT_CONFIG } from '../../common/domain.config';
import { BillingSnackBar } from '../../../components/BillingMDC';
import { debouncer } from '../../../utils/handlers';
import { checkIfApiCallSuccess, ledgerApi, tagApi } from '../../common/base.api';
import moment from 'moment/moment';
import Header from '../../../components/PrintComponent/Header';
import { Button, Grid, Cell } from '../../../components/BillingMDC';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { Tab, TabList, Tabs } from 'react-tabs';
import TableView from '../../common/pagination/TableView';
import PageHeader from '../../common/pagination/PageHeader';
import { JWT } from '../../../environment';
import PageNumber from '../../../components/PrintComponent/PageNumber';
import * as downloadService from '../../common/download.service';
import {groupPrintDataSet} from "../../common/print.service";
import {getSortedByInvoiceNumber} from '../../common/common';
import { getBackDatedStatus,getCustomerList } from '../../../views/fundamentals/common/helpers';
import CustomerSelect from '../../../common/CustomerSelect';

class Receipts extends Component {
  setQueryParameters = (queryParameters, callBack = () => null) => this.setState({ queryParameters }, callBack);
  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };
  sendErrorMessage = (message = 'Error while fetching data') => {
    const snack = snackService.generateFailureMessage(message);
    this.setState({ snack });
  };

  loadPrintData = extraQueryString => {
    const { queryParameters } = this.state;
    const { getReceiptList } = this.props;
    let query = clone(queryParameters);
    query.pagination.limit = 1000;
    getReceiptList({
      query,
      extraQueryString,
    })
      .then(response => {
        const printData = response.list || [];
        this.setState({ printData });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage('Error while fetching print data set');
      });
  };
  /** get data for grid, adjust loading flag */
  loadTableData = () => {
    const { getReceiptList } = this.props;
    const { queryParameters, data, activeTab } = this.state;
    const extraQueryString = `&transaction_type=0&status=${activeTab}`;
    this.loadPrintData(extraQueryString);
    getReceiptList({
      query: queryParameters,
      extraQueryString,
    })
      .then(response => {
        const { data } = this.state;
        data.list = response.list || [];
        getSortedByInvoiceNumber(data.list);
        data.total = response.total || 0;
        this.setState({ data });
      })
      .catch(err => {
        console.log(err);
        this.sendErrorMessage();
      });
  };

  getLedgerList = async () => {
    const ledgerListAll =await getCustomerList('all');
    this.getOtherListList(ledgerListAll)
  }
  getOtherListList = (ledgerListAll) =>{
    const cashBankListTrue = ledgerListAll.filter(({isCashBankLedger})=> isCashBankLedger);
    const cashBankListTruePartial = cashBankListTrue.slice(0,300) ;
    const cashBankListFalse = ledgerListAll.filter(({isCashBankLedger})=> !isCashBankLedger);
    const cashBankListFalsePartial = cashBankListFalse.slice(0,300) ;
    this.setState({cashBankListFalse,cashBankListTrue,cashBankListTruePartial,cashBankListFalsePartial}) 
  }
  getTagList = () => {
    tagApi.getList().then(response => {
      if (checkIfApiCallSuccess(response)) {
        this.setState({ tagList: response.data.list });
      }
    });
  };

  setAddTagStatus() {
    const settings = localStorage.getItem(JWT.LOCAL_STORAGE.SETTINGS.NAME);
    if (settings) {
      let parsedSettings = JSON.parse(settings);
      if (parsedSettings) {
        this.setState({ addTag: !!parsedSettings.addTag });
      }
    }
  }

  handleFabButtonClick = () => {
    const receipt = tableConfig.getDetail();
    this.setState({ dialogType: ACTION.ADD, receipt });
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleInputChange = (field, value) => {
    const { receipt } = this.state;
    if (field === 'from_ledger') {
      this.fetchLedgerOutStandingBalance(field, value);
    }
    if (field === 'document_date' || field === 'reference_date') {
      const formattedDate = filterUtil.formatToNumericDate(value);
      receipt[field] = formattedDate;
    } else {
      receipt[field] = value;
    }
    this.setState({ receipt });
  };

  handleModalClose = () => {
    const {validation, from_ledger} = this.state;
    validation.flag = false;
    from_ledger.show = false;

    this.setState({dialogType: ACTION.NULL, validation, from_ledger,confirmStatus:false,confirmDialog:false});
  };

  closeSnack = () => {
    const snack = snackService.resetSnack();
    this.setState({ snack });
  };

  createReceipt = () => {
    const { receipt } = this.state;
    const { createReceipt } = this.props;
    const apiTransformedData = tableConfig.getApiTransformedData(receipt);

    createReceipt(apiTransformedData)
      .then(response => {
        const snack = snackService.generateSuccessMessage();
        this.setState({
          snack,
        });
        this.loadTableData();
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage(err?.error?.message || 'Error while creating receipt');
        this.setState({ snack });
      });
  };
  tabChange = () =>{
    const {receipt}=this.state;
    const {RECEIPT_TAB} = tableConfig;
    if(receipt?.reconciled){
      this.setState({activeTab:RECEIPT_TAB.RECONCILE})
   }
  }

  updateReceipt = () => {
    const { receipt,activeTab ,confirmDialog} = this.state;
    const { update ,updateReconcile} = this.props;
    const {updateApiTransformedData, updateApiTransformedDataReconcile, RECEIPT_TAB} = tableConfig;
    if (activeTab ===RECEIPT_TAB.PENDING){
    const apiTransformedData =updateApiTransformedData(receipt);
    update({
      id: receipt.id,
      query: apiTransformedData,
    })
      .then(response => {
        const snack = snackService.generateUpdateMessage();
        this.setState({
          snack,
        });
        this.tabChange();
        this.loadTableData();
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage(err?.error?.message || 'Error while updating receip pending');
        this.setState({ snack });
      });
    }
    else{
      if(confirmDialog){
      const apiTransformedData = updateApiTransformedDataReconcile(receipt);
      updateReconcile({
        id: receipt.id,
        query: apiTransformedData,
      })
        .then(response => {
          const snack = snackService.generateUpdateMessage();
          this.setState({
            snack,
          });
          this.loadTableData();
        })
        .catch(err => {
          const snack = snackService.generateFailureMessage('Error while updating receipt reconcile');
          this.setState({ snack });
        });
    }
    else{
      this.handleModalClose();
     }
  }
  };

  deletePayment = () => {
    const { deletePayment } = this.props;
    const { data, deleteId } = this.state;

    deletePayment({
      id: deleteId,
    })
      .then(res => {
        this.loadTableData();
        const snack = snackService.generateUpdateMessage();
        this.setState({
          data,
          snack,
        });
      })
      .catch(err => {
        const snack = snackService.generateFailureMessage('Error while deleting Tag!!');
        this.setState({
          data,
          snack,
        });
      });
    };
    getFormValidationConfig = () => {
      const { validation, addTag } = this.state;
      let validationConfig = {
        flag: validation.flag,
        fieldList:validation.fieldList
      }
      validationConfig = addTag ? {
        flag: validation.flag,
        fieldList: [...validationConfig.fieldList, { title: 'tagid' }]
      } : validation;
      return validationConfig;
    };

  handleModalSubmit = () => {
    // upload or set data from the form.
    const {
      receipt,
      validation,
      chequeValidation,
      dialogType,
      addTag,
      confirmStatus,
      activeTab,
    } = this.state;

    if (addTag) {
      validation.fieldList.push({
        title: 'tagid',
      });
    }
    // eslint-disable-next-line no-shadow
    const formValid = validateForm(receipt, validation, (valid) =>
      this.setState({ validation: valid })
    );

    const refNoValidation = validateForm(receipt, chequeValidation, valid =>
      this.setState({ chequeValidation: valid }),
    );

  if (!formValid && dialogType !== ACTION.DELETE) return false;

  if (dialogType === ACTION.DELETE) {
    this.deletePayment();
  }

    if (receipt.payment_type !== 1 ? refNoValidation : formValid) {
      this.handleModalClose();
      if (dialogType === ACTION.ADD) {
        this.createReceipt();
      } else {
        (activeTab === tableConfig.RECEIPT_TAB.PENDING || confirmStatus)  ? this.updateReceipt(): this.getConfirmDialog();
      }
    }
  };
  onModalConfirmClose = () => {
    const { receipt } = this.state;
    this.setState({
      receipt: { ...receipt },
      dialogType: ACTION.UPDATE,
      confirmDialog: false,
    });
  };
  handleConfirmModalSubmit =() =>{
    this.setState({confirmStatus:true,dialogType :ACTION.UPDATE,});
  }
  getConfirmDialog =() =>{
    const {confirmStatus,receiptBackUp,receipt} =this.state;
    const inputChangeStatus =receiptBackUp.amount !== receipt.amount || receiptBackUp.from_ledger !== receipt.from_ledger ||  receiptBackUp.to_ledger !== receipt.to_ledger || receiptBackUp.document_date !== receipt.document_date || receiptBackUp.tagid !== receipt.tagid  ||receiptBackUp.narration !== receipt.narration|| receiptBackUp.agent_id !== receipt.agent_id ;
    if(inputChangeStatus){
    this.setState({confirmDialog:true,dialogType :ACTION.READ},()=>{
      confirmStatus &&  this.handleModalSubmit(); confirmStatus &&  this.onModalConfirmClose();
    });
    return
  }
    this.setState({confirmDialog:false,dialogType :ACTION.UPDATE},()=>{
      this.handleModalClose();
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      deleteId: '',
      display: {
        searchBox: false,
        filter: false,
      },
      confirmDialog:false,
      confirmStatus:false,
      receiptBackUp:{},
      dialogType: ACTION.NULL,
      snack: { ...snackService.snackParameters },
      queryParameters: {
        ...queryService.queryParameters,
        filter: {
          payment_type: [],
        },
      },
      data: {
        list: [],
        total: 0,
      },
      printData: [],
      printButtonClicked: false,
      dataList: [],
      date: filterUtil.getCurrentDay(),
      receipt: tableConfig.getDetail(),
      validation: {
        flag: false,
        fieldList: tableConfig.formValidationFieldList,
      },
      tagList: [],
      activeTab: 0,
      paymentType: paymentTypes,
      addTag: false,
      chequeValidation: {
        flag: false,
        fieldList: tableConfig.chequeValidationFieldList,
      },
      from_ledger: {
        amount: 0,
        show: false
      },
      agentList:[],
      backDatedStatus:getBackDatedStatus(),
      cashBankListFalse:[],
      cashBankListFalsePartial:[],
      cashBankListTrue:[],
      cashBankListTruePartial:[],
    };

    this.reference = {
      tableHeader: React.createRef(),
      tableFooter: React.createRef(),
      tableBody: React.createRef(),
      fileRef: React.createRef(),
    };
    const { paymentDownloadList } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      paymentDownloadList,
    );
    this.basePaginationService.resetFilter();

    this.tabListGetterMapper = {
      [tableConfig.PAYMENT_TABS.PENDING]: this.loadTableData,
      [tableConfig.PAYMENT_TABS.RECONCILED]: this.loadTableData,
    };
  }


  fetchLedgerOutStandingBalance = (field, customerId) => {
    const {from_ledger} = this.state;
    // display sign to represent credit or debit
    const displaySign = -1;
    ledgerApi.getOutstandingValue(customerId).then((response) => {
      if (checkIfApiCallSuccess(response)) {
        if (field === 'from_ledger') {
          from_ledger.show = true;
          from_ledger.amount = response.data.outstandingAmount * displaySign || 0;
          this.setState({from_ledger})
        }
      }
    });
  };

  /** adjust table width and height according to screen * */
  componentDidMount() {
    this.loadTableData();
    this.getLedgerList();
    this.getTagList();
    this.setAddTagStatus();
    this.getAgentList();
    window.addEventListener('keydown', this.handleKeyDown);
  }
  getAgentList = () => {
    const { agentDataList } = this.props;
    agentDataList().then(response => {
        const agentList = response.list.filter(a=>a.status) || [];
        this.setState({ agentList });
    });
  };
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }
  handleKeyDown = e => {
    const charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey && charCode === 'p') || (e.metaKey && charCode === 'p')) {
      e.preventDefault();
      this.getDataForPrint();
    }
  };
  
  fetchActiveTabPaginatedList = tab => {
    const type = tableConfig.tabMapper[tab];
    this.tabListGetterMapper[type]();
  };

  getDataForPrint = () => {
    const self = this;
    this.groupPrintDataSetNew();
    self.setState({ printButtonClicked: true }, () => {
      setTimeout(() => {
        window.print();
      }, 500);
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false });
    };
  };

  groupPrintDataSetNew() {
    const { printData  } = this.state;
    const {company} =this.props;
    const printInfoBill = {...company,printInfo:{...company.printInfo,batchEnabled:false}} || {};
    const orders = clone(printData);
    const odering= true;
    const sortedOrder =getSortedByInvoiceNumber(orders);
    const dataList = groupPrintDataSet(sortedOrder, printInfoBill, tableConfig,odering);
    this.setState({ dataList });
  }

  handleTabChange = tab => {
    this.setState(
      {
        activeTab: tab,
      },
      () => this.fetchActiveTabPaginatedList(tab),
    );
  };

  handleEditIconClick = data => {
    const {serverResponseWaiting}=this.props;
    if(!(!!serverResponseWaiting)){
    const receipt = tableConfig.getDetail(data);
    const backUpData=clone({...receipt});
    this.setState({
      dialogType: ACTION.UPDATE ,
      receipt,
      receiptBackUp:backUpData,
    });
    this.fetchLedgerOutStandingBalance('from_ledger', receipt.from_ledger)
  }
  else{
    const snack = snackService.generateUpdateMessage('Updating...');
    this.setState({snack});
  }
  };

  handleDeleteIconClick = data => {
    const receipt = tableConfig.getDetail(data);
    this.setState({
      dialogType: ACTION.DELETE,
      deleteId: data.id,
      receipt,
    });
  };
  handleDownloadClick = (reportType = 'CSV') => {
    const { paymentDownloadList } = this.props;
    const { queryParameters, activeTab } = this.state;
    const extraQueryString = `&transaction_type=0&status=${activeTab}`;
    paymentDownloadList({
      type: reportType,
      query: queryParameters,
      extraQueryString,
    }).then(response => downloadService.resolver(response));
  };

  handleFilterChange = (fieldValue, selectedValue) => {
    const { queryParameters } = this.state;
    queryParameters.filter[fieldValue] = selectedValue;
    queryParameters.pagination.page = 1;
    this.setState({ queryParameters });
    this.basePaginationService.handleFilterChange(fieldValue, selectedValue);
  };
  handleResetFilter =()=>{
    this.basePaginationService.defaultResetFilter({
      ...queryService.queryParameters,
      filter: {
        payment_type: [],
      },
    });
  }
  getLedgerSelectOptions = () => {
    const {receipt,from_ledger,validation,cashBankListTrue,cashBankListFalse,cashBankListTruePartial,cashBankListFalsePartial} =this.state;
    return(
      <Grid className='grid-padding'>
      <Cell col={4} tablet={4} className='input-select '>
          <label>To Cash/Bank*</label>
          <CustomerSelect
            value ={receipt?.to_ledger}
            param='to_ledger'
            valueKey='customerId'
            clearable
            onHandleChange={this.handleInputChange}
            type='isCashBankLedgerTrue'
            required
            pageValidation={validation.flag}
            ledgerList={cashBankListTrue}
            ledgerListPartial={cashBankListTruePartial}
              />
          </Cell>
          <Cell col={4} tablet={4} className='input-select '>
              <label>From Ledger*</label>
                <CustomerSelect
                  value ={receipt?.from_ledger}
                  param='from_ledger'
                  valueKey='customerId'
                  clearable
                  onHandleChange={this.handleInputChange}
                  required
                  pageValidation={validation.flag}
                  ledgerList={cashBankListFalse}
                  ledgerListPartial={cashBankListFalsePartial}
                  type = 'isCashBankLedgerFalse'
                  />
                  {receipt?.from_ledger>0 &&
            <span> {from_ledger.show && `Outstanding due: ${fixedFloatAndCommas(from_ledger.amount)}`}</span>
                  }
          </Cell>
          </Grid>
    )
  }

  render() {
    const {
      queryParameters,
      data,
      display,
      receipt,
      snack,
      dialogType,
      validation,
      tagList,
      activeTab,
      paymentType,
      chequeValidation,
      printButtonClicked,
      dataList,
      date,
      from_ledger,
      addTag,
      confirmDialog,
      confirmStatus,
      agentList,
      backDatedStatus,
      cashBankListFalse,
      cashBankListTrue,
    } = this.state;
    const { serverResponseWaiting, company, fiscalYearRange } = this.props;
    return (
      <ReceiptStyled>
        <div className={printButtonClicked ? 'no-print' : 'display-block receipt'}>
          <PageHeader
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            controlDisplay={this.controlDisplay}
            clearSearchText={this.basePaginationService.clearSearchText}
            queryParameters={queryParameters}
            domainConfig={RECEIPT_CONFIG}
            display={display}
            config={{
              search: true,
              filter: true,
              date: true,
              create: true,
              print: true,
              download: true,
            }}
            handlePrintClick={this.getDataForPrint}
            resetFilter={this.handleResetFilter}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.handleDownloadClick}
            handleFilterChange={this.handleFilterChange}
            serverResponseWaiting={serverResponseWaiting}
          />
          <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.handleTabChange(tabIndex)}>
            <TabList>
              <Tab>Pending</Tab>
              <Tab>Reconciled</Tab>
            </TabList>
          </Tabs>
          <TableView
            display={display}
            onPageSelect={this.basePaginationService.onPageSelect}
            handleTableSorting={this.basePaginationService.handleTableSorting}
            data={data}
            config={tableConfig}
            serverResponseWaiting={serverResponseWaiting}
            pagination={queryParameters.pagination}
            createHandler={this.handleFabButtonClick}
            btnDisplay={true}
            onTableBodyClick={this.handleEditIconClick}
            viewType={activeTab}
            header={tableConfig.tabBasedHeaderConfig[activeTab]}
            deleteHandler={this.handleDeleteIconClick}
            tagList={tagList}
            agentList ={agentList}
          />
            {confirmDialog && !confirmStatus && (
            <ConfirmationDialog actionType={dialogType} onModalConfirmClose={this.onModalConfirmClose} handleConfirmModalSubmit={this.handleConfirmModalSubmit} data={receipt} cashBank={cashBankListTrue} ledgerList={cashBankListFalse}/>
          )}

          <ReceiptDialog
            receipt={receipt}
            addTag={addTag}
            validationFlag={validation.flag}
            actionType={dialogType}
            ledgerList={cashBankListFalse}
            formEmptyField={false}
            onInputChange={debouncer(this.handleInputChange, 800)}
            onModalClose={this.handleModalClose}
            onModalSubmit={this.handleModalSubmit}
            tagList={tagList}
            paymentType={paymentType}
            activeTab={activeTab}
            cashBank={cashBankListTrue}
            chequeValidation={chequeValidation.flag}
            fiscalYearRange={fiscalYearRange}
            from_ledger={from_ledger}
            confirmStatus={confirmStatus}
            agentList={agentList}
            serverResponseWaiting={serverResponseWaiting}
            backDatedStatus={backDatedStatus}
            renderLedgerSelectOptions={this.getLedgerSelectOptions}
          />
          <BillingSnackBar closeSnack={this.closeSnack} config={snack} />
        </div>

        <div className={printButtonClicked ? 'display-block receipt_print portrait-type' : 'no-print'}>
          {dataList.map((ceItem, key) => (
            <div className="print-body zoom-reduce voucher-print">
              <div className="jv card-body">
                <Grid>
                  <Cell col={2} tablet={2}>
                    <div className="header-title">{company.title}</div>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Address: </span>
                      <span>{company.address}</span>
                    </span>
                    <span className="header-title-content">
                      <span className="active-opacity-text">Phone: </span>
                      <span>{company.phone}</span>
                    </span>
                    <span className="no-margin-bottom header-title-content">
                      <span className="active-opacity-text">PAN No: </span>
                      <span>{company.panNumber}</span>
                    </span>
                  </Cell>
                  <Cell col={4} tablet={4}>
                    <div className="center-align">
                      <h2>{RECEIPT_CONFIG.title}</h2>
                      <div className="default-margin-top-2">
                        <span className="header-title-content no-margin-bottom">
                          <span className="active-opacity-text fs-14">
                            Report from
                            <span className="date-rendered">
                              {` ${filterUtil.formatToReadableDate(
                                queryParameters.date.start,
                              )} - ${filterUtil.formatToReadableDate(queryParameters.date.end)}`}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </Cell>
                  <Cell col={2} tablet={2}>
                    <div className="header-menu-right date text-right">
                      <span>
                        {' '}
                        <span className="">Date: </span>
                        <span>{date}</span>
                      </span>
                    </div>
                  </Cell>
                </Grid>
                <div className={` fixed-table-wrapper ${!data.footer ? 'fixed-table-height ' : 'total-footer'}`}>
                  <table>
                    <TableHeader headerDetails={tableConfig.tabBasedHeaderConfig[activeTab]} handleSorting={null} />
                    {tableConfig.getTableBody({
                      dataList: ceItem.list,
                      viewType: activeTab,
                      remainingLines: ceItem.remainingLines,
                    })}
                  </table>
                </div>
              </div>
              <div className="footer-block">
                <PageNumber value={ceItem.page} totalPage={dataList.length} />
              </div>
            </div>
          ))}
        </div>
      </ReceiptStyled>
    );
  }
}

const ReceiptsWithState = withBaseState(Receipts);

export default ReceiptsWithState;
